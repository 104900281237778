import { useCallback } from "react"
import axios from "axios"
import useLoginStore from "src/store/LoginStore"

const useAxiosService = () => {
  const { login } = useLoginStore()

  const axiosService = useCallback(() => {
    return login
      ? axios.create({
          baseURL: process.env.REACT_APP_API_SERVER,
          withCredentials: true,
          mode: "no-cors",
          credentials: "same-origin",
          headers: {
            //"Access-Control-Allow-Origin": '*',
            Authorization: `Bearer ${login.jwt_token}`,
            "Content-Type": "application/json"
          }
        })
      : axios.create({
          baseURL: process.env.REACT_APP_API_SERVER,
          withCredentials: true,
          mode: "no-cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json"
          }
        })
  }, [login])

  return {
    axiosService
  }
}

export default useAxiosService

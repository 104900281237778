import React, { useCallback } from "react"
import ProgressBar from "react-bootstrap/ProgressBar"
import { useNavigate, useSearchParams } from "react-router-dom"
import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"
import Loading from "src/components/comm/Loading"
import { useVocaCatList } from "src/api/VocaApi"
import { useSvcDetail } from "src/api/SvcApi"

const GateList = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { data: listData, isLoading: listLoading } = useVocaCatList(searchParams.get("svc_id"), "Y")
  const { data: detailData, isLoading: detailLoading } = useSvcDetail(searchParams.get("svc_id"))

  const onGateClick = useCallback((e, items) => {
    if (items.cat_typ === "W") {
      navigate(`/word/word-list?svc_id=${searchParams.get("svc_id")}&cat_id=${items.cat_id}`)
    } else if (items.cat_typ === "P") {
      navigate(`/word/pattern-list?svc_id=${searchParams.get("svc_id")}&cat_id=${items.cat_id}`)
    } else if (items.cat_typ === "Q") {
      alert("종합퀴즈")
      //navigate(`/word/idiom-list?svc_id=${searchParams.get("svc_id")}&cat_id=${items.cat_id}`)
    }
  }, [])

  if (listLoading || detailLoading) {
    return <Loading />
  }

  return (
    <>
      <Header />
      <section className="tf-section top-seller bg-home-3" style={{ paddingTop: "120px", paddingBottom: "0px" }}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12" style={{ marginBottom: "30px" }}>
              <div className="">
                <h2 className="tf-title style2">{detailData.svc_nm}</h2>

                <div className="heading-line s1"></div>
                <p style={{ textAlign: "center", fontSize: "1.8em" }}>{detailData.svc_dsc}</p>
                <p style={{ textAlign: "center", fontSize: "1.4em", color: "orange" }}>
                  기억하고싶은 표현들들 내단어장에 담아 학습하세요.
                </p>
              </div>
            </div>
            {listData.map((item, index) => (
              <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="sc-author-box" onClick={(e) => onGateClick(e, item)} style={{ cursor: "pointer" }}>
                  <div className="author-avatar">
                    {/*
                    <img src={img1} className="avatar" alt="Axies" />
                    */}
                    {item.cat_typ === "W" ? (
                      <div
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "40px",
                          background: "#5142FC",
                          color: "#fff",
                          fontSize: "1.6em",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "bold"
                        }}
                      >
                        {item.cat_typ}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "40px",
                          background: "#d0702f",
                          color: "#fff",
                          fontSize: "1.6em",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "bold"
                        }}
                      >
                        {item.cat_typ}
                      </div>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ fontSize: "1.8em", fontWeight: "bold", margin: "0 0 10px 0", color: "#fff" }}>
                      {item.cat_nm}
                    </div>
                    <ProgressBar animated variant="primary" now={100} style={{ height: "4px", width: "85%" }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default GateList

import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const DashboardStore = (set) => ({
  choiceItem: "voca",
  setChoiceItem: (_choiceItem) => set((state) => ({ choiceItem: _choiceItem }))
})

const persistConfig = {
  name: "DashboardStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useDashboardStore = create(devtools(persist(DashboardStore, persistConfig), { name: persistConfig.name }))
export default useDashboardStore

/* eslint-disable react/react-in-jsx-scope */
import "./App.css"
import { Routes, Route, useNavigate } from "react-router-dom"
import routes from "./pages/index"
import { SWRConfig } from "swr"
import useLoginStore from "src/store/LoginStore"

function App() {
  const navigate = useNavigate()
  const { setLogin } = useLoginStore()

  const onErrorRetry = (error, key, config, revalidate, { retryCount }) => {
    console.log(error)
    console.log(retryCount)
    if (error?.response?.status === 404) return

    if (error?.response?.status === 401) {
      setLogin(null)
      navigate("/")
      return
    }

    if (retryCount >= 2) return
    setTimeout(() => revalidate({ retryCount }), 5000)
  }

  return (
    <SWRConfig
      value={{
        onErrorRetry: onErrorRetry,
        revalidateOnFocus: false,
        revalidateIfState: true,
        revalidateOnMount: true,
        suspense: false
      }}
    >
      <Routes>
        {routes.map((data, index) => (
          <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
        ))}
      </Routes>
    </SWRConfig>
  )
}

export default App

import React, { useState, useCallback } from "react"
import { FaBookmark } from "react-icons/fa6"
import { PiListBold, PiNotepad } from "react-icons/pi"
import { FaCheckCircle } from "react-icons/fa"
import NoDataFooter from "src/components/footer/NoDataFooter"
import NoHeaderLoading from "src/components/comm/NoHeaderLoading"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useVocaCatDetail, useVocaMapList } from "src/api/VocaApi"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import useLoginStore from "src/store/LoginStore"

const WordList = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { login } = useLoginStore()
  const [selectedItem, setSelectedItem] = useState(0)
  //console.log(login)
  const {
    data: detailData,
    isLoading: detailLoading,
    isValidating: detailValidating
  } = useVocaCatDetail(searchParams.get("cat_id"))
  const {
    data: listData,
    isLoading: listLoading,
    isValidating: listValidating
  } = useVocaMapList(searchParams.get("cat_id"), login?.mem_id, "Y")

  const clickItem = useCallback(
    (voca_id, e) => {
      e.preventDefault()
      setSelectedItem(voca_id)
      navigate(`/word/word-study?svc_id=${searchParams.get("svc_id")}&cat_id=${searchParams.get("cat_id")}&voca_id=${voca_id}`)
    },
    [selectedItem]
  )

  if (listLoading || listValidating || detailLoading || detailValidating) {
    return <NoHeaderLoading />
  }

  return (
    <>
      <section className="tf-section top-seller home5 s2 mobie-style bg-style2" style={{ paddingBottom: "0px" }}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-12 col-lg-12" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <div
                className="form-inner"
                style={{
                  position: "fixed",
                  zIndex: 200,
                  background: "#000",
                  top: "0px",
                  left: "0px",
                  width: "100%"
                }}
              >
                <div
                  className="themesflat-container"
                  style={{ padding: "0px", borderBottom: "1px solid rgba(235, 235, 235, 0.2)" }}
                >
                  <PiListBold
                    size="38"
                    onClick={() => navigate(`/word/gate-list?svc_id=${searchParams.get("svc_id")}`)}
                    style={{ margin: "16px 0 14px 20px", cursor: "pointer" }}
                  />
                  <PiNotepad
                    size="40"
                    onClick={() => navigate(`/word/mynotes`)}
                    style={{ margin: "14px 20px 14px 0px", cursor: "pointer", float: "right" }}
                  />
                </div>
              </div>
              <h2 className="style2 text-center" style={{ paddingTop: "16px", fontSize: "3em" }}>
                {detailData.cat_nm}
              </h2>
              <div className="flat-tabs seller-tab tablet-30">
                <Tabs>
                  <TabList style={{ borderBottom: "0", display: "none" }}>
                    {[
                      {
                        title: "단어"
                      }
                    ].map((item, index) => (
                      <Tab key={index} style={{ fontSize: "1.4em", margin: "0 2px", padding: "10px 0" }}>
                        {item.title}
                      </Tab>
                    ))}
                  </TabList>

                  <div className="content-tab" style={{ marginTop: "30px" }}>
                    <TabPanel>
                      {listData.map((item, index) => (
                        <div
                          key={index}
                          className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                          style={{ paddingLeft: "8px", paddingRight: "8px" }}
                        >
                          <div
                            onClick={(e) => clickItem(item.voca_id, e)}
                            className={
                              item.voca_id === selectedItem
                                ? "sc-author-box style-3 sc-author-box-selected"
                                : "sc-author-box style-3"
                            }
                            style={{ textAlign: "center", cursor: "pointer", padding: "25px 0" }}
                          >
                            <h4 style={{ width: "100%" }}>
                              {item?.mark_yn === "Y" ? (
                                <FaBookmark
                                  size="26"
                                  style={{ color: "#249fff", position: "absolute", top: "-4px", right: "24px" }}
                                />
                              ) : (
                                ""
                              )}

                              <span style={{ fontSize: "1.2em" }}>{item.voca_tg}</span>
                              <div
                                className="author-avatar"
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "16px",
                                  lineHeight: "15px"
                                }}
                              >
                                {item?.std_cnt ? <FaCheckCircle /> : ""}
                              </div>
                            </h4>
                          </div>
                        </div>
                      ))}
                      <div
                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                        style={{ paddingLeft: "8px", paddingRight: "8px" }}
                      >
                        <div
                          onClick={() =>
                            navigate(`/quiz/start-quiz?svc_id=${searchParams.get("svc_id")}&cat_id=${searchParams.get("cat_id")}`)
                          }
                          className="form-inner"
                          style={{ textAlign: "center", cursor: "pointer", padding: "0px 0" }}
                        >
                          <h4 style={{ width: "100%" }}>
                            <button className="submit" style={{ fontSize: "1em", lineHeight: "36px" }}>
                              {detailData.cat_nm} 퀴즈
                            </button>
                          </h4>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NoDataFooter />
    </>
  )
}

export default WordList

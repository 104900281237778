import React, { useCallback, useRef, useState, useMemo, useEffect } from "react"
import { BsStopCircleFill } from "react-icons/bs"
import { BsPlayCircleFill } from "react-icons/bs"
import { RiEditCircleFill } from "react-icons/ri"
import { IoIosRemoveCircle } from "react-icons/io"
import { IoMdRefreshCircle } from "react-icons/io"
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3"
import { getSignedUrl } from "@aws-sdk/s3-request-presigner"
import WithHeaderLoading from "src/components/comm/WithHeaderLoading"
import { TypeAnimation } from "react-type-animation"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css"
import useLoginStore from "src/store/LoginStore"
import { useSentBookmark } from "src/api/VocaApi"

const MySentList = ({ sentBookmarkList, sentBookmarkListMutate }) => {
  const S3 = new S3Client({
    region: "auto",
    endpoint: process.env.REACT_APP_CLOUDFLARE_ENDPOINT,
    credentials: {
      accessKeyId: process.env.REACT_APP_CLOUDFLARE_ACCESSKEYID,
      secretAccessKey: process.env.REACT_APP_CLOUDFLARE_SECRETACCESSKEY
    }
  })

  const gridRef = useRef(null)
  const { login, sex } = useLoginStore()
  const [windowHeight, setWindowHeight] = useState()

  const [play, setPlay] = useState(false)
  const [sentSingleAudio, setSentSingleAudio] = useState(new Audio())

  const [listPlay, setListPlay] = useState(false)
  const [sentListAudio, setSentListAudio] = useState(new Audio())

  const [loading, setLoading] = useState(false)

  const [dataSearch, setDataSearch] = useState("")
  const [edit, setEdit] = useState(false)
  //const [modalShow, setModalShow] = useState(false)

  const { trigger: sentBookmarkTrigger, isMutating: isSentBookMarkMutating } = useSentBookmark()

  const columnDefs = useMemo(
    () => [
      /*
    부하 때문에 드래그 순위조정이 아닌 하트나, 무조건 최상위(타임스탬프 활용) 버튼처리 하는게 좋겠음.
    {
      field: "sent_ord",
      cellStyle: { textAlign: "right", padding: "9px 0 0 12px", fontSize: "2em" },
      rowDrag: (params) => {
        return true
      },
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      hide: true,
      getQuickFilterText: () => ""
    },*/
      {
        field: "sent_en",
        cellStyle: {
          fontSize: "1.4em",
          lineHeight: "30px",
          verticalAlign: "middle",
          wordBreak: "keep-all",
          whiteSpace: "pre-line",
          padding: "14px 10px 14px 8px"
        },
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params) => {
          return (
            <div onClick={() => onClickSinglePlay(params)}>
              <div>{params.value}</div>
              <span style={{ fontSize: "0.8em", color: "#dcd579" }}>{params.data.sent_kr}</span>
            </div>
          )
        }
        /*,
      cellRendererParams: {
        onClick: (item) => {
          alert("test")
          //console.log(item)
          //return <TypeAnimation sequence={[item.value, 1000]} speed={50} style={{ fontSize: "2em" }} repeat={Infinity} />
        }
      }*/
        /*
      cellRenderer: (item) => {
        return <TypeAnimation sequence={[item.value, 3000]} speed={3} style={{ fontSize: "1em" }} repeat={0} cursor={false} />
      }*/
      },
      {
        field: "sent_kr",
        hide: true
      },
      /*
    {
      field: "sent_sound",
      cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px", verticalAlign: "bottom" },
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => {
        return (
          <div style={{ textAlign: "right" }}>
            <RxSpeakerQuiet onClick={() => onClickSinglePlay(params)} size="28" style={{ margin: "14px 2px 0 0" }} />
          </div>
        )
      }
    },
    */
      {
        field: "sent_remove",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px", verticalAlign: "bottom" },
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        hide: true,
        cellRenderer: (params) => {
          return (
            <div style={{ textAlign: "right" }}>
              <IoIosRemoveCircle
                onClick={(e) => onClickRemove(params, e)}
                size="28"
                color="#de9717"
                style={{ margin: "14px 7px 0 0" }}
              />
            </div>
          )
        }
      }
    ],
    [play, listPlay]
  )

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      flex: 1
    }
  }, [])

  const gridCommonOptions = useMemo(() => {
    return {
      animateRows: true,
      pagination: false,
      groupHeaderHeight: 30,
      headerHeight: 30,
      floatingFiltersHeight: 30,
      pivotHeaderHeight: 30,
      pivotGroupHeaderHeight: 30,
      enableRangeSelection: true,
      suppressMultiRangeSelection: true,
      tooltipShowDelay: 500,
      tooltipHideDelay: 10000,
      //paginationAutoPageSize: true,
      suppressPaginationPanel: true
    }
  }, [])

  const onClickRemove = useCallback(async (params, e) => {
    e.stopPropagation()
    const data = {
      sent_id: params.data.sent_id,
      mem_id: login?.mem_id,
      mark_yn: "N"
    }
    const result = await sentBookmarkTrigger(data)
    if (result) {
      sentBookmarkListMutate()
    }
  }, [])

  const onClickSinglePlay = useCallback(
    async (item) => {
      if (play || listPlay) {
        return
      }
      try {
        const response = await getSignedUrl(
          S3,
          new GetObjectCommand({ Bucket: "sentence-bucket", Key: sex ? item.data.sent_mp3_m : item.data.sent_mp3_w }),
          { expiresIn: 30 }
        )
        sentSingleAudio.src = response
        const rowNode = gridRef.current.api.getRowNode(item.node.id)
        rowNode.updateData({
          sent_id: item.data.sent_id,
          mark_yn: item.data.mark_yn,
          sent_kr: item.data.sent_kr,
          sent_mp3_m: item.data.sent_mp3_m,
          sent_mp3_m_ss: item.data.sent_mp3_m_ss,
          sent_mp3_w: item.data.sent_mp3_w,
          sent_mp3_w_ss: item.data.sent_mp3_w_ss,
          sent_mp3_k: item.data.sent_mp3_k,
          sent_mp3_k_ss: item.data.sent_mp3_k_ss,
          sent_en: (
            <TypeAnimation
              sequence={[
                0,
                () => {
                  sentSingleAudio.play()
                  console.log("typing")
                },
                item.data.sent_en,
                () => {
                  console.log("done")
                  rowNode.updateData({
                    sent_id: item.data.sent_id,
                    mark_yn: item.data.mark_yn,
                    sent_en: item.data.sent_en,
                    sent_kr: item.data.sent_kr,
                    sent_mp3_m: item.data.sent_mp3_m,
                    sent_mp3_m_ss: item.data.sent_mp3_m_ss,
                    sent_mp3_w: item.data.sent_mp3_w,
                    sent_mp3_w_ss: item.data.sent_mp3_w_ss,
                    sent_mp3_k: item.data.sent_mp3_k,
                    sent_mp3_k_ss: item.data.sent_mp3_k_ss
                  })
                  setPlay(false)
                  //sentAudio.pause()
                  //sentAudio.currentTime = 0
                }
              ]}
              speed={{ type: "keyStrokeDelayInMs", value: sex ? item.data.sent_mp3_m_ss / 50 : item.data.sent_mp3_w_ss / 50 }}
              style={{ fontSize: "1em", color: "#f0c45b" }}
              repeat={0}
              omitDeletionAnimation={true}
            />
          )
        })
      } catch (e) {
        alert(e)
        sentSingleAudio.pause()
        sentSingleAudio.currentTime = 0
        setPlay(false)
        console.log(e)
      }
    },
    [play, listPlay, sentSingleAudio]
  )

  const onGridReady = useCallback((params) => {
    setWindowHeight(window.innerHeight)
    params.api.sizeColumnsToFit()
  }, [])

  const onGridSizeChanged = useCallback((params) => {
    params.api.sizeColumnsToFit()
  }, [])

  const autoPlayLoop = useCallback(
    async (rowNodeArr, index) => {
      if (play || listPlay) {
        return
      }
      try {
        const response = await getSignedUrl(
          S3,
          new GetObjectCommand({
            Bucket: "sentence-bucket",
            Key: sex ? rowNodeArr[index].data.sent_mp3_m : rowNodeArr[index].data.sent_mp3_w
          }),
          { expiresIn: 30 }
        )
        sentListAudio.src = response
        //console.log(sentBookmarkList)
        //console.log(rowNodeArr[index])
        const sent_id_origin = rowNodeArr[index].data.sent_id
        const sent_en_oirgin = rowNodeArr[index].data.sent_en
        const sent_kr_origin = rowNodeArr[index].data.sent_kr
        const sent_mp3_m_origin = rowNodeArr[index].data.sent_mp3_m
        const sent_mp3_m_ss_origin = rowNodeArr[index].data.sent_mp3_m_ss
        const sent_mp3_w_origin = rowNodeArr[index].data.sent_mp3_w
        const sent_mp3_w_ss_origin = rowNodeArr[index].data.sent_mp3_w_ss
        const sent_mp3_k_origin = rowNodeArr[index].data.sent_mp3_k
        const sent_mp3_k_ss_origin = rowNodeArr[index].data.sent_mp3_k_ss

        rowNodeArr[index].updateData({
          sent_id: rowNodeArr[index].data.sent_id,
          mark_yn: rowNodeArr[index].data.mark_yn,
          sent_kr: rowNodeArr[index].data.sent_kr,
          sent_mp3_m: rowNodeArr[index].data.sent_mp3_m,
          sent_mp3_m_ss: rowNodeArr[index].data.sent_mp3_m_ss,
          sent_mp3_w: rowNodeArr[index].data.sent_mp3_w,
          sent_mp3_w_ss: rowNodeArr[index].data.sent_mp3_w_ss,
          sent_mp3_k: rowNodeArr[index].data.sent_mp3_k,
          sent_mp3_k_ss: rowNodeArr[index].data.sent_mp3_k_ss,
          sent_en: (
            <TypeAnimation
              sequence={[
                1500,
                () => {
                  sentListAudio.play()
                  console.log("typing")
                },
                rowNodeArr[index].data.sent_en,
                () => {
                  console.log("done")
                  rowNodeArr[index].updateData({
                    sent_id: sent_id_origin,
                    sent_en: sent_en_oirgin,
                    sent_kr: sent_kr_origin,
                    sent_mp3_m: sent_mp3_m_origin,
                    sent_mp3_m_ss: sent_mp3_m_ss_origin,
                    sent_mp3_w: sent_mp3_w_origin,
                    sent_mp3_w_ss: sent_mp3_w_ss_origin,
                    sent_mp3_k: sent_mp3_k_origin,
                    sent_mp3_k_ss: sent_mp3_k_ss_origin
                  })
                  if (rowNodeArr.length - 1 === index) {
                    setListPlay(false)
                    console.log("finish")
                  } else {
                    const timeout = setTimeout(() => {
                      //sentListAudio.pause()
                      //sentListAudio.currentTime = 0
                      autoPlayLoop(rowNodeArr, index + 1)
                      clearTimeout(timeout)
                    }, 1500)
                  }
                }
              ]}
              speed={{ type: "keyStrokeDelayInMs", value: sex ? sent_mp3_m_ss_origin / 50 : sent_mp3_w_ss_origin / 50 }}
              style={{ fontSize: "1em", color: "#f0c45b" }}
              repeat={0}
              omitDeletionAnimation={true}
            />
          )
        })
      } catch (e) {
        alert(e)
        sentListAudio.pause()
        sentListAudio.currentTime = 0
        setListPlay(false)
        console.log(e)
      }
    },
    [play, listPlay, sentListAudio]
  )

  const onClickListPlay = useCallback(
    (isListPlay) => {
      if (play) {
        return
      }
      if (isListPlay) {
        setListPlay(true)
        //alert("true")
        //그리드의 싱글플레이 컬럼 없애자
        let rowNodeArr = []
        gridRef.current.api.forEachNodeAfterFilter((node) => rowNodeArr.push(node))
        autoPlayLoop(rowNodeArr, 0)
      } else {
        setListPlay(false)
        setLoading(true)
        sentBookmarkListMutate()
        const timeout = setTimeout(() => {
          setLoading(false)
          clearTimeout(timeout)
        }, 1000)
      }
    },
    [play, listPlay]
  )

  const searchKeyDown = useCallback((e) => {
    if (e.key === "Enter") {
      gridRef.current.api.setQuickFilter(e.target.value)
    } else if (e.target.value === "") {
      gridRef.current.api.setQuickFilter("")
    }
  }, [])

  const searchChange = useCallback((e) => {
    //console.log(e.target.value)
    e.preventDefault()
    console.log(e.target.value)
    setDataSearch(e.target.value)
  }, [])

  const refresh = useCallback(
    (e) => {
      setLoading(true)
      sentBookmarkListMutate()
      const timeout = setTimeout(() => {
        setLoading(false)
        setPlay(false)
        setListPlay(false)
        clearTimeout(timeout)
      }, 1000)
    },
    [play, listPlay]
  )

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  useEffect(() => {
    sentSingleAudio.addEventListener("play", function () {
      setPlay(true)
    })
    sentSingleAudio.addEventListener("playing", function () {
      setPlay(true)
    })
    sentSingleAudio.addEventListener("ended", function () {
      setPlay(false)
    })
  }, [play])

  useEffect(() => {
    sentListAudio.addEventListener("play", function () {
      setListPlay(true)
    })
    sentListAudio.addEventListener("playing", function () {
      setListPlay(true)
    })
    sentListAudio.addEventListener("ended", function () {
      setListPlay(false)
    })
  }, [listPlay])

  useEffect(() => {
    if (listPlay) {
      setEdit(false)
      //gridRef?.current?.columnApi?.setColumnsVisible(["sent_sound"], false)
    } else {
      //gridRef?.current?.columnApi?.setColumnsVisible(["sent_sound"], true)
    }
    gridRef?.current?.api?.sizeColumnsToFit()
  }, [listPlay])

  useEffect(() => {
    if (edit) {
      //gridRef?.current?.columnApi?.setColumnsVisible(["sent_sound"], false)
      gridRef?.current?.columnApi?.setColumnsVisible(["sent_remove"], true)
      //gridRef?.current?.columnApi?.setColumnsVisible(["sent_ord"], true)
    } else {
      gridRef?.current?.columnApi?.setColumnsVisible(["sent_remove"], false)
      //gridRef?.current?.columnApi?.setColumnsVisible(["sent_ord"], false)
      if (listPlay) {
        //gridRef?.current?.columnApi?.setColumnsVisible(["sent_sound"], false)
      } else {
        //gridRef?.current?.columnApi?.setColumnsVisible(["sent_sound"], true)
      }
    }
    gridRef?.current?.api?.sizeColumnsToFit()
  }, [edit, listPlay])

  const noData = () => {
    return <div style={{ height: "70px" }}>등록된 문장이 없습니다.</div>
  }

  return (
    <>
      <div className="row" style={{ marginTop: "113px", marginBottom: "10px" }}>
        <div className="col-12" style={{ padding: "0px 22px" }}>
          <div
            className="row"
            style={{
              padding: "0 10px 6px 0",
              margin: "12px 0 6px 0",
              position: "fixed",
              top: "52px",
              zIndex: 100,
              background: "#000",
              width: "100%",
              maxWidth: "1440px"
            }}
          >
            <div className="col-6" style={{ padding: "0px" }}>
              <input
                onChange={searchChange}
                onKeyUp={searchKeyDown}
                value={dataSearch}
                pattern="[a-zA-Z0-9]+"
                type="text"
                id="text"
                name="text"
                tabIndex="1"
                aria-required="true"
                placeholder="Search"
                style={{ lineHeight: "18px", height: "47px", maxWidth: "300px", textTransform: "lowercase" }}
              />
            </div>

            <div className="col-6" style={{ padding: "0px 8px", textAlign: "right" }}>
              <IoMdRefreshCircle onClick={() => refresh()} size="46" style={{ margin: "0 12px 1px 0" }} />
              {!edit ? (
                <RiEditCircleFill onClick={() => setEdit(true)} size="44" color="#fff" style={{ margin: "0 16px 2px 0" }} />
              ) : (
                <RiEditCircleFill onClick={() => setEdit(false)} size="44" color="#7d92f6" style={{ margin: "0 16px 2px 0" }} />
              )}
              {!listPlay ? (
                <BsPlayCircleFill onClick={() => onClickListPlay(true)} size="39" color="#fff" style={{ margin: "0 0 5px 0" }} />
              ) : (
                <BsStopCircleFill
                  onClick={() => onClickListPlay(false)}
                  size="39"
                  color="#7d92f6"
                  style={{ margin: "0 0 5px 0" }}
                />
              )}
            </div>
          </div>
          <div
            id="myWords"
            className={"ag-theme-alpine-dark"}
            style={{
              //Height: `${windowHeight}px`,
              //height: `${sentBookmarkList.length * 92}px`,
              paddingTop: "10px"
            }}
          >
            {loading ? (
              <WithHeaderLoading />
            ) : (
              <AgGridReact
                ref={gridRef}
                rowHeight={90}
                rowData={sentBookmarkList}
                noRowsOverlayComponent={noData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                gridOptions={gridCommonOptions}
                //onRowClicked={onRowClicked2}
                //rowDragManaged={true}
                //rowDragEntireRow={true}
                //tooltipInteraction={true}
                //tooltipShowDelay={200}
                //onRowClicked={onRowClicked}\
                domLayout={"autoHeight"}
                rowSelection={"single"}
                rowDragMultiRow={false}
                enableRangeSelection={false}
                suppressRowClickSelection={false}
                suppressContextMenu={true}
                suppressMenuHide={false}
                suppressHorizontalScroll={true}
                rowDragManaged={false}
                onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
              />
            )}
          </div>
        </div>
      </div>
      {/*<CardModal show={modalShow} onHide={() => setModalShow(false)} />*/}
    </>
  )
}
export default MySentList

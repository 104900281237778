/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useCallback, useRef } from "react"
import Loading from "src/components/comm/Loading"
import { BeatLoader, PulseLoader } from "react-spinners"
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3"
import { getSignedUrl } from "@aws-sdk/s3-request-presigner"
import { sortedUniqBy } from "lodash"
import { TypeAnimation } from "react-type-animation"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useVocaCatDetail, useSentMapList, useSentBookmark } from "src/api/VocaApi"
import useLoginStore from "src/store/LoginStore"
import { PiListBold, PiNotepad } from "react-icons/pi"
import { FcBusinessman } from "react-icons/fc"
import { FcBusinesswoman } from "react-icons/fc"
import NoDataFooter from "src/components/footer/NoDataFooter"
import { FaBookmark } from "react-icons/fa6"
import "react-tabs/style/react-tabs.css"

const PatternList = () => {
  const divKor = useRef([])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { login, sex, setSex, trans, setTrans } = useLoginStore()
  const { data: detailData, isLoading: detailLoading } = useVocaCatDetail(searchParams.get("cat_id"))
  const {
    data: listData,
    isLoading: listLoading,
    mutate: sentMapListMutate
  } = useSentMapList(searchParams.get("cat_id"), login?.mem_id, "Y")
  const { trigger: sentBookmarkTrigger, isMutating: isSentBookMarkMutating } = useSentBookmark()
  const [uniqData, setUniqData] = useState([])

  const [seed, setSeed] = useState(-1)
  const [sentMark, setSentMark] = useState(-1)
  const [sentPlay, setSentPlay] = useState(false)
  const [sentAudio, setSentAudio] = useState(new Audio())

  const S3 = new S3Client({
    region: "auto",
    endpoint: process.env.REACT_APP_CLOUDFLARE_ENDPOINT,
    credentials: {
      accessKeyId: process.env.REACT_APP_CLOUDFLARE_ACCESSKEYID,
      secretAccessKey: process.env.REACT_APP_CLOUDFLARE_SECRETACCESSKEY
    }
  })

  const onVocaClick = useCallback(
    async (e, item) => {
      console.log(item.sent_id)
      if (sentPlay) {
        return
      }
      try {
        setSentPlay(true)
        const response = await getSignedUrl(
          S3,
          new GetObjectCommand({ Bucket: "sentence-bucket", Key: sex ? item.sent_mp3_m : item.sent_mp3_w }),
          {
            expiresIn: 30
          }
        )
        //console.log(item)
        console.log(response)
        sentAudio.src = response
        setSeed(item.sent_id)

        /*
        sentAudio.play()
        const timeout = setTimeout(() => {
          setSeed(item.sent_id)
          clearTimeout(timeout)
        }, 200)*/
      } catch (e) {
        console.log(e)
        setSeed(-1)
        sentAudio.pause()
        sentAudio.currentTime = 0
        setSentPlay(false)
        alert(e)
      }
    },
    [sentAudio, sentPlay, sex, seed]
  )

  const onTransClick = useCallback(() => {
    if (trans) {
      divKor.current.map((item, index) => {
        if (item) {
          item.style.display = "none"
        }
      })
    } else {
      divKor.current.map((item, index) => {
        if (item) {
          item.style.display = "block"
        }
      })
    }
    setTrans(!trans)
  }, [trans])

  const onSentBookmarkClick = useCallback(
    async (mark_yn, item, e) => {
      e.stopPropagation()
      console.log(item)

      setSentMark(item.sent_id)
      if (!isSentBookMarkMutating) {
        const data = {
          sent_id: item.sent_id,
          mem_id: login?.mem_id,
          mark_yn: mark_yn
        }
        const result = await sentBookmarkTrigger(data)
        if (result) {
          sentMapListMutate()
        }
      }
    },
    [isSentBookMarkMutating]
  )

  const onSexClick = useCallback(() => {
    console.log("onSexClick")
    if (!sentPlay) {
      setSex(!sex)
    }
  }, [sex])

  useEffect(() => {
    /*
    const fData = listData.map((item, index) => {
      const searchData = item.voca_tg.replace("~", "").trim()
      const start = item.sent_en.indexOf(searchData)
      console.log("start=", start)
      console.log("length=", searchData.length)

      const first = item.sent_en.substr(0, start)
      const search = `<span style="color:#fca942">` + item.sent_en.substr(start, searchData.length) + "</span>"
      const last = item.sent_en.substr(start + searchData.length, item.sent_en.length)

      return {
        sent_id: item.sent_id,
        voca_id: item.voca_id,
        voca_tg: item.voca_tg,
        voca_mean: item.voca_mean,
        sent_en: first + search + last,
        sent_mp3_m: item.sent_mp3_m,
        sent_mp3_w: item.sent_mp3_w
      }
    })
    setFilteredData(fData)
    */
    const uniData = sortedUniqBy(listData, "voca_id")
    setUniqData(uniData)
  }, [listLoading])

  useEffect(() => {
    sentAudio.addEventListener("play", function () {
      setSentPlay(true)
    })
    sentAudio.addEventListener("playing", function () {
      setSentPlay(true)
    })
    sentAudio.addEventListener("ended", function () {
      setSentPlay(false)
    })
  }, [])

  if (listLoading || detailLoading) {
    return <Loading />
  }

  return (
    <>
      <section className="tf-contact tf-section" style={{ padding: "4px 0 4px 0" }}>
        <div className="themesflat-container">
          <div className="row" style={{ alignItems: "initial", padding: "0px" }}>
            <div className="col-xl-12 col-lg-12 col-md-12 col-12" style={{ padding: "6px", marginBottom: "40px" }}>
              <div
                className="form-inner"
                style={{
                  position: "fixed",
                  zIndex: 200,
                  background: "#000",
                  top: "0px",
                  left: "0px",
                  width: "100%"
                }}
              >
                <div className="themesflat-container" style={{ padding: "0px" }}>
                  <PiListBold
                    size="38"
                    onClick={() => navigate(`/word/gate-list?svc_id=${searchParams.get("svc_id")}`)}
                    style={{ margin: "16px 0 14px 20px", cursor: "pointer" }}
                  />
                  <PiNotepad
                    size="40"
                    onClick={() => navigate(`/word/mynotes`)}
                    style={{ margin: "14px 20px 14px 0px", cursor: "pointer", float: "right" }}
                  />
                </div>
              </div>

              <div
                style={{
                  position: "relative",
                  border: "1px solid #676767db",
                  borderRadius: "8px",
                  padding: "6px",
                  margin: "78px 0 0 0"
                }}
              >
                <div className="form-inner">
                  {sex ? (
                    <FcBusinessman
                      onClick={(e) => onSexClick()}
                      size="44"
                      style={{ position: "absolute", top: "16px", right: "20px", opacity: 0.7, zIndex: 100 }}
                    />
                  ) : (
                    <FcBusinesswoman
                      onClick={(e) => onSexClick()}
                      size="44"
                      style={{ position: "absolute", top: "16px", right: "20px", opacity: 0.7, zIndex: 100 }}
                    />
                  )}
                </div>
                <h2
                  className="tf-title style2 text-center"
                  style={{
                    padding: "70px 0 14px 0",
                    fontSize: "3.6em",
                    textAlign: "center",
                    color: "#f0c45b"
                  }}
                >
                  {detailData.cat_nm}
                </h2>
                <h5
                  style={{
                    textAlign: "center",
                    fontWeight: "bold"
                  }}
                >
                  {detailData.cat_dsc}
                </h5>
                <div style={{ marginTop: "66px" }}>
                  <label
                    className="uploadFile"
                    style={{
                      border: 0,
                      padding: "4px 4px",
                      minHeight: "160px",
                      borderTop: "1px solid #333",
                      borderRadius: "0px",
                      marginBottom: "0px"
                    }}
                  >
                    <pre
                      style={{
                        minHeight: "30px",
                        overflowY: "auto",
                        fontFamily: "inherit",
                        fontSize: "1.1em",
                        lineHeight: "30px",
                        color: "#fff",
                        background: "#000",
                        whiteSpace: "pre-line",
                        border: "0",
                        pading: "0"
                      }}
                    >
                      {detailData.etc}
                    </pre>
                  </label>
                </div>
              </div>
              <div style={{ border: "0px solid #676767db", borderRadius: "8px", padding: "6px", position: "relative" }}>
                <div
                  className="form-inner"
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: "6px",
                    right: "-8px"
                  }}
                >
                  <button
                    onClick={(e) => onTransClick(trans)}
                    className="submit"
                    style={{ maxWidth: "110px", float: "right", padding: "8px 20px", margin: "20px" }}
                  >
                    {!trans ? "번역보기" : "번역숨김"}
                  </button>
                </div>
              </div>
            </div>

            {uniqData.map((uniqItem, uniqIndex) => (
              <div
                key={uniqIndex}
                className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                style={{ paddingLeft: "8px", paddingRight: "6px" }}
              >
                <div>
                  <h4 style={{ textAlign: "center", margin: "40px 10px 10px 10px", fontSize: "2.4em" }}>{uniqItem.voca_tg}</h4>
                  <h6 style={{ textAlign: "center", margin: "0 0 10px 0", fontSize: "1.6em" }}>{uniqItem.voca_mean}</h6>
                </div>
                {listData.map((item, index) =>
                  item.voca_id === uniqItem.voca_id ? (
                    <div key={index} className="filename">
                      <label
                        onClick={(e) => onVocaClick(e, item)}
                        className="uploadFile"
                        style={{
                          position: "relative",
                          overflow: "visible",
                          fontSize: "1.7em",
                          fontWeight: "normal",
                          padding: "26px 0 26px 10px",
                          margin: "10px 0 40px 0"
                        }}
                      >
                        <div
                          style={
                            sentPlay && seed === item.sent_id
                              ? { color: "#fca942", lineHeight: "30px", fontSize: "1.2em", fontWeight: "bold" }
                              : { color: "#f4cd6e", lineHeight: "30px", fontSize: "1.2em", fontWeight: "bold" }
                          }
                        >
                          {item.sent_id === seed ? (
                            <TypeAnimation
                              sequence={[
                                1500,
                                () => {
                                  sentAudio.play()
                                  console.log("typing")
                                },
                                item.sent_en,
                                () => {
                                  console.log("done")
                                  setSeed(-1)
                                  setSentPlay(false)
                                  //sentAudio.pause()
                                  //sentAudio.currentTime = 0
                                }
                              ]}
                              speed={{
                                type: "keyStrokeDelayInMs",
                                value: sex ? item.sent_mp3_m_ss / 45 : item.sent_mp3_w_ss / 45
                              }}
                              style={{ fontSize: "1em", color: "#f0c45b" }}
                              repeat={0}
                              omitDeletionAnimation={true}
                              //preRenderFirstString={true}
                            />
                          ) : (
                            item.sent_en
                          )}
                        </div>
                        <div
                          style={
                            trans
                              ? { lineHeight: "30px", fontSize: "1m", fontWeight: "bold", display: "block" }
                              : { lineHeight: "30px", fontSize: "1m", fontWeight: "bold", display: "none" }
                          }
                        >
                          {item.sent_kr}
                        </div>
                        {item?.mark_yn === "Y" ? (
                          <FaBookmark
                            onClick={(e) => onSentBookmarkClick("N", item, e)}
                            size="35"
                            style={{ color: "#249fff", position: "absolute", top: "-10px", right: "10px" }}
                          />
                        ) : (
                          <FaBookmark
                            onClick={(e) => onSentBookmarkClick("Y", item, e)}
                            size="35"
                            style={{ color: "#666", position: "absolute", top: "-10px", right: "10px" }}
                          />
                        )}
                        <div style={{ display: "inline-block", position: "absolute", top: "-46px", right: "15px" }}>
                          <PulseLoader
                            loading={(isSentBookMarkMutating || listLoading) && sentMark === item.sent_id}
                            size={4}
                            color="yellow"
                          />
                        </div>
                        <BeatLoader
                          loading={sentPlay && seed === item.sent_id}
                          color="#5142FC"
                          size={8}
                          style={{ position: "absolute", right: "10px", bottom: "-34px" }}
                        />
                      </label>
                    </div>
                  ) : null
                )}
              </div>
            ))}
          </div>
        </div>
        <NoDataFooter />
      </section>
    </>
  )
}

export default PatternList

import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"
import { assign } from "lodash"

const VocaQuizStore = (set) => ({
  quizBase: {},
  //setQuizBase: (cat_id, quizList) => set((state) => ({ quizBase: { cat_id1: cat_id, quiz_list: quizList } }))
  setQuizBase: (cat_id, quizList) =>
    set((state) => {
      //console.log(cat_id)
      //console.log(quizList)
      state.quizBase[cat_id] = quizList
      return state
    }),
  setQuizDone: (cat_id, quz_arr) =>
    set((state) => {
      //console.log(cat_id, quz_arr)
      //console.log(state.quizBase)
      assign(state.quizBase[cat_id][quz_arr], { complete: true })
      return state
    })
})

const persistConfig = {
  name: "VocaQuizStore",
  storage: createJSONStorage(() => sessionStorage)
}

const useVocaQuizStore = create(devtools(persist(VocaQuizStore, persistConfig), { name: persistConfig.name }))
export default useVocaQuizStore

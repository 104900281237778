import React, { useState, useEffect, useCallback, useRef } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3"
import { getSignedUrl } from "@aws-sdk/s3-request-presigner"
import { TypeAnimation } from "react-type-animation"
import { BeatLoader, PulseLoader } from "react-spinners"
import { FaBookmark } from "react-icons/fa6"
import { PiListBold, PiNotepad } from "react-icons/pi"
import { TbBoxMultiple3 } from "react-icons/tb"
import NoHeaderLoading from "src/components/comm/NoHeaderLoading"
import { useVocaInfo, useVocaMapList, useVocaBookmark, useSentBookmark } from "src/api/VocaApi"
import { HiSpeakerWave } from "react-icons/hi2"
import { FcBusinessman } from "react-icons/fc"
import { FcBusinesswoman } from "react-icons/fc"
import useLoginStore from "src/store/LoginStore"
import NoDataFooter from "src/components/footer/NoDataFooter"

const WordStudy = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { login, sex, setSex, trans, setTrans } = useLoginStore()
  const {
    data: detailData,
    isLoading: detailLoading,
    mutate: vocaInfoMutate,
    isValidating: detailValidating
  } = useVocaInfo(searchParams.get("voca_id"), login?.mem_id, "Y")
  const {
    data: listData,
    isLoading: listLoading,
    isValidating: listValidating
  } = useVocaMapList(searchParams.get("cat_id"), login?.mem_id, "Y")
  const { trigger: vocaBookmarkTrigger, isMutating: isVocaBookMarkMutating } = useVocaBookmark()
  const { trigger: sentBookmarkTrigger, isMutating: isSentBookMarkMutating } = useSentBookmark()
  const [beforeData, setBeforeData] = useState([])
  const [nextData, setNextData] = useState([])
  const [seed, setSeed] = useState(-1)
  const [sentMark, setSentMark] = useState(-1)
  const [sentPlay, setSentPlay] = useState(false)
  const [wordPlay, setWordPlay] = useState(false)
  const [sentAudio] = useState(new Audio())
  const [wordAudio] = useState(new Audio())

  const S3 = new S3Client({
    region: "auto",
    endpoint: process.env.REACT_APP_CLOUDFLARE_ENDPOINT,
    credentials: {
      accessKeyId: process.env.REACT_APP_CLOUDFLARE_ACCESSKEYID,
      secretAccessKey: process.env.REACT_APP_CLOUDFLARE_SECRETACCESSKEY
    }
  })

  const onSoundClick = useCallback(
    async (e, detailData) => {
      if (wordPlay) {
        return
      }

      if (sex && detailData?.vocaInfo?.voca_mp3_m) {
        await wordAudio.play()
      }

      if (!sex && detailData?.vocaInfo?.voca_mp3_w) {
        await wordAudio.play()
      }
      wordAudio.play()
      /*
      try {
        const response = await getSignedUrl(
          S3,
          new GetObjectCommand({
            Bucket: "word-bucket",
            Key: sex ? detailData.vocaInfo.voca_mp3_m : detailData.vocaInfo.voca_mp3_w
          }),
          {
            expiresIn: 10
          }
        )
        //console.log(response)
        wordAudio.src = response
        wordAudio.play()
      } catch (e) {
        wordAudio.pause()
        wordAudio.currentTime = 0
        setWordPlay(false)
        alert(e)
        console.log(e)
      }*/
    },
    [wordAudio, wordPlay, sex]
  )

  const onTransClick = useCallback(
    (_trans) => {
      setTrans(!_trans)
    },
    [trans]
  )

  const onSexClick = useCallback(() => {
    if (!sentPlay && !wordPlay) {
      setSex(!sex)
    }
  }, [sex, sentPlay, wordPlay])

  const onVocaClick = useCallback(
    async (e, item) => {
      console.log(item.sent_id)
      if (sentPlay) {
        return
      }
      try {
        setSentPlay(true)
        const response = await getSignedUrl(
          S3,
          new GetObjectCommand({ Bucket: "sentence-bucket", Key: sex ? item.sent_mp3_m : item.sent_mp3_w }),
          {
            expiresIn: 30
          }
        )
        //console.log(item)
        console.log(response)
        sentAudio.src = response
        setSeed(item.sent_id)

        /*
        sentAudio.play()
        const timeout = setTimeout(() => {
          setSeed(item.sent_id)
          clearTimeout(timeout)
        }, 200)*/
      } catch (e) {
        console.log(e)
        setSeed(-1)
        sentAudio.pause()
        sentAudio.currentTime = 0
        setSentPlay(false)
        alert(e)
      }
    },
    [sentAudio, sentPlay, sex, seed]
  )

  const onBeforeClick = useCallback(() => {
    console.log(nextData)
    if (beforeData) {
      navigate(
        `/word/word-study?svc_id=${searchParams.get("svc_id")}&cat_id=${searchParams.get("cat_id")}&voca_id=${beforeData.voca_id}`
      )
      window.scrollTo(0, 0)
    } else {
      alert(`이전 단어가 없습니다.`)
    }
  }, [beforeData])

  const onNextClick = useCallback(() => {
    console.log(nextData)
    if (nextData) {
      navigate(
        `/word/word-study?svc_id=${searchParams.get("svc_id")}&cat_id=${searchParams.get("cat_id")}&voca_id=${nextData.voca_id}`
      )
      window.scrollTo(0, 0)
    } else {
      alert(`마지막 단어입니다.`)
    }
  }, [nextData])

  const onVocaBookmarkClick = useCallback(
    async (mark_yn, vocaInfo, e) => {
      console.log(vocaInfo)
      if (!isVocaBookMarkMutating) {
        const data = {
          voca_id: vocaInfo.voca_id,
          mem_id: login?.mem_id,
          mark_yn: mark_yn
        }
        const result = await vocaBookmarkTrigger(data)
        if (result) {
          vocaInfoMutate()
        }
      }
    },
    [isVocaBookMarkMutating]
  )

  const onSentBookmarkClick = useCallback(
    async (mark_yn, item, e) => {
      e.stopPropagation()
      console.log(item)
      setSentMark(item.sent_id)
      if (!isSentBookMarkMutating) {
        const data = {
          sent_id: item.sent_id,
          mem_id: login?.mem_id,
          mark_yn: mark_yn
        }
        const result = await sentBookmarkTrigger(data)
        if (result) {
          vocaInfoMutate()
        }
      }
    },
    [isSentBookMarkMutating]
  )

  useEffect(() => {
    wordAudio.addEventListener("play", function () {
      setWordPlay(true)
      //console.log("오디오 플레이")
    })
    wordAudio.addEventListener("playing", function () {
      setWordPlay(true)
      //console.log("오디오 플레이중")
    })
    wordAudio.addEventListener("ended", function () {
      setWordPlay(false)
      //console.log("오디오 완료")
    })

    sentAudio.addEventListener("play", function () {
      setSentPlay(true)
      //console.log("오디오 플레이")
    })
    sentAudio.addEventListener("playing", function () {
      setSentPlay(true)
      //console.log("오디오 플레이중")
    })
    sentAudio.addEventListener("ended", function () {
      setSentPlay(false)
      //console.log("오디오 완료")
    })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      //console.log(detailData)

      if (detailData.constructor === Object && Object.keys(detailData).length === 0) {
        return false
      }

      if (!detailData?.vocaInfo?.voca_mp3_m || !detailData?.vocaInfo?.voca_mp3_w) {
        return false
      }

      try {
        const response = await getSignedUrl(
          S3,
          new GetObjectCommand({
            Bucket: "word-bucket",
            Key: sex ? detailData?.vocaInfo?.voca_mp3_m : detailData?.vocaInfo?.voca_mp3_w
          }),
          {
            expiresIn: 30
          }
        )
        wordAudio.src = response
        //await wordAudio.play()
      } catch (e) {
        alert(e)
        console.log(e)
      }
    }
    fetchData()
  }, [detailData, sex])

  useEffect(() => {
    listData.map((item, index) => {
      //console.log(item)
      if (item.voca_id === detailData?.vocaInfo?.voca_id) {
        setBeforeData(listData[index - 1])
        setNextData(listData[index + 1])
      }
    })
  }, [detailData, listData])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  if (detailLoading || listLoading || listValidating) {
    return <NoHeaderLoading />
  }

  return (
    <section className="tf-contact tf-section" style={{ padding: "4px 0 4px 0" }}>
      <div className="themesflat-container">
        <div className="row" style={{ alignItems: "initial", padding: "0px" }}>
          <div className="col-xl-6 col-lg-6 col-md-12 col-12" style={{ padding: "20px 14px 0px 14px" }}>
            <div
              className="form-inner"
              style={{
                position: "fixed",
                zIndex: 200,
                background: "#000",
                top: "0px",
                left: "0px",
                width: "100%"
              }}
            >
              <div
                className="themesflat-container"
                style={{ padding: "0px", borderBottom: "1px solid rgba(235, 235, 235, 0.2)" }}
              >
                <PiListBold
                  size="38"
                  onClick={() =>
                    navigate(`/word/word-list?svc_id=${searchParams.get("svc_id")}&cat_id=${searchParams.get("cat_id")}`)
                  }
                  style={{ margin: "16px 0 14px 20px", cursor: "pointer" }}
                />
                <PiNotepad
                  size="40"
                  onClick={() => navigate(`/word/mynotes`)}
                  style={{ margin: "14px 20px 14px 0px", cursor: "pointer", float: "right" }}
                />
              </div>
            </div>
            <div
              style={{
                position: "relative",
                border: "1px solid rgba(138, 138, 160, 0.3)",
                borderRadius: "8px",
                padding: "6px",
                marginTop: "72px"
              }}
            >
              <div style={{ height: "80px", fontSize: "1.2em", marginTop: "20px", marginLeft: "5px" }}></div>
              {sex ? (
                <FcBusinessman
                  onClick={(e) => onSexClick()}
                  size="44"
                  style={{ position: "absolute", top: "16px", right: "80px", opacity: 0.7 }}
                />
              ) : (
                <FcBusinesswoman
                  onClick={(e) => onSexClick()}
                  size="44"
                  style={{ position: "absolute", top: "16px", right: "80px", opacity: 0.7 }}
                />
              )}
              {detailData.vocaInfo?.mark_yn === "Y" ? (
                <FaBookmark
                  onClick={(e) => onVocaBookmarkClick("N", detailData.vocaInfo, e)}
                  size="40"
                  style={{ color: "#249fff", position: "absolute", top: "16px", right: "16px" }}
                />
              ) : (
                <FaBookmark
                  onClick={(e) => onVocaBookmarkClick("Y", detailData.vocaInfo, e)}
                  size="40"
                  style={{ color: "#666", position: "absolute", top: "16px", right: "16px" }}
                />
              )}
              <div style={{ display: "inline-block", position: "absolute", top: "27px", right: "22px" }}>
                <PulseLoader loading={isVocaBookMarkMutating || detailLoading} size={5} color="yellow" />
              </div>
              <h1
                className="tf-title-heading style-2 mg-bt-12"
                style={{
                  fontSize: "4em",
                  textAlign: "center",
                  color: "#f0c45b",
                  letterSpacing: "-0.8px"
                }}
              >
                {detailData.vocaInfo.voca_tg}
              </h1>
              <h5
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                  fontWeight: "bold"
                }}
              >
                {detailData.vocaInfo.voca_mean}
              </h5>
              <h5
                style={{
                  position: "relative",
                  textAlign: "center",
                  marginTop: "36px",
                  fontWeight: "normal"
                }}
              >
                <span className="fontDiv" style={{ fontSize: "1.2em" }}>
                  {detailData.vocaInfo.voca_phnt}
                </span>
                {wordPlay ? (
                  <HiSpeakerWave size="48" style={{ color: "#249fff", margin: "0 0 -17px 16px", cursor: "pointer" }} />
                ) : (
                  <HiSpeakerWave
                    onClick={(e) => onSoundClick(e, detailData)}
                    size="48"
                    style={{ color: "#999", margin: "0 0 -17px 16px", cursor: "pointer" }}
                  />
                )}
              </h5>

              <div style={{ marginTop: "40px" }}>
                <label
                  className="uploadFile"
                  style={{
                    border: 0,
                    padding: "4px 4px",
                    minHeight: "160px",
                    borderTop: "1px solid #333",
                    borderRadius: "0px",
                    marginBottom: 0
                  }}
                >
                  <pre
                    style={{
                      minHeight: "30px",
                      overflowY: "auto",
                      fontFamily: "inherit",
                      fontSize: "1.1em",
                      lineHeight: "30px",
                      color: "#fff",
                      background: "#000",
                      whiteSpace: "pre-line",
                      border: "0",
                      pading: "0"
                    }}
                  >
                    {detailData.vocaInfo.voca_kr_dsc}
                  </pre>
                </label>
              </div>
              {/*
              <div style={{ marginTop: "0px" }}>
                <label
                  className="uploadFile"
                  style={{ padding: "10px 20px", border: 0, lineHeight: "30px", fontSize: "2em", textAlign: "center" }}
                >
                  <span className="filename">
                    <h5
                      style={{
                        fontWeight: "normal"
                      }}
                    >
                      {detailData.vocaInfo.voca_tens}
                    </h5>
                  </span>
                </label>
              </div>
              */}
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-12" style={{ padding: "6px", marginTop: "10px", marginBottom: "30px" }}>
            <div style={{ border: "0px solid #676767db", borderRadius: "8px", padding: "6px", position: "relative" }}>
              <div
                className="form-inner"
                style={{
                  position: "absolute",
                  zIndex: 10,
                  top: "45px",
                  right: "-8px"
                }}
              >
                <button
                  onClick={(e) => onTransClick(trans)}
                  className="submit"
                  style={{ maxWidth: "110px", float: "right", padding: "8px 20px", margin: "20px" }}
                >
                  {!trans ? "번역보기" : "번역숨김"}
                </button>
              </div>
              <h2 className="tf-title-heading style-2 mg-bt-12" style={{ margin: "60px 0 20px 0", textAlign: "center" }}>
                예문
              </h2>

              <div className="form-inner" style={{ paddingBottom: "40px" }}>
                {detailData.vocaSentInfo.map((item, index) => (
                  <div key={index} className="filename" style={{ position: "relative" }}>
                    <label
                      onClick={(e) => onVocaClick(e, item)}
                      className="uploadFile"
                      style={{
                        overflow: "visible",
                        fontSize: "1.7em",
                        fontWeight: "normal",
                        padding: "26px 0 26px 10px",
                        margin: "10px 0 40px 0"
                      }}
                    >
                      {/*
                      <div
                        className="tags"
                        style={{
                          position: "absolute",
                          left: "6px",
                          top: "-14px",
                          padding: "0px 6px",
                          fontSize: "0.6em",
                          fontWeight: "bold",
                          letterSpacing: "0",
                          lineHeight: "30px",
                          height: "28px",
                          background: "#353736",
                          borderRadius: "50px"
                        }}
                      >
                        LV.{index + 1}
                      </div>
                      */}
                      <div
                        style={
                          sentPlay && seed === item.sent_id
                            ? {
                                color: "#f0c45b",
                                lineHeight: "30px",
                                fontSize: "1.2em",
                                fontWeight: "bold"
                              }
                            : {
                                color: "#f0c45b",
                                lineHeight: "30px",
                                fontSize: "1.2em",
                                fontWeight: "bold"
                              }
                        }
                      >
                        {item.sent_id === seed ? (
                          <TypeAnimation
                            sequence={[
                              1500,
                              () => {
                                sentAudio.play()
                                console.log("typing")
                              },
                              item.sent_en,
                              () => {
                                console.log("done")
                                setSeed(-1)
                                setSentPlay(false)
                                //sentAudio.pause()
                                //sentAudio.currentTime = 0
                              }
                            ]}
                            speed={{
                              type: "keyStrokeDelayInMs",
                              value: sex ? item.sent_mp3_m_ss / 45 : item.sent_mp3_w_ss / 45
                            }}
                            style={{ fontSize: "1em", color: "#f0c45b", fontWeight: "bold" }}
                            repeat={0}
                            omitDeletionAnimation={true}
                            //preRenderFirstString={true}
                          />
                        ) : (
                          item.sent_en
                        )}
                      </div>
                      <div
                        style={
                          trans
                            ? {
                                lineHeight: "30px",
                                fontSize: "0.9em",
                                display: "block"
                              }
                            : {
                                lineHeight: "30px",
                                fontSize: "0.9em",
                                display: "none"
                              }
                        }
                      >
                        {item.sent_kr}
                      </div>
                      {item?.mark_yn === "Y" ? (
                        <FaBookmark
                          onClick={(e) => onSentBookmarkClick("N", item, e)}
                          size="34"
                          style={{ color: "#249fff", position: "absolute", top: "-8px", right: "10px" }}
                        />
                      ) : (
                        <FaBookmark
                          onClick={(e) => onSentBookmarkClick("Y", item, e)}
                          size="34"
                          style={{ color: "#666", position: "absolute", top: "-8px", right: "10px" }}
                        />
                      )}
                      <div style={{ display: "inline-block", position: "absolute", top: "-44px", right: "15px" }}>
                        <PulseLoader
                          loading={(isSentBookMarkMutating || detailLoading) && sentMark === item.sent_id}
                          size={4}
                          color="yellow"
                        />
                      </div>
                      <BeatLoader
                        loading={sentPlay && seed === item.sent_id}
                        color="#5142FC"
                        size={8}
                        style={{ position: "absolute", right: "10px", bottom: "-35px" }}
                      />
                    </label>
                  </div>
                ))}

                <div className="row" style={{ padding: "6px" }}>
                  <div className={nextData ? "col-xl-6 col-lg-6 col-md-6 col-6" : "col-xl-12 col-lg-12 col-md-12 col-12"}>
                    <button
                      className="submit"
                      onClick={() => onBeforeClick()}
                      style={beforeData ? { padding: "16px 18px" } : { display: "none" }}
                    >
                      이전
                    </button>
                  </div>
                  <div className={beforeData ? "col-xl-6 col-lg-6 col-md-6 col-6" : "col-xl-12 col-lg-12 col-md-12 col-12"}>
                    <button
                      className="submit"
                      onClick={() => onNextClick()}
                      style={nextData ? { padding: "16px 18px", display: "block" } : { display: "none" }}
                    >
                      다음
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoDataFooter />
    </section>
  )
}

export default WordStudy

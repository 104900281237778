import React, { useCallback, useRef, useEffect, useMemo, useState } from "react"
import { AgGridReact } from "ag-grid-react"
import { assign } from "lodash"
import "ag-grid-community"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css"
import ProgressBar from "react-bootstrap/ProgressBar"
import { useNavigate, useSearchParams } from "react-router-dom"
import useVocaQuizStore from "src/store/VocaQuizStore"
import useLoginStore from "src/store/LoginStore"
import NoHeaderLoading from "src/components/comm/NoHeaderLoading"
import { useVocaQuzDetail, useVocaQuzInsert } from "src/api/VocaQuizApi"

const SentQuiz = () => {
  const gridRef1 = useRef(null)
  const gridRef2 = useRef(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const svc_id = searchParams.get("svc_id")
  const cat_id = searchParams.get("cat_id")
  const mem_std_id = searchParams.get("mem_std_id")
  const quz_arr = searchParams.get("quz_arr")

  const { login } = useLoginStore()
  const { quizBase, setQuizDone } = useVocaQuizStore()

  const [submitShow, setSubmitShow] = useState(false)
  const [score, setScore] = useState(0)

  const { data: detailData, isLoading: detailLoading } = useVocaQuzDetail(quizBase[cat_id][quz_arr].quz_id)
  const { trigger, isMutating } = useVocaQuzInsert()

  const [value1, setValue1] = useState(null)
  const [value2, setValue2] = useState(null)

  const [wordGrp1, setWordGrp1] = useState(null)
  const [wordGrp2, setWordGrp2] = useState(null)

  const [result1, setResult1] = useState(null)
  const [result2, setResult2] = useState(null)

  const [columnDefs, setColumnDefs] = useState([{ field: "opt_ans" /*, rowDrag: true*/ }])
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      flex: 1,
      cellStyle: { textAlign: "center", paddingLeft: "2px", paddingRight: "2px" }
    }
  }, [])

  const onRowClicked1 = useCallback(
    (params) => {
      setValue1(params.data)
      const qst = detailData.qst[0]
      assign(qst, { user: params.data.opt_ans })
      setResult1(qst)
    },
    [value1, detailLoading]
  )

  const onRowClicked2 = useCallback(
    (params) => {
      setValue2(params.data)
      const qst = detailData.qst[1]
      assign(qst, { user: params.data.opt_ans })
      setResult2(qst)
    },
    [value2, detailLoading]
  )

  const onSubmitClick = useCallback(async () => {
    console.log(result1, result2)
    const resultArr = []
    if (result1) resultArr.push(result1)
    if (result2) resultArr.push(result2)
    console.log(resultArr)

    const qstData = resultArr.map((item) => {
      return {
        qst_id: item.qst_id,
        voca_id: item.voca_id,
        qst_rgt_yn: item.qst_ans === item.user ? "Y" : "N",
        qst_sol_ans: item.user,
        reg_mem_id: login.user_id
      }
    })

    setQuizDone(cat_id, Number(quz_arr))

    let quz_rgt_yn = "Y"
    qstData.map((item) => {
      if (item.qst_rgt_yn === "N") {
        quz_rgt_yn = "N"
      }
    })

    const submitData = {
      mem_std_id: mem_std_id,
      quz_id: quizBase[cat_id][Number(quz_arr)].quz_id,
      quz_rgt_yn: quz_rgt_yn,
      quz_end_yn: "Y",
      quz_qst: qstData,
      reg_mem_id: login.user_id
    }
    console.log(submitData)
    await trigger(submitData)

    const nextQuiz = quizBase[cat_id][Number(quz_arr) + 1]
    console.log(nextQuiz)
    console.log("cat_id : ", cat_id)
    console.log("quz_arr : ", quz_arr)

    setResult1(null)
    setResult2(null)

    if (nextQuiz) {
      console.log(nextQuiz)
      if (nextQuiz.quz_typ === "W") {
        navigate(`/quiz/word-quiz?svc_id=${svc_id}&cat_id=${cat_id}&mem_std_id=${mem_std_id}&quz_arr=${Number(quz_arr) + 1}`, {
          replace: true
        })
      } else if (nextQuiz.quz_typ === "S") {
        navigate(`/quiz/sent-quiz?svc_id=${svc_id}&cat_id=${cat_id}&mem_std_id=${mem_std_id}&quz_arr=${Number(quz_arr) + 1}`, {
          replace: true
        })
      }
    } else {
      navigate(`/quiz/finish-quiz?svc_id=${svc_id}&cat_id=${cat_id}&mem_std_id=${mem_std_id}`, { replace: true })
    }
  }, [result1, result2])

  const onGridReady1 = useCallback((params) => {
    params.api.sizeColumnsToFit()
  }, [])

  const onGridReady2 = useCallback((params) => {
    params.api.sizeColumnsToFit()
  }, [])

  const onGridSizeChanged1 = useCallback((params) => {
    params.api.sizeColumnsToFit()
  }, [])

  const onGridSizeChanged2 = useCallback((params) => {
    params.api.sizeColumnsToFit()
  }, [])

  const noData = () => {
    return <div style={{ display: "none" }}>No Rows</div>
  }

  const Container = ({ str }) => {
    console.log(str)
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: str
            .replace("#1", `<div class="tile-container1 quiz_blank">${value1?.opt_ans ? value1?.opt_ans : ""}</div>`)
            .replace("#2", `<div class="tile-container1 quiz_blank">${value2?.opt_ans ? value2?.opt_ans : ""}</div>`)
        }}
      ></div>
    )
  }

  useEffect(() => {
    //console.log(wordGrp1, wordGrp2, value1, value2)
    if (wordGrp1?.length >= 1 && wordGrp2?.length === 0) {
      if (value1) {
        setSubmitShow(true)
      }
    } else if (wordGrp1?.length >= 1 && wordGrp2?.length >= 1) {
      if (value1 && value2) {
        setSubmitShow(true)
      }
    }
  }, [value1, value2])

  useEffect(() => {
    const optArr = detailData.opt
    const grp1 = optArr?.filter((item) => {
      return item.quz_grp === 1
    })
    const grp2 = optArr?.filter((item) => {
      return item.quz_grp === 2
    })
    setWordGrp1(grp1)
    setWordGrp2(grp2)
  }, [detailData])

  useEffect(() => {
    setValue1(null)
    setValue2(null)
    setSubmitShow(false)
  }, [quz_arr])

  useEffect(() => {
    console.log(quizBase[cat_id].length)
    const complete = quizBase[cat_id].filter((item) => {
      return item.complete
    })
    console.log(complete.length)
    setScore((complete.length / quizBase[cat_id].length) * 100)
  }, [quz_arr])

  if (detailLoading) {
    return <NoHeaderLoading />
  }

  if (isMutating) {
    return <NoHeaderLoading />
  }

  return (
    <>
      <section className="tf-section top-seller home5 s2 mobie-style bg-style2" style={{ padding: "10px 0 0 0" }}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-0 col-sm-0"></div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <ProgressBar
                animated
                variant="primary"
                now={score}
                label={`${Math.round(score)}%`}
                style={{ lineHeight: "14px", height: "14px", fontSize: "1.2em" }}
              />
              <p style={{ textAlign: "center", fontSize: "2.2em", fontWeight: "bold", marginTop: "16px" }}>문장퀴즈</p>
              <p style={{ textAlign: "center", fontSize: "1.4em", color: "orange" }}>{quizBase[cat_id][quz_arr].quz_ttl_kor}</p>

              <div className="form-inner" style={{ paddingBottom: "0px" }}>
                <div className="filename">
                  <label
                    className="uploadFile"
                    style={{
                      lineHeight: "30px",
                      overflow: "visible",
                      fontSize: "1.6em",
                      fontWeight: "bold",
                      padding: "14px 0 10px 4px",
                      margin: "10px 0 0px 0"
                    }}
                  >
                    <div>
                      <Container str={quizBase[cat_id][quz_arr].quz_qst_eng} />
                    </div>
                    <p style={{ fontSize: "0.9em" }}>{quizBase[cat_id][quz_arr].quz_qst_kor}</p>
                  </label>
                </div>
              </div>
              <div
                style={{
                  boxSizing: "border-box",
                  flex: "1 1 0px"
                }}
              >
                <div className="row" style={{ padding: "0px 16px" }}>
                  <div
                    //onClick={(e) => onDropZoneClick(item, index, e)}
                    style={{ padding: "10px 2px", textAlign: "center", cursor: "pointer" }}
                    className="col-xl-6 col-lg-6 col-md-6 col-6"
                  ></div>
                </div>
              </div>

              <div style={{ width: "100%", height: "100%" }}>
                <div className="example-wrapper">
                  <div className="drop-containers">
                    <div className="grid-wrapper">
                      <div className="row" style={{ padding: "0px" }}>
                        {wordGrp1?.length >= 1 ? (
                          <div
                            className={
                              wordGrp2?.length >= 1 ? "col-xl-6 col-lg-6 col-md-6 col-6" : "col-xl-12 col-lg-12 col-md-12 col-12"
                            }
                            style={{ padding: "4px" }}
                          >
                            <div
                              className={"ag-theme-alpine-dark word-quiz-grid"}
                              style={{ marginTop: "6px", height: "302px", padding: "0 0 0 4px" }}
                            >
                              <AgGridReact
                                ref={gridRef1}
                                rowData={wordGrp1}
                                rowHeight={50}
                                noRowsOverlayComponent={noData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                onRowClicked={onRowClicked1}
                                rowDragManaged={true}
                                //rowDragEntireRow={true}
                                rowDragMultiRow={false}
                                rowSelection={"single"}
                                suppressRowClickSelection={false}
                                suppressContextMenu={true}
                                suppressMenuHide={false}
                                onGridReady={onGridReady1}
                                onGridSizeChanged={onGridSizeChanged1}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {wordGrp2?.length >= 1 ? (
                          <div className="col-xl-6 col-lg-6 col-md-6 col-6" style={{ padding: "4px" }}>
                            <div
                              className={"ag-theme-alpine-dark word-quiz-grid"}
                              style={{ marginTop: "6px", height: "302px", padding: "0 4px 0 0" }}
                            >
                              <AgGridReact
                                ref={gridRef2}
                                rowData={wordGrp2}
                                rowHeight={50}
                                noRowsOverlayComponent={noData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                onRowClicked={onRowClicked2}
                                rowDragManaged={true}
                                //rowDragEntireRow={true}
                                rowDragMultiRow={false}
                                rowSelection={"single"}
                                suppressRowClickSelection={false}
                                suppressContextMenu={true}
                                suppressMenuHide={false}
                                onGridReady={onGridReady2}
                                onGridSizeChanged={onGridSizeChanged2}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12" style={{ padding: "4px" }}>
                          {submitShow ? (
                            <div className="form-inner" style={{ padding: "10px" }}>
                              <button onClick={() => onSubmitClick()} className="submit" style={{ display: "block" }}>
                                정답제출
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-0 col-sm-0"></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SentQuiz

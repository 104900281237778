import React from "react"
import { Link } from "react-router-dom"
import icon1 from "../../../assets/images/icon/Wallet.png"
import icon2 from "../../../assets/images/icon/Category.png"
import icon3 from "../../../assets/images/icon/Image2.png"
import icon4 from "../../../assets/images/icon/Favicon.png"

const Create = () => {
  const data = [
    {
      title: "나만의 영어 표현노트",
      description: "핵심 영어표현들을 익히면서 나만의 표현노트를 만드세요",
      icon: icon1,
      colorbg: "icon-color1"
    },
    {
      title: "영어 퀴즈",
      description: "학습한 영어표현을 재미있는 퀴즈로 확실하게 익히세요",
      icon: icon3,
      colorbg: "icon-color3"
    },
    {
      title: "연속, 반복 음원 재생",
      description: "나의 표현노트의 문장을 연속된 음원으로 학습하세요",
      icon: icon4,
      colorbg: "icon-color4"
    },
    {
      title: "AI 미션스피킹",
      description: "상황별로 영어 대화하면서 더 나은 대화로 교정받으세요",
      icon: icon2,
      colorbg: "icon-color2"
    }
  ]
  return (
    <section className="tf-box-icon create tf-section bg-home-3">
      <div className="themesflat-container">
        <div className="row">
          {data.map((item, index) => (
            <CreateItem key={index} item={item} />
          ))}
        </div>
      </div>
    </section>
  )
}

const CreateItem = (props) => (
  <div className="col-lg-6 col-md-6 col-12">
    <div className="sc-box-icon">
      <div className="image center">
        <div className={`icon-create ${props.item.colorbg}`}>
          <img src={props.item.icon} alt="" />
        </div>
      </div>
      <h3 className="heading">
        <Link to="/wallet-connect">{props.item.title}</Link>
      </h3>
      <p className="content">{props.item.description}</p>
    </div>
  </div>
)

export default Create

import React, { useCallback, useRef, useState, useMemo, useEffect } from "react"
import { RxSpeakerQuiet } from "react-icons/rx"
import { BsStopCircleFill } from "react-icons/bs"
import { BsPlayCircleFill } from "react-icons/bs"
import { RiEditCircleFill } from "react-icons/ri"
import { IoIosRemoveCircle } from "react-icons/io"
import { IoMdRefreshCircle } from "react-icons/io"
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3"
import { getSignedUrl } from "@aws-sdk/s3-request-presigner"
import WithHeaderLoading from "src/components/comm/WithHeaderLoading"
import CardModal from "src/components/layouts/CardModal"
import { TypeAnimation } from "react-type-animation"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css"
import useLoginStore from "src/store/LoginStore"
import { useVocaBookmark } from "src/api/VocaApi"

const MyWordList = ({ vocaBookmarkList, vocaBookmarkListMutate }) => {
  const S3 = new S3Client({
    region: "auto",
    endpoint: process.env.REACT_APP_CLOUDFLARE_ENDPOINT,
    credentials: {
      accessKeyId: process.env.REACT_APP_CLOUDFLARE_ACCESSKEYID,
      secretAccessKey: process.env.REACT_APP_CLOUDFLARE_SECRETACCESSKEY
    }
  })

  const gridRef = useRef(null)
  const { login, sex } = useLoginStore()
  const [windowHeight, setWindowHeight] = useState()

  const [play, setPlay] = useState(false)
  const [vocaSingleAudio, setVocaSingleAudio] = useState(new Audio())

  const [listPlay, setListPlay] = useState(false)
  const [vocaListAudio, setVocaListAudio] = useState(new Audio())

  const [loading, setLoading] = useState(false)

  const [dataSearch, setDataSearch] = useState("")
  const [edit, setEdit] = useState(false)
  //const [modalShow, setModalShow] = useState(false)

  const { trigger: vocaBookmarkTrigger, isMutating: isVocaBookMarkMutating } = useVocaBookmark()

  const columnDefs = useMemo(
    () => [
      /*
    부하 때문에 드래그 순위조정이 아닌 하트나, 무조건 최상위(타임스탬프 활용) 버튼처리 하는게 좋겠음.
    {
      field: "sent_ord",
      cellStyle: { textAlign: "right", padding: "9px 0 0 12px", fontSize: "2em" },
      rowDrag: (params) => {
        return true
      },
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      hide: true,
      getQuickFilterText: () => ""
    },*/
      {
        field: "voca_tg",
        cellStyle: {
          fontSize: "1.8em",
          lineHeight: "30px",
          fontWeight: "bold",
          verticalAlign: "middle",
          wordBreak: "keep-all",
          whiteSpace: "pre-line",
          padding: "14px 10px 14px 28px"
        },
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params) => {
          return (
            <>
              <div onClick={() => onClickSinglePlay(params)}>{params.value}</div>
              <span style={{ fontSize: "0.6em", color: "#dcd579" }}>{params.data.voca_mean}</span>
            </>
          )
        }
      },
      {
        field: "voca_mean",
        hide: true
      },
      {
        field: "voca_sound",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px", verticalAlign: "bottom" },
        width: 70,
        minWidth: 70,
        maxWidth: 70,
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params) => {
          return (
            <div style={{ textAlign: "center" }}>
              <RxSpeakerQuiet onClick={() => onClickSinglePlay(params)} size="28" style={{ margin: "31px 2px 0 16px" }} />
            </div>
          )
        }
      },
      {
        field: "voca_remove",
        cellStyle: { textAlign: "right", paddingLeft: "2px", paddingRight: "2px", verticalAlign: "bottom" },
        width: 70,
        minWidth: 70,
        maxWidth: 70,
        hide: true,
        cellRenderer: (params) => {
          return (
            <div style={{ textAlign: "center" }}>
              <IoIosRemoveCircle
                onClick={(e) => onClickRemove(params, e)}
                size="28"
                color="#de9717"
                style={{ margin: "31px 7px 0 16px" }}
              />
            </div>
          )
        }
      }
    ],
    [play, listPlay]
  )

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      flex: 1
    }
  }, [])

  const gridCommonOptions = useMemo(() => {
    return {
      animateRows: true,
      pagination: false,
      groupHeaderHeight: 30,
      headerHeight: 30,
      floatingFiltersHeight: 30,
      pivotHeaderHeight: 30,
      pivotGroupHeaderHeight: 30,
      enableRangeSelection: true,
      suppressMultiRangeSelection: true,
      tooltipShowDelay: 500,
      tooltipHideDelay: 10000,
      //paginationAutoPageSize: true,
      suppressPaginationPanel: true
    }
  }, [])

  const onClickRemove = useCallback(async (params, e) => {
    e.stopPropagation()
    const data = {
      voca_id: params.data.voca_id,
      mem_id: login?.mem_id,
      mark_yn: "N"
    }
    const result = await vocaBookmarkTrigger(data)
    if (result) {
      vocaBookmarkListMutate()
    }
  }, [])

  const onClickSinglePlay = useCallback(
    async (item) => {
      if (play || listPlay) {
        return
      }
      try {
        const response = await getSignedUrl(S3, new GetObjectCommand({ Bucket: "word-bucket", Key: item.data.voca_mp3_m }), {
          expiresIn: 30
        })
        console.log(response)
        vocaSingleAudio.src = response
        const rowNode = gridRef.current.api.getRowNode(item.node.id)
        rowNode.updateData({
          voca_id: item.data.voca_id,
          mark_yn: item.data.mark_yn,
          voca_mean: item.data.voca_mean,
          voca_mp3_m: item.data.voca_mp3_m,
          voca_mp3_m_ss: item.data.voca_mp3_m_ss,
          voca_mp3_w: item.data.voca_mp3_w,
          voca_mp3_w_ss: item.data.voca_mp3_w_ss,
          voca_tg: (
            <TypeAnimation
              sequence={[
                0,
                () => {
                  vocaSingleAudio.play()
                  console.log("typing")
                },
                item.data.voca_tg,
                () => {
                  console.log("done")
                  rowNode.updateData({
                    voca_id: item.data.voca_id,
                    mark_yn: item.data.mark_yn,
                    voca_tg: item.data.voca_tg,
                    voca_mean: item.data.voca_mean,
                    voca_mp3_m: item.data.voca_mp3_m,
                    voca_mp3_m_ss: item.data.voca_mp3_m_ss,
                    voca_mp3_w: item.data.voca_mp3_w,
                    voca_mp3_w_ss: item.data.voca_mp3_w_ss
                  })
                  setPlay(false)
                  //vocaSingleAudio.pause()
                  //vocaSingleAudio.currentTime = 0
                }
              ]}
              //speed={sex ? Math.round(item.data.sent_mp3_m_ss / 200) : Math.round(item.data.sent_mp3_w_ss / 200)}
              speed={1}
              style={{ fontSize: "1em", color: "#f0c45b" }}
              repeat={0}
              omitDeletionAnimation={true}
            />
          )
        })
      } catch (e) {
        alert(e)
        vocaSingleAudio.pause()
        vocaSingleAudio.currentTime = 0
        setPlay(false)
        console.log(e)
      }
    },
    [play, listPlay, vocaSingleAudio]
  )

  const onGridReady = useCallback((params) => {
    setWindowHeight(window.innerHeight)
    params.api.sizeColumnsToFit()
  }, [])

  const onGridSizeChanged = useCallback((params) => {
    params.api.sizeColumnsToFit()
  }, [])

  const autoPlayLoop = useCallback(
    async (rowNodeArr, index) => {
      if (play || listPlay) {
        return
      }

      try {
        const response = await getSignedUrl(
          S3,
          new GetObjectCommand({ Bucket: "word-bucket", Key: rowNodeArr[index].data.voca_mp3_m }),
          { expiresIn: 30 }
        )
        vocaListAudio.src = response
        //console.log(sentBookmarkList)
        //console.log(rowNodeArr[index])
        const voca_id_origin = rowNodeArr[index].data.voca_id
        const voca_tg_oirgin = rowNodeArr[index].data.voca_tg
        const voca_mean_origin = rowNodeArr[index].data.voca_mean
        const voca_mp3_m_origin = rowNodeArr[index].data.voca_mp3_m
        const voca_mp3_m_ss_origin = rowNodeArr[index].data.voca_mp3_m_ss
        const voca_mp3_w_origin = rowNodeArr[index].data.voca_mp3_w
        const voca_mp3_w_ss_origin = rowNodeArr[index].data.voca_mp3_w_ss

        rowNodeArr[index].updateData({
          voca_id: rowNodeArr[index].data.voca_id,
          mark_yn: rowNodeArr[index].data.mark_yn,
          voca_mean: rowNodeArr[index].data.voca_mean,
          voca_mp3_m: rowNodeArr[index].data.voca_mp3_m,
          voca_mp3_m_ss: rowNodeArr[index].data.voca_mp3_m_ss,
          voca_mp3_w: rowNodeArr[index].data.voca_mp3_w,
          voca_mp3_w_ss: rowNodeArr[index].data.voca_mp3_w_ss,
          voca_tg: (
            <TypeAnimation
              sequence={[
                1200,
                () => {
                  vocaListAudio.play()
                  console.log("typing")
                },
                rowNodeArr[index].data.voca_tg,
                () => {
                  console.log("done")
                  rowNodeArr[index].updateData({
                    voca_id: voca_id_origin,
                    voca_tg: voca_tg_oirgin,
                    voca_mean: voca_mean_origin,
                    voca_mp3_m: voca_mp3_m_origin,
                    voca_mp3_m_ss: voca_mp3_m_ss_origin,
                    voca_mp3_w: voca_mp3_w_origin,
                    voca_mp3_w_ss: voca_mp3_w_ss_origin
                  })
                  if (rowNodeArr.length - 1 === index) {
                    setListPlay(false)
                    console.log("finish")
                  } else {
                    const timeout = setTimeout(() => {
                      vocaListAudio.pause()
                      vocaListAudio.currentTime = 0
                      autoPlayLoop(rowNodeArr, index + 1)
                      clearTimeout(timeout)
                    }, 1000)
                  }
                }
              ]}
              speed={1}
              style={{ fontSize: "1em", color: "#f0c45b" }}
              repeat={0}
              omitDeletionAnimation={true}
            />
          )
        })
      } catch (e) {
        alert(e)
        vocaListAudio.pause()
        vocaListAudio.currentTime = 0
        setListPlay(false)
        console.log(e)
      }
    },
    [play, listPlay, vocaListAudio]
  )

  const onClickListPlay = useCallback(
    (isListPlay) => {
      if (play) {
        return
      }
      if (isListPlay) {
        setListPlay(isListPlay)
        //그리드의 싱글플레이 컬럼 없애자
        let rowNodeArr = []
        gridRef.current.api.forEachNodeAfterFilter((node) => rowNodeArr.push(node))
        autoPlayLoop(rowNodeArr, 0)
      } else {
        setListPlay(false)
        setLoading(true)
        vocaBookmarkListMutate()
        const timeout = setTimeout(() => {
          setLoading(false)
          clearTimeout(timeout)
        }, 1000)
      }
    },
    [play, listPlay]
  )

  const searchKeyDown = useCallback((e) => {
    if (e.key === "Enter") {
      gridRef.current.api.setQuickFilter(e.target.value)
    } else if (e.target.value === "") {
      gridRef.current.api.setQuickFilter("")
    }
  }, [])

  const searchChange = useCallback((e) => {
    //console.log(e.target.value)
    e.preventDefault()
    console.log(e.target.value)
    setDataSearch(e.target.value)
  }, [])

  const refresh = useCallback(
    (e) => {
      setLoading(true)
      vocaBookmarkListMutate()
      const timeout = setTimeout(() => {
        setLoading(false)
        setPlay(false)
        setListPlay(false)
        clearTimeout(timeout)
      }, 1000)
    },
    [play, listPlay]
  )

  useEffect(() => {
    window.addEventListener("resize", () => setWindowHeight(window.innerHeight))
    return () => {
      window.removeEventListener("resize", () => setWindowHeight(window.innerHeight))
    }
  }, [window.innerHeight])

  useEffect(() => {
    vocaSingleAudio.addEventListener("play", function () {
      setPlay(true)
    })
    vocaSingleAudio.addEventListener("playing", function () {
      setPlay(true)
    })
    vocaSingleAudio.addEventListener("ended", function () {
      setPlay(false)
    })
  }, [play])

  useEffect(() => {
    vocaListAudio.addEventListener("play", function () {
      setListPlay(true)
    })
    vocaListAudio.addEventListener("playing", function () {
      setListPlay(true)
    })
    vocaListAudio.addEventListener("ended", function () {
      setListPlay(false)
    })
  }, [listPlay])

  useEffect(() => {
    if (listPlay) {
      setEdit(false)
      //gridRef?.current?.columnApi?.setColumnsVisible(["voca_sound"], false)
    } else {
      //gridRef?.current?.columnApi?.setColumnsVisible(["voca_sound"], true)
    }
    gridRef?.current?.api?.sizeColumnsToFit()
  }, [listPlay])

  useEffect(() => {
    if (edit) {
      gridRef?.current?.columnApi?.setColumnsVisible(["voca_sound"], false)
      gridRef?.current?.columnApi?.setColumnsVisible(["voca_remove"], true)
      //gridRef?.current?.columnApi?.setColumnsVisible(["sent_ord"], true)
    } else {
      gridRef?.current?.columnApi?.setColumnsVisible(["voca_remove"], false)
      //gridRef?.current?.columnApi?.setColumnsVisible(["sent_ord"], false)
      if (listPlay) {
        gridRef?.current?.columnApi?.setColumnsVisible(["voca_sound"], false)
      } else {
        gridRef?.current?.columnApi?.setColumnsVisible(["voca_sound"], true)
      }
    }
    gridRef?.current?.api?.sizeColumnsToFit()
  }, [edit, listPlay])

  const noData = () => {
    return <div style={{ height: "70px" }}>등록된 단어가 없습니다.</div>
  }

  return (
    <>
      <div className="row" style={{ marginTop: "113px", marginBottom: "10px" }}>
        <div className="col-12" style={{ padding: "0px 22px" }}>
          <div
            className="row"
            style={{
              padding: "0 10px 6px 0",
              margin: "12px 0 6px 0",
              position: "fixed",
              top: "52px",
              zIndex: 100,
              background: "#000",
              width: "100%",
              maxWidth: "1440px"
            }}
          >
            <div className="col-6" style={{ padding: "0px" }}>
              <input
                onChange={searchChange}
                onKeyUp={searchKeyDown}
                value={dataSearch}
                pattern="[a-zA-Z0-9]+"
                type="text"
                id="text"
                name="text"
                tabIndex="1"
                aria-required="true"
                placeholder="Search"
                style={{ lineHeight: "18px", height: "47px", maxWidth: "300px", textTransform: "lowercase" }}
              />
            </div>

            <div className="col-6" style={{ padding: "0px 8px", textAlign: "right" }}>
              <IoMdRefreshCircle onClick={() => refresh()} size="46" style={{ margin: "0 12px 1px 0" }} />
              {!edit ? (
                <RiEditCircleFill onClick={() => setEdit(true)} size="44" color="#fff" style={{ margin: "0 16px 2px 0" }} />
              ) : (
                <RiEditCircleFill onClick={() => setEdit(false)} size="44" color="#7d92f6" style={{ margin: "0 16px 2px 0" }} />
              )}
              {!listPlay ? (
                <BsPlayCircleFill onClick={() => onClickListPlay(true)} size="39" color="#fff" style={{ margin: "0 0 5px 0" }} />
              ) : (
                <BsStopCircleFill
                  onClick={() => onClickListPlay(false)}
                  size="39"
                  color="#7d92f6"
                  style={{ margin: "0 0 5px 0" }}
                />
              )}
            </div>
          </div>
          <div
            id="myWords"
            className={"ag-theme-alpine-dark"}
            style={{
              //minHeight: `${windowHeight - 130}px`,
              //height: `${vocaBookmarkList.length * 95}px`,
              paddingTop: "10px"
            }}
          >
            {loading ? (
              <WithHeaderLoading />
            ) : (
              <AgGridReact
                ref={gridRef}
                rowHeight={93}
                rowData={vocaBookmarkList}
                noRowsOverlayComponent={noData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                gridOptions={gridCommonOptions}
                //onRowClicked={onRowClicked2}
                //rowDragManaged={true}
                //rowDragEntireRow={true}
                //tooltipInteraction={true}
                //tooltipShowDelay={200}
                //onRowClicked={onRowClicked}
                domLayout={"autoHeight"}
                rowDragMultiRow={false}
                enableRangeSelection={false}
                suppressRowClickSelection={false}
                suppressContextMenu={true}
                suppressMenuHide={false}
                suppressHorizontalScroll={true}
                rowDragManaged={false}
                onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
              />
            )}
          </div>
        </div>
      </div>
      {/*<CardModal show={modalShow} onHide={() => setModalShow(false)} />*/}
    </>
  )
}
export default MyWordList

import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Navigation, Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/scss"
import "swiper/scss/navigation"
import "swiper/scss/pagination"
import shape1 from "../../assets/images/backgroup-secsion/bg-gradient1.png"
import shape2 from "../../assets/images/backgroup-secsion/bg-gradient2.png"
import shape3 from "../../assets/images/backgroup-secsion/bg-gradient3.png"
import imgbg from "../../assets/images/backgroup-secsion/img_bg_page_title.jpg"

import useLoginStore from "src/store/LoginStore"

const SliderStyle1 = (props) => {
  const { login, setLogin } = useLoginStore()
  const data = props.data
  return (
    <div className="mainslider">
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: true }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className={item.class}>
            <div className="flat-title-page" style={{ backgroundImage: `url(${imgbg})`, paddingBottom: "70px" }}>
              <img className="bgr-gradient gradient1" src={shape1} alt="Axies" />
              <img className="bgr-gradient gradient2" src={shape2} alt="Axies" />
              <img className="bgr-gradient gradient3" src={shape3} alt="Axies" />
              <div className="shape item-w-16"></div>
              <div className="shape item-w-22"></div>
              <div className="shape item-w-32"></div>
              <div className="shape item-w-48"></div>
              <div className="shape style2 item-w-51"></div>
              <div className="shape style2 item-w-51 position2"></div>
              <div className="shape item-w-68"></div>
              <div className="overlay"></div>
              <div className="swiper-container mainslider home">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="slider-item">
                      <div className="themesflat-container ">
                        <div className="wrap-heading flat-slider flex">
                          <div className="content" style={{ marginLeft: "-15px" }}>
                            <h2 className="heading" style={{ fontSize: "3.2em", lineHeight: "0", margin: "0 0 16px 0" }}>
                              {item.title_1}
                            </h2>
                            <h4 className="heading mb-style">
                              <span style={{ fontSize: "0.7em" }}>{item.title_2}</span>
                            </h4>
                            <h1 className="heading" style={{ margin: "24px 0 40px 0", lineHeight: "0" }}>
                              <span className="fill" style={{ fontSize: "0.4em" }}>
                                {item.title_3}
                              </span>
                              <span style={{ fontSize: "0.35em" }}></span>
                            </h1>
                            <h5 className="sub-heading" style={{ color: "#fbb34d", fontSize: "1.8em", margin: 0 }}>
                              {item.description1}
                            </h5>
                            <h5 className="sub-heading" style={{ color: "#fbb34d", fontSize: "1.8em", margin: "-4px 0 34px 0" }}>
                              {item.description2}
                            </h5>
                            <div className="flat-bt-slider flex style2">
                              {!login ? (
                                <Link to="/login" className="sc-button header-slider style style-1 rocket fl-button pri-1">
                                  <span>로그인하고 시작하기</span>
                                </Link>
                              ) : (
                                <Link to="/dashboard" className="sc-button header-slider style style-1 rocket fl-button pri-1">
                                  <span>공부하러가기</span>
                                </Link>
                              )}
                            </div>
                          </div>
                          <div className="image">
                            <img className="img-bg" src={item.imgbg} alt="axies" />
                            <img src={item.img} alt="axies" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SliderStyle1

const useUtils = () => {
  const comma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  return {
    comma
  }
}

export default useUtils

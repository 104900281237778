import React from "react"
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap"

const CardModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} className="word-modal" animation={false}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40" style={{ height: "800px" }}>
        <h3>단어학습</h3>
        <Link
          to="/wallet-connect"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#popup_bid_success"
          data-dismiss="modal"
          aria-label="Close"
        >
          {" "}
          Place a bid
        </Link>
      </div>
    </Modal>
  )
}

export default CardModal

import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"

const PolicyMarketing = () => {
  return (
    <div>
      <Header />
      <section className="tf-contact tf-section">
        <div className="themesflat-container" style={{ marginTop: "70px" }}>
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <h2 className="tf-title-heading ct style-2 mg-bt-40">마케팅 수신동의</h2>

                <h3 style={{ marginBottom: "10px" }}>1. 광고성 정보의 이용목적</h3>
                <h6 className="sub-title style-2">
                  Ai Tutor가 제공하는 이용자 맞춤형 서비스 및 상품 추천, 각종 경품 행사, 이벤트 등의 광고성 정보를 전자우편이나
                  서신우편, 문자(SMS 또는 카카오 알림톡), 푸시, 전화 등을 통해 이용자에게 제공합니다.
                  <br />
                  마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라 동의를 철회할 수 있습니다. 동의를
                  거부하시더라도 Ai Tutor가 제공하는 서비스의 이용에 제한이 되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품
                  추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>2. 미동의 시 불이익 사항</h3>
                <h6 className="sub-title style-2">
                  개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의 거부하시더라도 서비스 이용에 제한되지 않습니다.
                  단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>3. 서비스 정보 수신 동의 철회</h3>
                <h6 className="sub-title style-2">
                  Ai Tutor에서 제공하는 마케팅 정보를 원하지 않을 경우 ‘내 정보 수정 - 추가 정보’에서 철회를 요청할 수 있습니다.
                  또한 향후 마케팅 활용에 새롭게 동의하고자 하는 경우에는 ‘내 정보 수정 - 추가 정보’에서 동의하실 수 있습니다.
                  <br />
                  시행일자 : 2020.06.01
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default PolicyMarketing

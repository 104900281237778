import Home from "./Home"
import Login from "./login/Login"
import LoginPlatform from "./login/LoginPlatform"
import KakaoLogin from "./login/KaKaoLogin"
import GoogleLogin from "./login/GoogleLogin"
import Logout from "src/pages/login/Logout"
import Dashboard from "src/pages/dashboard/Dashboard"
import GateList from "src/pages/word/GateList"
import PatternList from "./word/PatternList"
import WordList from "src/pages/word/WordList"
import WordStudy from "src/pages/word/WordStudy"
import Test from "src/pages/word/Test"
import StartQuiz from "src/pages/quiz/StartQuiz"
import WordQuiz from "src/pages/quiz/WordQuiz"
import SentQuiz from "src/pages/quiz/SentQuiz"
import FinishQuiz from "src/pages/quiz/FinishQuiz"
import SignUp from "./login/SignUp"
import NoResult from "./NoResult"
import FAQ from "./FAQ"
import PolicyPrivate from "./policy/PolicyPrivate"
import PolicyRefund from "./policy/PolicyRefund"
import PolicyTerms from "./policy/PolicyTerms"
import PolicyMarketing from "./policy/PolicyMarketing"
import MyNotes from "./word/MyNotes"
//import UppyTest from "./UppyTest"

const routes = [
  { path: "/", component: <Home /> },
  { path: "/kakao-login", component: <KakaoLogin /> },
  { path: "/google-login", component: <GoogleLogin /> },
  { path: "/login", component: <Login /> },
  { path: "/login/platform", component: <LoginPlatform /> },
  { path: "/logout", component: <Logout /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/word/gate-list", component: <GateList /> },
  { path: "/word/word-list", component: <WordList /> },
  { path: "/word/pattern-list", component: <PatternList /> },
  { path: "/word/word-study", component: <WordStudy /> },
  { path: "/word/mynotes", component: <MyNotes /> },
  { path: "/word/test", component: <Test /> },
  { path: "/quiz/start-quiz", component: <StartQuiz /> },
  { path: "/quiz/word-quiz", component: <WordQuiz /> },
  { path: "/quiz/sent-quiz", component: <SentQuiz /> },
  { path: "/quiz/finish-quiz", component: <FinishQuiz /> },
  { path: "/policy/terms", component: <PolicyTerms /> },
  { path: "/policy/private", component: <PolicyPrivate /> },
  { path: "/policy/refund", component: <PolicyRefund /> },
  { path: "/policy/marketing", component: <PolicyMarketing /> },
  { path: "/sign-up", component: <SignUp /> },
  { path: "/no-result", component: <NoResult /> },
  { path: "/faq", component: <FAQ /> }
  /*{ path: "/uppy", component: <UppyTest /> }*/
]

export default routes

import { Link } from "react-router-dom"
import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"

const PolicyRefund = () => {
  return (
    <div>
      <Header />
      <section className="tf-contact tf-section">
        <div className="themesflat-container" style={{ marginTop: "70px" }}>
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <h2 className="tf-title-heading ct style-2 mg-bt-40">취소 및 환불정책</h2>

                <h6 className="sub-title style-2" style={{fontWeight: "bold"}}>
                  평생교육법 시행령 제23조 학습비 반환 규정에 근거하여 환불이 진행되고 있습니다.
                  <br />
                  수강 기간은 30일 (유료 1개월 + 무료 복습 1개월)을 기준으로 합니다.
                  <br />
                  환불 의사를 밝힌 다음 날부터(영업일 기준) 반올림으로 계산하여 환불합니다.
                  <br />
                  반환 사유 발생 시 5일(영업일) 이내 환불됩니다.
                </h6>
                <h6 className="sub-title style-2" style={{marginLeft: "40px"}}>                  
                  <table style={{width: "80%"}}>
                    <tr>
                      <td colSpan="2" style={{width: "75%", background: "#434343"}}>기준</td>
                      <td style={{width: "25%", background: "#434343"}}>환불금액</td>
                    </tr>
                    <tr>
                      <td colSpan="2">결제일 기준 7일 이내 & 5강 미만 수강 시</td>
                      <td>전액 환불</td>
                    </tr>
                    <tr>
                      <td rowSpan="3" style={{verticalAlign: "middle"}}>결제일 기준 8일 초과,<br/> 혹은 5강 이상 수강 시</td>
                      <td style={{width: "30%"}}>수강 기간 1/3 경과 전</td>
                      <td>수강료 2/3 환불</td>
                    </tr>
                    <tr>                      
                      <td>수강 기간 1/2 경과 전</td>
                      <td>수강료 1/2 환불</td>
                    </tr>
                    <tr>                      
                      <td>수강 기간 1/2 경과 후</td>
                      <td>환불 없음</td>
                    </tr>
                  </table>
                  </h6>
                <h6 className="sub-title style-2" style={{ marginBottom: "10px", fontWeight: "bold"}}>
                  (예시) 6월 1일. 결제하였을 경우
                </h6>
                <h6 className="sub-title style-2">
                  - 6월 1일 ~ 6월 7일: (5강 미만 수강 시) 전액 환불 가능
                  <br />
                  - 6월 8일 ~ 6월 10일: 2/3 환불
                  <br />
                  - 6월 11일 ~ 6월 15일: 1/2 환불
                  <br />
                  - 6월 16일 이후: 환불 없음
                </h6>
                <h6 className="sub-title style-2" style={{fontWeight: "bold"}}>
                  기준이 되는 5강은 미리보기로 공개된 강의를 포함한 갯수입니다.
                  <br />
                  환불을 원하는 경우{" "}
                  <Link to="#" style={{color:"#4B50E6", textDecoration: "underline"}}>
                    환불 신청서
                  </Link>
                  를 작성해 주세요.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default PolicyRefund

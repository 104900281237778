import React, { useEffect, useCallback } from "react"
import NoHeaderLoading from "src/components/comm/NoHeaderLoading"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useVocaCatDetail } from "src/api/VocaApi"
import { useVocaQuzList } from "src/api/VocaQuizApi"
import { useVocaQuzStart } from "src/api/VocaQuizApi"
import useVocaQuizStore from "src/store/VocaQuizStore"
import useLoginStore from "src/store/LoginStore"

const StartQuiz = () => {
  const [searchParams] = useSearchParams()
  const svc_id = searchParams.get("svc_id")
  const cat_id = searchParams.get("cat_id")

  const navigate = useNavigate()
  const { data: detailData, isLoading: detailLoading } = useVocaCatDetail(cat_id)
  const { data: quzList, isLoading: quzLoading } = useVocaQuzList(cat_id, "Y")
  const { trigger } = useVocaQuzStart()
  const { login } = useLoginStore()
  const { quizBase, setQuizBase } = useVocaQuizStore()

  useEffect(() => {
    setQuizBase(cat_id, quzList)
  }, [svc_id, cat_id, quzList])

  const onStartQuiz = useCallback(async () => {
    const firstQuiz = quizBase[cat_id][0]
    //[1] 해당 cat_id에 대한 퀴즈푼거 모두 지우기
    //[2] 해당 cat_id의 퀴즈 상태 ing
    console.log(login.user_id)
    console.log(firstQuiz.quz_id)
    const startData = {
      mem_id: login?.mem_id,
      cat_id: cat_id,
      cat_quz_end_yn: "N",
      cat_quz_cnt: quizBase[cat_id].length,
      reg_mem_id: login.user_id
    }
    try {
      const result = await trigger(startData)
      const mem_std_id = result.data.res_data
      if (mem_std_id >= 1) {
        if (firstQuiz.quz_typ === "W") {
          navigate(`/quiz/word-quiz?svc_id=${svc_id}&cat_id=${cat_id}&mem_std_id=${mem_std_id}&quz_arr=0`, {
            replate: true
          })
        } else if (firstQuiz.quz_typ === "S") {
          navigate(`/quiz/sent-quiz?svc_id=${svc_id}&cat_id=${cat_id}&mem_std_id=${mem_std_id}&quz_arr=0`, {
            replate: true
          })
        }
      } else {
        alert(result)
      }
    } catch (error) {
      alert(error.response.data.res_msg)
    }
  }, [quizBase])

  if (detailLoading || quzLoading) {
    return <NoHeaderLoading />
  }

  return (
    <>
      <section className="tf-section top-seller home5 s2 mobie-style bg-style2">
        <div className="themesflat-container">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{ marginTop: "14px" }}>
            <div className="form-inner" style={{ paddingBottom: "40px" }}>
              <button onClick={() => onStartQuiz()} className="submit" style={{ padding: "16px 18px", display: "block" }}>
                {detailData.cat_nm} 퀴즈를 시작합니다.
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StartQuiz

import useAxiosService from "src/common/useAxiosService"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useVocaCatList = (svc_id, use_yn) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/web/voca/catlist?svc_id=${svc_id}&use_yn=${use_yn}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

export const useVocaCatDetail = (cat_id) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(`/web/voca/catdetail?cat_id=${cat_id}`, fetcher)
  return { data: data?.res_data || {}, error, isLoading, isValidating, mutate }
}

export const useVocaMapList = (cat_id, mem_id, use_yn) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `/web/voca/maplist?cat_id=${cat_id}&mem_id=${mem_id}&use_yn=${use_yn}`,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, isValidating, mutate }
}

export const useSentMapList = (cat_id, mem_id, use_yn) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(
    `/web/sent/maplist?cat_id=${cat_id}&mem_id=${mem_id}&use_yn=${use_yn}`,
    fetcher
  )
  return { data: data?.res_data || [], error, isLoading, mutate }
}

export const useVocaInfo = (voca_id, mem_id, use_yn) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    `/web/voca/info?voca_id=${voca_id}&mem_id=${mem_id}&use_yn=${use_yn}`,
    fetcher
  )
  return { data: data?.res_data || {}, error, isLoading, isValidating, mutate }
}

export const useVocaBookmark = () => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/web/voca/bookmark`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useSentBookmark = () => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/web/sent/bookmark`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useVocaBookmarkList = (mem_id) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/web/voca/bookmark/list?mem_id=${mem_id}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

export const useSentBookmarkList = (mem_id) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/web/sent/bookmark/list?mem_id=${mem_id}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

export const useBookmarkCount = (mem_id) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(mem_id ? `/web/bookmarkcount?mem_id=${mem_id}` : null, fetcher)
  return { data: data?.res_data || {}, error, isLoading, mutate }
}

import useAxiosService from "src/common/useAxiosService"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

/*
export const useKaKaoLogin = (code) => {
  const fetcher = (url) => axiosSpring.post(url).then((data) => data)
  const { data, error, isLoading, mutate } = useSWR(code ? `/oauth/kakao?code=${code}` : null, fetcher)
  return { data: data?.res_data || null, error, isLoading, mutate }
}

export const useLogout = () => {
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg).then((res) => res.data)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/logout`, fetcher)
  return { trigger, isMutating, data, error, reset }
}
*/

export const useAcctRegist = () => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg).then((res) => res.data)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/login/acct/regist`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useEmailLoginLogin = () => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg).then((res) => res.data)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/login/email`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

import { useEffect } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { BarLoader } from "react-spinners"
import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"
import axiosSpring from "src/common/AxiosSpring"
import useLoginStore from "src/store/LoginStore"

const KakaoLogin = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code")
  const { login, setLogin } = useLoginStore()

  useEffect(() => {
    if (login) {
      navigate("/dashboard")
    }
    axiosSpring
      .post(`/login/oauth/kakao?code=${code}`)
      .then((res) => {
        console.log(res)
        if (res) {
          setLogin(res.data.res_data)
          navigate("/dashboard", {
            replace: true
          })
        } else {
          alert("로그인에 실패하였습니다.")
          navigate("/login", {
            replace: true
          })
        }
      })
      .catch((err) => {
        alert("로그인에 실패하였습니다.")
        navigate("/login", {
          replace: true
        })
      })
  }, [])
  return (
    <>
      <Header />
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div style={{ minHeight: "500px", margin: "200px 0 200px 0" }}>
                <h4 className="tf-title-heading ct style-2" style={{ marginBottom: "10px" }}>
                  로그인 중입니다.
                </h4>

                <div style={{ textAlign: "center", width: "200px", margin: "auto" }}>
                  <BarLoader color="#5142FC" width="200px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default KakaoLogin

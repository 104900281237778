import img2 from "../images/slider/slide_2.png"
import imgbg2 from "../images/slider/bg_slide_2.png"

const heroSliderData = [
  {
    title_1: "Build up yours.",
    title_2: "Make your English Note.",
    title_3: "영어는 배우는게 아닌 쌓아나가는거야!",
    title_4: "NFTs",
    description1: "나만의 단어와 문장노트를 만들어",
    description2: "영어실력을 단단하게 만들어 나가세요. ",
    img: img2,
    imgbg: imgbg2,
    class: "center"
  }
]

export default heroSliderData

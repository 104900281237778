import { Link } from "react-router-dom"
import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"

const PolicyTerms = () => {
  return (
    <div>
      <Header />
      <section className="tf-contact tf-section">
        <div className="themesflat-container" style={{ marginTop: "70px" }}>
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <h2 className="tf-title-heading ct style-2 mg-bt-40">이용약관</h2>

                <h3 style={{ marginBottom: "10px" }}>제 1조(목적)</h3>
                <h6 className="sub-title style-2">
                  본 약관은 주식회사 팀소프트(이하 ”회사”라 합니다)에서 제공하는 "AI튜터" 및 그와 관련한 부가 서비스(접속 가능한 유•무선 단말기의
                  종류와 관계없이 회사가 "AI튜터"를 통해 제공하는 이용 가능한 모든 서비스를 의미하며, 이하 “서비스”라 합니다)를 이용함에 있어
                  회사와 회원의 권리와 의무, 책임사항, 기타 필요한 사항을 규정함을 그 목적으로 합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제 2조(정의)</h3>
                <h6 className="sub-title style-2">
                  이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                  <br />
                  1. “사이트”란 회사가 재화 또는 서비스(이하 “상품 등”이라 합니다)를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 상품 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며 회사가 모바일 환경에서 서비스하는 모바일 웹과 앱을 포함합니다.
                  <br />
                  2. “회원”이라 함은 사이트에서 정한 소정의 절차를 거쳐 회원가입을 한 자로서, 약관에 따라 회사가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
                  <br />
                  3. “아이디(ID)”라 함은 회원의 식별과 서비스의 이용을 위하여 회원이 설정하고 회사가 승인하여 등록된 전자우편주소 또는 소셜 서비스 연동을 통해 수집된 전자우편주소를 말합니다.
                  <br />
                  4. “이용계약”이란 본 약관을 포함하여 서비스 이용과 관련하여 “회사”와 “회원”간에 체결하는 모든 계약을 말합니다.
                  <br />
                  5. “유료서비스”라 함은 “회사”가 유료로 제공하는 각종 온라인디지털콘텐츠(텍스트, 음성, 영상, 동영상 강의 열람, 프리미엄 교육 정보, 기타 유료 콘텐츠를 포합니다) 및 제반 서비스를 의미합니다.
                  <br />
                  6. “게시물”이라 함은 “회원”이 서비스를 이용함에 있어 서비스 상에 게시한 글, 사진, 동영상 및 각종 파일과 링크를 의미합니다.
                  <br />
                  7. 위 항에서 정의되지 않은 약관 상의 용어의 의미는 일반적인 거래관행에 따릅니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제3조 (약관 등의 명시와 설명 및 개정)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 본 약관의 내용과 상호, 영업소 소재지 주소, 대표자의 성명, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자 등을 "회원"이 쉽게 확인할 수 있도록 사이트의 초기 화면에 게시합니다. 다만, 본 약관의 구체적 내용은 회원이 연결화면을 통하여 볼 수 있도록 합니다.
                  <br />
                  2. "회사"는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제에 관한 법률』, 『전자문서 및 전자거래기본법』, 『전자금융거래법』, 『전자서명법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『소비자기본법』, 『개인정보보호법』, 『청소년보호법』 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
                  <br />
                  3. "회사"가 본 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 그 적용일자 7일 이전부터 적용일자 전일까지 "사이트"의 초기 화면 등에 고지하거나 전자우편 또는 그 밖의 방법으로 통지합니다. 단, "회원"에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지 및 통지합니다.
                  <br />
                  4. 변경된 약관은 법령에 특별한 규정이나 기타 부득이한 사유가 없는 한 그 적용일자 이전으로 소급하여 적용되지 않습니다. 단, 본 약관의 권리, 의무에 영향을 주지 않는 서비스에 대한 새로운 기능과 관련된 변경이나 법령의 개정 후 법령을 준수하기 위한 변경은 사전 공지 없이 즉시 발효될 수 있습니다.
                  <br />
                  5. "회원"은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우 언제든지 자유롭게 서비스 이용을 중단하고 탈퇴할 수 있습니다. 단, 효력발생일까지 탈퇴를 하지 않은 경우 개정된 약관에 동의한 것으로 간주합니다.
                  <br />
                  6. 회사는 제공하는 서비스 내의 개별 서비스에 대한 별도의 약관 및 이용조건(이하 “개별약관” 또는 “운영정책”이라고 합니다)을 둘 수 있으며 개별 서비스에서 별도로 적용되는 약관에 대한 동의는 "회원"이 개별 서비스를 최초로 이용할 경우 별도의 동의 절차를 거치게 됩니다. 이 경우 개별 서비스에 대한 이용약관 등이 이 약관에 우선합니다.
                  <br />
                  7. “회원”은 본 약관의 내용을 확인할 의무가 있으며, 개정된 약관에 동의한 “회원”이 약관의 변경으로 인하여 입은 피해 및 “회원”의 확인 소홀로 개정된 약관의 내용을 알지 못해 발생하는 피해에 대해서 “회사”는 책임지지 않습니다.
                  <br />
                  8. “회원”이 “회사”와 개별 계약을 체결하여 서비스를 이용할 경우, “회원”의 서비스 이용과 관련된 권리 의무는 순차적으로 개별 계약, 개별 서비스 이용약관, 본 약관에서 정한 내용에 따라 적용합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제4조(서비스의 제공 및 변경)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 다음과 같은 서비스를 제공합니다.
                  <br />
                  가. 전자상거래 서비스 및 부수 서비스
                  <br />
                  나. 온라인 동영상 강의 서비스
                  <br />
                  다. 동영상 외 텍스트 강의, 강의 관련 다양한 형태의 교육용 서비스
                  <br />
                  라. 학습 지원 서비스(토론, 퀴즈, 과제, 평가, 수료)
                  <br />
                  마. 기타 회사가 정하거나 추가 개발하는 서비스 또는 업무
                  <br />
                  2. "회사"는 상품 등의 품절 또는 기술적 사양의 변경 등의 사유로 장차 체결되는 계약에 의해 제공할 상품 등의 내용을 변경할 수 있습니다. 이 경우에는 변경된 상품 등의 내용 및 제공일자를 명시하여 현재의 상품 등의 내용을 게시한 곳에 즉시 공지합니다.
                  <br />
                  3. "회사"가 제공하기로 "회원"과 계약을 체결한 서비스의 내용을 상품 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 "회원"에게 즉시 통지합니다.
                  <br />
                  4. 전항의 경우 회사는 이로 인하여 "회원"이 입은 인과관계가 입증된 실제 손해를 배상합니다. 다만, 회사가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 않습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제5조(서비스의 중단)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 컴퓨터 등 정보통신설비의 보수 점검 및 교체, 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
                  <br />
                  2. "회사"가 서비스 제공을 일시적으로 중단할 경우 서비스 일시 중단 사실과 그 사유를 사이트 초기화면에 고지합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제6조(회원가입과 이용계약의 성립)</h3>
                <h6 className="sub-title style-2">
                  1. 이용계약은 "회원"이 되고자 하는 자(이하 “가입신청자”라 합니다)가 "회사"가 정한 가입 양식에 따라 회원정보를 기입하고 본 약관에 동의한 후 회원가입을 신청하면 "회사"가 가입을 승인함으로써 체결됩니다.
                  <br />
                  2. "회사"는 "회원"으로 가입할 것을 신청한 "가입신청자" 중 다음에 해당하지 않는 한 "회원"으로 가입을 승인합니다. 단, "회사"가 회원가입 신청의 승인을 거부하거나 유보하는 경우 이를 "가입신청자"에게 알립니다.
                  <br />
                  3. 카카오톡, 깃허브 등 외부 서비스와의 연동을 통한 회원 가입 시 “가입신청자”는 본 약관, 개인정보 처리방침 및 서비스 제공을 위한 “회사”의 외부 서비스 계정 정보 접근 및 활용에 대한 동의 표시를 하고, 회원 가입 회면에서 “회사”가 요청하는 사항(예컨대, 이메일주소 등을 포함합니다)을 기입하고 및 회원 가입 신청을 하여야 하며, “회사”가 회원가입 신청을 승인함으로써 이용계약이 성립합니다.
                  <br />
                  4. “회사”는 “가입신청자”가 본 약관 및 개인정보 처리방침의 내용을 읽고 동의한 것으로 봅니다.
                  <br />
                  5. 가입신청의 승인은 “회사”가 본 약관에 동의한 “가입신청자”에게 승인의 의사가 담긴 이메일 내에서 통지함으로 이루어지고, 이러한 승인의 통지가 “가입신청자”에게 도달하면 이용계약이 성립합니다.
                  <br />
                  6. “회사”는 “가입신청자”에 대하여 업무수행 및 서비스 제공을 위한 설비의 여유, 기술상 지장이 없는 경우 원칙적으로 접수 순서에 따라 서비스 이용 신청을 승인합니다.
                  <br />
                  7. “회사”는 아래 사항에 해당하는 경우에 대해서 이용 신청을 승인하지 않거나 사후에 이용계약을 해지할 수 있습니다.
                  <br />
                  가. "가입신청자"가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
                  <br />
                  나. 등록내용에 허위, 기재 누락, 오기가 있는 경우
                  <br />
                  다. 회원가입 신청 시 타인의 명의를 도용한 경우
                  <br />
                  라. "가입신청자"의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
                  <br />
                  마. 회원가입일 현재 만 14세 미만인 경우 (단, 법정대리인의 동의를 얻은 후 본인의 개인정보를 제공한 경우는 제외)
                  <br />
                  바. 서비스를 제공하지 않는 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우
                  <br />
                  사. 서비스의 위상이나 명예에 부정적인 영향을 줄 수 있는 경우
                  <br />
                  아. 부정한 행위나 과정을 통해 공공질서 및 미풍양속에 저해되는 활동을 하는 경우
                  <br />
                  자. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
                  <br />
                  차. 영리를 추구할 목적으로 서비스를 악용하는 경우
                  <br />
                  카. 서비스의 운영을 고의로 방해한 경우
                  <br />
                  타. 기타 이에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우
                  <br />
                  8. 이용계약의 성립 시기는 "회사"가 가입 완료를 신청절차 상에서 표시한 시점으로 합니다.
                  <br />
                  9. "회사"는 "회원"에 대하여 『학원법』, 『평생교육법』, 『청소년 보호법』 등에 따라 이용제한이나 등급별 제한을 둘 수 있습니다.
                  <br />
                  10. "회사"는 "회원"에 대해 회사 정책에 따라 등급을 정하고 이에 따라 이용 서비스에 차등을 둘 수 있습니다.
                  <br />
                  11. "회원"은 회원가입 시 등록한 사항에 변경이 있는 경우 상당한 기간 이내에 회사에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제7조 (회원의 의무)</h3>
                <h6 className="sub-title style-2">
                  1. "회원"은 관련 법령, 본 약관의 규정, 운영정책 등 "회사"가 통지하는 사항을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안됩니다.
                  <br />
                  2. ”회원”은 서비스를 이용하며 얻은 정보를 “회사”의 사전 동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.
                  <br />
                  3. ”회원”은 서비스를 본 약관에서 정한 바와 다른 목적으로 사용해서는 아니되며, 이하 각 호의 서비스 부정이용행위를 하지 않아야 합니다. “회사”는 아래 사항에 해당하는 경우에 대해서 이용계약 해지 등의 조치를 취할 수 있습니다.
                  <br />
                  가. 회원가입 신청 또는 변경 시 허위 내용의 등록
                  <br />
                  나. 타인의 정보도용
                  <br />
                  다. 타인의 지적재산권 등의 권리 침해
                  <br />
                  라. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
                  <br />
                  마. 해킹 행위 또는 바이러스 유포 및 비정상적인 방법으로 서비스를 이용하거나 회사의 시스템에 접근하는 행위
                  <br />
                  바. 타인의 명예를 손상시키거나 모욕하는 행위
                  <br />
                  사. 외설 또는 폭력적인 메시지∙화상∙음성 기타 공서양속에 반하는 정보를 사이트에 공개 또는 게시하는 행위
                  <br />
                  아. 사이트의 정보 및 서비스를 이용한 영리 행위
                  <br />
                  자. 기타 미풍양속 및 사회질서를 해하거나 관계법령에 위반하는 행위
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제8조 (회원의 아이디에 대한 의무)</h3>
                <h6 className="sub-title style-2">
                  1. 아이디에 관한 관리책임은 "회원"에게 있으며, 이를 소홀히 하여 발생한 모든 민∙형사상의 책임은 "회원"에게 있습니다.
                  <br />
                  2. "회원"은 자신의 아이디를 제3자에게 이용하게 해서는 안됩니다.
                  <br />
                  3. "회원"이 자신의 아이디를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 "회사"에 통보해야 하며, 별도 "회사"의 안내가 있는 경우에는 그에 따라야 합니다.
                  <br />
                  4. "회원"이 아이디 도난을 인지한 후에도 "회사"의 조치에 응하지 아니하여 발생하는 모든 불이익에 대한 책임은 "회원"에게 있습니다.
                  <br />
                  5. “회사“는 다음 각 호 어느 하나에 해당하는 경우를 부정 이용행위로 간주하여, 이용계약 해지 등의 조치를 취할 수 있습니다.
                  <br />
                  가. 동일한 아이디로 1대 이상의 기기 또는 IP에서 동시접속이 발생하는 경우
                  <br />
                  나. 자신의 아이디 및 강의 등의 서비스를 타인이 이용하도록 제공하는 경우
                  <br />
                  다. 자신의 아이디 및 강의 등의 서비스를 타인에게 판매, 대여, 양도하는 행위 및 이를 광고하는 행위
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제9조 (회원 탈퇴 및 자격 상실 등)</h3>
                <h6 className="sub-title style-2">
                  1. "회원"은 "회사"에 언제든지 탈퇴를 요청할 수 있으며, 회사는 회원 탈퇴에 관한 규정에 따라 이를 처리합니다.
                  <br />
                  2. "회원"이 다음 각 호의 사유에 해당하는 경우, "회사"는 회원자격 제한 및 정지 등의 조치를 취할 수 있습니다.
                  <br />
                  가. 가입신청 시에 허위 내용을 등록하거나 타인의 정보를 도용한 경우
                  <br />
                  나. "회사"가 제공하는 서비스의 원활한 운영을 방해하는 행위를 하거나 시도한 경우
                  <br />
                  다. 사이트를 통해 상품 등을 구매한 후 정당한 이유 없이 상습 또는 반복적으로 취소 또는 환불하여 "회사"의 업무를 방해하는 경우
                  <br />
                  라. 타인의 사이트 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
                  <br />
                  마. 기타 "회사"가 합리적인 판단에 의하여 필요하다고 인정하는 경우
                  <br />
                  3. "회사"의 조치 후 동일한 행위가 2회 이상 반복되거나 5일 이내에 그 사유가 시정되지 않은 경우, "회사"는 회원자격을 상실시킬 수 있습니다.
                  <br />
                  4. "회사"가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 "회원"에게 이를 통지하고, 회원등록 말소 전에 최소한 5일 이상의 기간을 정하여 소명할 기회를 부여합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제10조 (회사의 의무)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는데 최선을 다하여야 합니다.
                  <br />
                  2. "회사"는 "회원"이 안전하게 서비스를 이용할 수 있도록 "회원"의 개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어야 하며, 개인정보취급방침을 공시하고 이를 준수합니다.
                  <br />
                  3. "회사"는 "회원"이 원하지 않는 영리 목적의 광고성 전자우편 및 문자 등을 발송하지 않습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제11조 (부정이용)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 "회원"이 본 약관 제8조의 각 호에 해당하는 부정 이용자가 발견되었을 경우, “회사”는 다음 각 호의 방법을 통해 부정이용을 식별하고 이를 차단합니다. “회원”은 부정이용을 차단하기 위한 “회사”의 강제 탈퇴와 혹은 이용 정지 등의 조치에 대하여 일체의 이의를 제기할 수 없습니다.
                  <br />
                  가. “회사“는 “회원“의 서비스 이용 중에 수집ㆍ확인된 IP정보 등의 자료를 토대로, 서버를 통하여 부정이용 여부를 분류, 확인합니다.
                  <br />
                  나. “회사“는 “회원“이 서비스 이용 중에 복제프로그램을 실행시키거나 동일한 ID로 동시 접속을 하는 경우, 서비스 이용 접속을 강제로 종료 시킵니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제12조 (개인정보보호)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 "회원"의 개인정보를 보호하기 위하여 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등 관계법령에서 정하는 바를 준수합니다.
                  <br />
                  2. "회사"는 "회원"의 개인정보를 보호하기 위하여 개인정보취급방침을 제정, 서비스 초기 화면에 게시합니다. 다만, 개인정보취급방침의 구체적 내용은 연결화면을 통하여 볼 수 있도록 합니다.
                  <br />
                  3. "회사"는 "회원"의 개인정보를 수집 및 이용하는 때에는 "회원"에게 그 목적을 고지하고 동의를 받으며 개인정보취급방침에 따라 "회원"의 개인정보를 보호하기 위하여 노력합니다.
                  <br />
                  4. "회사"는 서비스의 제공이 일시적으로 중단됨으로 인하여 "회원"이 입은 손해에 대하여 배상합니다. 단, "회사"가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 않습니다.
                  <br />
                  5. "회사"는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용 및 제공단계에서 해당 "회원"에게 그 목적을 고지하고 동의를 받습니다. 단, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
                  <br />
                  6. "회사"가 "회원"의 동의를 받아야 하는 경우에는 개인정보관리책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집 목적 및 이용목적, 제3자에 대한 정보제공 관련 사항(제공받은 자, 제공 목적 및 제공할 정보의 내용) 등 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』에서 규정한 사항을 미리 명시하거나 고지해야 하며 "회원"은 언제든지 이 동의를 철회할 수 있습니다.
                  <br />
                  7. "회원"은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 "회사"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다.
                  <br />
                  8. "회사"의 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보취급방침이 적용되지 않습니다. 링크된 사이트 및 상품 등을 제공하는 제3자의 개인정보 취급과 관련하여서는 해당 사이트 및 제3자의 개인정보취급방침을 확인할 책임이 "회원"에게 있으며, "회사"는 이에 대하여 책임을 부담하지 않습니다.
                  <br />
                  9. "회사"는 다음과 같은 경우에 법이 허용하는 범위 내에서 회원의 개인정보를 제3자에게 제공할 수 있습니다.
                  <br />
                  가. 수사기관이나 기타 정부기관으로부터 정보제공을 요청받은 경우
                  <br />
                  나. "회원"이 법령 또는 약관의 위반을 포함하여 부정행위 확인 등의 정보보호업무를 위해 필요한 경우.
                  <br />
                  다. 배송업체 등에게 거래 및 배송 등에 필요한 최소한의 "회원"의 정보(성명, 주소, 전화번호 등)를 제공하는 경우
                  <br />
                  라. 구매가 성사된 때에 그 이행을 위하여 필요한 경우와 구매가 종료된 이후에도 취소, 반품, 교환, 환불 등을 위하여 필요한 경우
                  <br />
                  마. 기타 법령을 근거로 정보의 공개를 요구하거나 법령에 의하여 정보의 제공이 가능한 경우
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제13조 (회원에 대한 통지)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"가 "회원"에게 통지를 하는 경우, "회원"의 알림 설정 여부에 따라 회원가입 시 입력한 이메일 혹은 사이트 내 푸쉬 알림 등으로 할 수 있습니다. 단, 서비스 이용에 따른 필수적인 내용 또는 관련법에 따른 거래 관련 정보 및 고객 문의에 대한 답변 등은 알림 설정 여부와 관계없이 통지합니다.
                  <br />
                  2. "회사"는 불특정 다수 회원에 대한 통지의 경우 1주일 이상 사이트의 공지사항 등에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, "회원" 본인의 거래에 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제14조 (정보의 제공 및 광고의 게재)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 "회원"이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 이메일 혹은 사이트 내 푸쉬 알림 등의 방법으로 회원에게 제공할 수 있습니다.
                  <br />
                  2. "회사"는 "회원"에게 각종 상품∙서비스 소개, 홍보 등의 영리적 목적을 위한 정보를 이메일이나 사이트 푸쉬 알림 등으로 제공할 수 있습니다.
                  <br />
                  3. "회사"는 서비스와 관련되는 광고를 서비스 혹은 이메일 등에 게재할 수 있으며, 광고가 게재된 이메일을 수신한 "회원"은 수신거절을 "회사"에 할 수 있습니다.
                  <br />
                  4. "회원"은 서비스 이용 시 사이트 등에 노출되는 광고 게재에 대해 동의하는 것으로 간주합니다.
                  <br />
                  5. "회원"은 서비스 이용에 따른 필수적인 내용 또는 관련법에 따른 거래 관련 정보 및 고객 문의에 대한 답변 등을 제외하고는 언제든지 수신을 거절할 수 있습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제15조 (구매신청)</h3>
                <h6 className="sub-title style-2">
                  1. "회원"은 본 약관 및 "회사"가 정한 규정에 따라 구매를 신청하여야 하며, "회사"는 "회원"이 구매신청을 함에 있어서 다음의 내용을 알기 쉽게 제공하여야 합니다.
                  <br />
                  가. 상품 등의 검색 및 선택
                  <br />
                  나. 약관 내용, 청약철회권이 제한되는 서비스, 비용 부담과 관련한 내용에 대한 확인
                  <br />
                  다. 상품 등의 구매신청 및 이에 관한 확인
                  <br />
                  마. 기타 결제 시 유의사항에 대한 확인
                  <br />
                  라. 결제방법의 선택
                  <br />
                  2. "회사"가 제3자에게 "회원"의 개인정보를 제공 및 위탁할 필요가 있는 경우 실제 구매신청 시 "회원"의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이때 "회사"는 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용목적 및 보유, 이용기간 등을 "회원"에게 명시하여야 합니다. 단, 『정보통신망이용촉진 및 정보보호 등에 관한 법률』 제25조 제1항에 의한 개인정보 취급위탁의 경우 동 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제16조 (대금지급방법)</h3>
                <h6 className="sub-title style-2">
                  1. 사이트에서 구매한 상품 등에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
                  <br />
                  가. 직불카드, 신용카드 등의 각종 카드 결제
                  <br />
                  나. 실시간 계좌이체. 무통장입금
                  <br />
                  다. 기타 전자적 지급 방법에 의한 대금 지급 등
                  <br />
                  2. "회원"이 구매대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제17조 (할인쿠폰)</h3>
                <h6 className="sub-title style-2">
                  1. 할인쿠폰은 "회사"에서 발행하는 쿠폰으로, 발행 대상, 발행 경로, 사용대상 등에 따라 구분될 수 있으며, 할인쿠폰의 세부 구분, 할인금액(할인율), 사용방법, 사용기한 및 제한에 대한 사항은 할인쿠폰 또는 서비스 화면에 표시합니다. 할인쿠폰의 종류 및 내용과 발급 여부에 관하여는 "회사"의 정책에 따라 달라질 수 있습니다.
                  <br />
                  2. 할인쿠폰은 현금으로 출금될 수 없으며, 할인쿠폰에 표시된 유효기간이 만료되거나 이용계약이 종료되면 소멸합니다.
                  <br />
                  3. 할인쿠폰은 "회사"에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 부정한 목적이나 용도로 사용할 수 없습니다. 이를 위반한 경우 "회사"는 할인쿠폰을 소멸시키거나 회원자격을 정지할 수 있습니다.
                  <br />
                  4. "회원"이 부정한 방법으로 할인쿠폰을 획득한 사실이 확인될 경우 "회사"는 "회원"의 할인쿠폰 회수, 회원 탈퇴 및 형사고발 등의 조치를 취할 수 있습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제18조 (계약의 성립)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 제15조 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.
                  <br />
                  가. 신청내용에 허위, 기재누락, 오기가 있는 경우
                  <br />
                  나. 기타 구매신청에 승낙하는 것이 "회사"의 기술상 현저히 지장이 있다고 판단하는 경우
                  <br />
                  다. 구매신청 "회원"이 회원 자격이 제한, 정지 또는 상실된 "회원"으로 확인되었을 경우
                  <br />
                  라. 구매신청 "회원"이 재판매의 목적으로 상품 등을 중복 구매하는 등 사이트의 거래질서를 방해한 경우
                  <br />
                  마. 본 약관에 위배되는 내용을 “회사”에 요구한 이력이 있는 “회원”일 경우
                  <br />
                  2. "회사"의 승낙이 수신확인통지형태로 "회원"에게 도달한 시점에 계약이 성립한 것으로 봅니다.
                  <br />
                  3. "회사"의 승낙의 의사표시에는 "회원"의 구매신청에 대한 확인 및 판매 가능 여부 등에 관한 정보 등을 포함하여야 합니다.
                  <br />
                  4. 계약이 성립한 후 "회사"가 제6조 각 호의 사유를 발견한 경우 "회사"는 즉시 계약을 취소할 수 있으며, 계약 취소 시 "회원"이 결제한 상품 대금은 즉시 환불 처리됩니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제19조 (수신확인통지•구매신청 변경 및 취소)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 "회원"의 구매신청이 있는 경우 "회원"에게 수신확인통지를 합니다.
                  <br />
                  2. 수신확인통지를 받은 "회원"은 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 또는 취소를 요청할 수 있고, "회사"는 상품 등의 가치를 소비하기 전, "회원"의 요청이 있는 경우에 지체 없이 그 요청에 따라 처리하여야 합니다. 단, 이미 상품 등의 가치를 소비하거나 제22조에서 명시한 철회 가능 기간이 지난 경우에는 제22조의 청약철회 등에 관한 규정을 따릅니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제20조 (상품 등의 공급)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 "회원"에게 상품 등의 공급시기에 관하여 상품별로 고지하고 공급시기 내에 상품 등을 제공할 수 있도록 필요한 조치를 취합니다.
                  <br />
                  2. "회사"는 "회원"이 상품 등의 공급절차 및 진행사항을 확인할 수 있도록 적절한 조치를 하여야 하며, 상품 등의 취소 및 환불 방법 및 절차를 안내하여야 합니다.
                  <br />
                  3. "회사"와 "회원"간에 상품의 공급 및 인도시기 등에 관하여 별도의 약정이 있는 경우에는 본 약관에 우선합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제21조 (환급)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"는 "회원"이 구매 신청한 상품 등이 품절, 천재지변 등의 불가항력적인 사유 등으로 제공할 수 없을 때에는 지체 없이 그 사유를 "회원"에게 통지하고, 사전에 상품 등의 대금을 받은 경우에는 사유가 발생한 날로부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
                  <br />
                  2. 환불은 “회사”가 환불을 승인한 날로부터 3영업일 이내에 대금의 결제와 동일한 방법(신용카드 결제취소, 계좌이체 등)으로 이루어집니다. 단, 본 약관의 환불 규정에 따라 환급해야 할 이용요금에서 체납된 이용요금이 있거나, 상품을 일부 소비하였을 경우 우선 공제하고 남은 금액을 반환합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제22조 (청약철회 등)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"의 상품 등의 구매 취소 및 환불 규정은 『전자상거래 등에서의 소비자보호에 관한 법률』, 『학원법』 및 동법 시행령 등 관련 법령을 준수합니다.
                  <br />
                  2. "회사"와 상품 등의 구매에 관한 계약을 체결한 "회원"은 『전자상거래 등에서의 소비자보호에 관한 법률』 제17조 제1항에 따른 계약내용에 관한 서면(수신확인통지)을 받은 날로(그 서면을 받은 때보다 상품 등의 공급이 늦게 이루어진 경우에는 상품 등을 공급받거나 상품 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약을 철회할 수 있습니다. 단, 청약철회에 관하여 『학원법』 및 동법 시행령에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
                  <br />
                  3. "회사"는 강사의 사정 등으로 인하여 상품 등의 공급이 어려운 경우에 그 사유를 "회원"에게 고지하고 사유가 발생한 날로부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
                  <br />
                  4. "회원"은 상품 등을 제공받은 경우, 다음 각 호에 해당하는 경우에는 청약철회 등을 할 수 없습니다.
                  <br />
                  가. "회원"에게 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우.
                  <br />
                  나. "회원"의 사용 또는 일부 소비로 상품 등의 가치가 현저히 감소하거나 그 가치를 모두 소비한 경우
                  <br />
                  다. "회사"가 상품 등의 청약철회 등의 제한에 관해 사전에 고지한 경우
                  <br />
                  라. 구매일로부터 7일이 경과하였거나, 또는 5강 이상 수강하였을 경우(무료 제공 부분 포함)
                  <br />
                  마. “회원”이 관계법령 및 본 약관의 규정을 위반하여 회사로부터 강제탈퇴 처리가 되는 경우
                  <br />
                  바. 그밖에 거래의 안전을 위하여 법령으로 정한 경우
                  <br />
                  5. 스터디 등 이벤트성 프로그램의 경우, 결제 시 고지한 바에 따라 별도의 수강 취소, 변경 및 환불규정이 적용될 수 있습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제23조 (저작권의 귀속 및 이용제한)</h3>
                <h6 className="sub-title style-2">
                  1. "회사"가 작성한 저작물에 대한 저작권 및 기타 지식재산권은 "회사"에 귀속합니다.
                  <br />
                  2. "회사"는 서비스와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라 계정, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 서비스를 이용함으로써 얻은 정보 중 "회사"에게 지식재산권이 귀속된 정보를 "회사"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
                  <br />
                  3. "회원"이 사이트 등에 제공하거나 게시한 게시물에 대한 저작권은 "회원"에게 귀속됩니다. "회원"은 자신이 제공하거나 게시한 게시물에 대해 "회사"의 서비스 운영, 개선, 홍보를 위한 범위 내에서 무상의 비독점적 사용권을 "회사"에 부여합니다. "회원"이 "회사"에게 부여한 사용권은 "회사"가 사이트 등을 운영하는 동안 계속 유효하며, "회원"이 서비스 이용계약을 해지한 후에도 같습니다.
                  <br />
                  4. "회원"은 게시물에 대해 "회사"에 사용권을 부여하기 위해 필요한 권리를 보유해야 합니다. 이러한 권리를 보유하지 않아 발생하는 모든 문제에 대해서는 "회원"이 책임을 부담하게 됩니다.
                  <br />
                  5. "회원"이 사이트 등에 제공하거나 게시하는 게시물은 검색 엔진 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제24조 (회원의 게시물)</h3>
                <h6 className="sub-title style-2">
                  1. "회원"이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 "회원"에게 있습니다. "회사"는 "회원"이 게시하거나 등록하는 서비스의 내용물이 다음 각 항에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있고, 이에 대하여 "회사"는 어떠한 책임도 지지 않습니다.
                  <br />
                  가. 다른 "회원" 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
                  <br />
                  나. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
                  <br />
                  다. 범죄적 행위에 결부된다고 인정되는 경우
                  <br />
                  라. "회사"의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
                  <br />
                  마. 불법복제 또는 해킹을 조장하는 내용인 경우
                  <br />
                  바. "회사"로부터 사전 승인받지 아니한 영리적 목적의 상업광고, 판촉 내용을 게시하는 경우
                  <br />
                  사. "회사"의 자체적 판단 아래 해당 게시물이 서비스의 성격에 부합하지 않는 경우
                  <br />
                  아. 정당한 사유 없이 "회사"의 영업을 방해하는 내용을 기재하는 경우
                  <br />
                  자. 기타 관련 법령에 위반된다고 판단되는 경우
                  <br />
                  2. “회원“은 게시물 내용에 사회적 통념에 어긋나는 문구와 내용 또는 기타 법령에 위반되거나 제3자의 명예, 인격권, 저작권 기타 권리를 침해하거나 그에 준하는 내용이 포함되지 아니하도록 해야 하며, 작성된 게시물에 이러한 내용이 포함되어 발생하는 모든 문제에 대한 책임은 “회원“이 부담합니다.
                  <br />
                  3. 회원 탈퇴 이후 "회원"이 작성하였던 게시물 및 댓글 등은 삭제되지 않으며, "회원"이 작성한 게시물의 삭제를 원할 경우에는 회원 탈퇴 이전에 게시물을 모두 삭제하여야 합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제25조 (회사의 면책)</h3>
                <h6 className="sub-title style-2">
                  1. 회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 상품 등을 제공할 수 없는 경우에는 상품 등의 제공에 관한 책임이 면제됩니다.
                  <br />
                  2. "회사" 또는 강사의 사정으로 인하여 상품 등을 공급하지 못할 수 있으며, 이 경우 "회사"는 "회원"에게 상품 공급이 어렵다는 점을 고지한 후 환불을 진행합니다.
                  <br />
                  3. "회사"는 "회원"의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
                  <br />
                  4. "회사"는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
                  <br />
                  5. "회사"는 "회원"이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않습니다.
                  <br />
                  6. "회사"는 "회원" 간 또는 "회원"과 제3자 상호 간에 "회사"의 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
                  <br />
                  7. "회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>제26조 (재판권 및 준거법)</h3>
                <h6 className="sub-title style-2">
                  1. "회원" 간 또는 "회원"과 제3자 간 발생한 분쟁에 대하여 “회사“는 합리적인 범위 내에서 이를 조정할 수 있습니다.
                  <br />
                  2. 본 약관의 해석 및 "회사"와 "회원" 간의 분쟁에 대하여는 대한민국의 법률을 적용합니다.
                  <br />
                  3. 본 약관 및 서비스 이용과 관련하여 "회사"와 "회원" 사이에 분쟁이 발생하여 소송이 제기되는 경우에는 "회사"의 본사 소재지를 관할하는 법원을 관할법원으로 정합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>부칙</h3>
                <h6 className="sub-title style-2">
                  1. 공고일자 : 2024년 6월 30일
                  <br />
                  2. 시행일자 : 2024년 7월 1일
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default PolicyTerms

import React, { useCallback, useRef, useState, useMemo, useEffect } from "react"

import { TypeAnimation } from "react-type-animation"

const Test = () => {
  const [words, setWords] = useState({
    characters: [
      "B",
      "o",
      "r",
      "n",
      " ",
      "a",
      "n",
      "d",
      " ",
      "r",
      "a",
      "i",
      "s",
      "e",
      "d",
      " ",
      "i",
      "n",
      " ",
      "t",
      "h",
      "e",
      " ",
      "c",
      "h",
      "a",
      "r",
      "m",
      "i",
      "n",
      "g",
      " ",
      "s",
      "o",
      "u",
      "t",
      "h",
      ",",
      " ",
      "I",
      " ",
      "c",
      "a",
      "n",
      " ",
      "a",
      "d",
      "d",
      " ",
      "a",
      " ",
      "t",
      "o",
      "u",
      "c",
      "h",
      " ",
      "o",
      "f",
      " ",
      "s",
      "w",
      "e",
      "e",
      "t",
      " ",
      "s",
      "o",
      "u",
      "t",
      "h",
      "e",
      "r",
      "n",
      " ",
      "h",
      "o",
      "s",
      "p",
      "i",
      "t",
      "a",
      "l",
      "i",
      "t",
      "y",
      " ",
      "t",
      "o",
      " ",
      "y",
      "o",
      "u",
      "r",
      " ",
      "a",
      "u",
      "d",
      "i",
      "o",
      "b",
      "o",
      "o",
      "k",
      "s",
      " ",
      "a",
      "n",
      "d",
      " ",
      "p",
      "o",
      "d",
      "c",
      "a",
      "s",
      "t",
      "s"
    ],
    character_start_times_seconds: [
      0.0, 0.174, 0.232, 0.29, 0.337, 0.383, 0.406, 0.43, 0.453, 0.511, 0.557, 0.604, 0.65, 0.685, 0.743, 0.766, 0.801, 0.836,
      0.859, 0.906, 0.929, 0.952, 0.975, 1.01, 1.045, 1.091, 1.138, 1.196, 1.242, 1.289, 1.324, 1.347, 1.416, 1.463, 1.579, 1.637,
      1.695, 1.776, 1.869, 2.02, 2.067, 2.148, 2.183, 2.229, 2.264, 2.299, 2.345, 2.392, 2.426, 2.461, 2.496, 2.543, 2.577, 2.635,
      2.682, 2.728, 2.763, 2.81, 2.833, 2.868, 2.937, 2.995, 3.042, 3.1, 3.158, 3.204, 3.286, 3.332, 3.39, 3.425, 3.46, 3.483,
      3.506, 3.553, 3.599, 3.646, 3.68, 3.727, 3.796, 3.866, 3.924, 4.005, 4.098, 4.156, 4.214, 4.272, 4.319, 4.389, 4.423, 4.458,
      4.528, 4.551, 4.574, 4.609, 4.644, 4.702, 4.737, 4.795, 4.853, 4.923, 5.004, 5.062, 5.108, 5.143, 5.201, 5.259, 5.294,
      5.329, 5.352, 5.375, 5.433, 5.48, 5.596, 5.677, 5.735, 5.886, 5.956, 6.072
    ],
    character_end_times_seconds: [
      0.174, 0.232, 0.29, 0.337, 0.383, 0.406, 0.43, 0.453, 0.511, 0.557, 0.604, 0.65, 0.685, 0.743, 0.766, 0.801, 0.836, 0.859,
      0.906, 0.929, 0.952, 0.975, 1.01, 1.045, 1.091, 1.138, 1.196, 1.242, 1.289, 1.324, 1.347, 1.416, 1.463, 1.579, 1.637, 1.695,
      1.776, 1.869, 2.02, 2.067, 2.148, 2.183, 2.229, 2.264, 2.299, 2.345, 2.392, 2.426, 2.461, 2.496, 2.543, 2.577, 2.635, 2.682,
      2.728, 2.763, 2.81, 2.833, 2.868, 2.937, 2.995, 3.042, 3.1, 3.158, 3.204, 3.286, 3.332, 3.39, 3.425, 3.46, 3.483, 3.506,
      3.553, 3.599, 3.646, 3.68, 3.727, 3.796, 3.866, 3.924, 4.005, 4.098, 4.156, 4.214, 4.272, 4.319, 4.389, 4.423, 4.458, 4.528,
      4.551, 4.574, 4.609, 4.644, 4.702, 4.737, 4.795, 4.853, 4.923, 5.004, 5.062, 5.108, 5.143, 5.201, 5.259, 5.294, 5.329,
      5.352, 5.375, 5.433, 5.48, 5.596, 5.677, 5.735, 5.886, 5.956, 6.072, 6.409
    ]
  })
  const [sentense, setSentens] = useState("")
  const [time, setTime] = useState(0)
  const [data, setData] = useState({
    sentens:
      "Born and raised in the charming south, I can add a touch of sweet southern hospitality to your audiobooks and podcasts",
    time: 34300
  })

  useEffect(() => {
    let str = ""
    words.characters.map((item, index) => {
      str += item
    })
    setSentens(str)

    let time = 0
    words.character_start_times_seconds.map((item, index) => {
      time += item
    })
    setTime(Math.round(time) * 100)

    console.log(str)
    console.log(Math.round(time) * 100)
  }, [])

  return (
    <>
      <section
        className="tf-section top-seller home5 s2 mobie-style bg-style2"
        style={{ padding: "0px", paddingBottom: "0px !important" }}
      >
        <div className="themesflat-container" style={{ padding: "0" }}>
          <div className="form-inner" style={{ padding: "4px 0px", fontSize: "1.2em" }}>
            <div>Test</div>
            {/*<TypeAnimation sequence={["One", 500, "Two", 500, "Three", 500]} style={{ fontSize: "2em" }} repeat={Infinity} />*/}

            <TypeAnimation sequence={[data.sentens, data.time]} style={{ fontSize: "2em" }} repeat={Infinity} />
          </div>
        </div>
      </section>
    </>
  )
}

export default Test

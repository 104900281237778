const menus = [
  {
    id: 1,
    name: "Home",
    links: ["/", "/dashboard"]
  },
  {
    id: 2,
    name: "Loadmap",
    links: ["#"]
  },
  {
    id: 3,
    name: "Courses",
    links: ["#"]
  },
  {
    id: 4,
    name: "FAQ",
    links: ["#"]
  }
]

export default menus

import React, { useEffect, useCallback, useState } from "react"
import { SyncLoader } from "react-spinners"
import { Link, useNavigate } from "react-router-dom"
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"
import { useEmailLoginLogin } from "src/api/LoginApi"
import useLoginStore from "src/store/LoginStore"

const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/

function emailValidChk(email) {
  if (pattern.test(email) === false) {
    return false
  } else {
    return true
  }
}

const Login = () => {
  const DOMAIN = process.env.REACT_APP_DOMAIN

  const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID
  const KAKAO_REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI

  const navigate = useNavigate()
  const { login, setLogin } = useLoginStore()
  const [kakaoLoading, setKakaoLoading] = useState(false)
  const [googleLoading, setGoogleLoading] = useState(false)
  const [mailDomain, setMailDomain] = useState("")
  const [memAcct, setMemAcct] = useState("")
  const [msg, setMsg] = useState("")

  const { trigger: emailLoginTrigger, isMutating: emailLoginMutating } = useEmailLoginLogin()

  const kakaoLogin = useCallback(() => {
    setKakaoLoading(true)
    window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_CLIENT_ID}&redirect_uri=${DOMAIN}${KAKAO_REDIRECT_URI}&scope=account_email profile_nickname profile_image talk_message`
  }, [kakaoLoading])

  const googleLogin = useCallback(() => {
    setGoogleLoading(true)
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${DOMAIN}${GOOGLE_REDIRECT_URI}&response_type=code&scope=email profile`
  }, [googleLoading])

  const platformLogin = useCallback(
    async (e) => {
      e.preventDefault()
      console.log("platformLogin")
      if (!memAcct) {
        setMsg("이메일을 입력해주세요")
        return
      }
      if (!emailValidChk(memAcct)) {
        setMsg("이메일 형태의 값이 아닙니다.")
        return
      }
      setMsg("")

      try {
        const result = await emailLoginTrigger({ mem_acct: memAcct })
        if (result.res_data === "notExists") {
          setMsg("회원가입이 없는 이메일 입니다.")
        } else if (result.res_data === "ok") {
          setMsg(`${memAcct} 메일을 확인하세요.`)
          setMailDomain(memAcct.split("@")[1])
        }
        return
      } catch (e) {
        console.log(e)
        alert("로그인 에러입니다.")
      }
    },
    [memAcct]
  )

  const domainClick = useCallback(
    (mailDomain, e) => {
      e.preventDefault()
      console.log(mailDomain)
      window.location.href = "https://" + mailDomain
    },
    [mailDomain]
  )

  useEffect(() => {
    if (login) {
      navigate("/dashboard", {
        replace: true
      })
    }
  }, [])

  return (
    <div className="authors-2">
      <Header />
      <section className="tf-login tf-section">
        <div
          className="themesflat-container col-xl-8 col-lg-8 col-md-8 col-sm-8"
          style={{ marginTop: "50px", marginBottom: "100px" }}
        >
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-1">OK-VOCA 로그인</h2>

              <div className="flat-form box-login-email">
                {/*
                <div className="box-title-login" style={{ marginBottom: "10px" }}>
                  <h5>Email Login</h5>
                </div>
                */}

                <div className="form-inner">
                  <form action="#" id="contactform">
                    <input
                      id="memAcct"
                      name="memAcct"
                      value={memAcct}
                      onChange={(e) => setMemAcct(e.target.value)}
                      tabIndex="1"
                      autoComplete="on"
                      aria-required="true"
                      type="email"
                      placeholder="이메일"
                      required
                      style={{ marginBottom: "8px", fontSize: "1.6em" }}
                    />

                    <div className="form-inner" style={{ height: "40px" }}>
                      {mailDomain ? (
                        <>
                          <div
                            className="input-group mb-3 form-inner"
                            style={{
                              justifyContent: "center",
                              marginBottom: "6px",
                              fontSize: "1.5em",
                              color: "#fbb34d",
                              wordBreak: "keep-all",
                              whiteSpace: "pre-line"
                            }}
                          >
                            해당 이메일로 로그인 링크가 발송되었습니다.
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div
                        className="row-form style-1"
                        style={{
                          justifyContent: "center",
                          marginBottom: "0px",
                          fontSize: "1.5em",
                          color: "#fbb34d",
                          wordBreak: "keep-all",
                          whiteSpace: "pre-line"
                        }}
                      >
                        {msg}
                      </div>
                    </div>

                    {mailDomain ? (
                      <>
                        <button
                          onClick={(e) => domainClick(mailDomain, e)}
                          style={{
                            padding: "12px 0",
                            borderRadius: "10px",
                            fontSize: "1.6em",
                            marginTop: "10px",
                            marginBottom: "200px"
                          }}
                        >
                          {" "}
                          {mailDomain} 바로가기{" "}
                        </button>
                      </>
                    ) : (
                      ""
                    )}

                    {mailDomain ? (
                      ""
                    ) : (
                      <>
                        {emailLoginMutating ? (
                          <button
                            type="submit"
                            onClick={(e) => e.preventDefault()}
                            className="submit"
                            style={{ marginTop: "16px" }}
                          >
                            <SyncLoader loading={true} color="#eee" size={12} />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            onClick={(e) => platformLogin(e)}
                            className="submit"
                            style={{ marginTop: "16px" }}
                          >
                            로그인
                          </button>
                        )}
                      </>
                    )}
                  </form>
                  {mailDomain ? (
                    ""
                  ) : (
                    <div className="row-form style-1" style={{ justifyContent: "end", margin: "20px 6px 10px 0" }}>
                      <Link to="/sign-up" className="forgot-pass">
                        회원가입
                      </Link>
                    </div>
                  )}
                </div>
              </div>

              {mailDomain ? (
                ""
              ) : (
                <div className="flat-form box-login-social">
                  <ul>
                    <li>
                      <div className="form-inner">
                        {kakaoLoading ? (
                          <button className="submit" style={{ background: "#fee500", padding: "14px 0 12px 0" }}>
                            <SyncLoader loading={true} color="#5142FC" size={12} />
                          </button>
                        ) : (
                          <button
                            onClick={kakaoLogin}
                            className="submit"
                            style={{ border: "2px solid #fee500", background: "#fee500", padding: "14px 0 12px 0" }}
                          >
                            <div style={{ color: "#444", display: "block" }}>카카오 로그인</div>
                          </button>
                        )}
                      </div>
                    </li>
                    <li>
                      <div className="form-inner">
                        {googleLoading ? (
                          <button className="submit" style={{ backgroundColor: "#fff", padding: "14px 0 12px 0" }}>
                            <SyncLoader loading={true} color="#5142FC" size={12} />
                          </button>
                        ) : (
                          <button
                            onClick={googleLogin}
                            className="submit"
                            style={{ border: "2px solid #fff", backgroundColor: "#fff", padding: "14px 0 12px 0" }}
                          >
                            <div style={{ color: "#222", display: "block" }}>구글 로그인</div>
                          </button>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Login

import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { persist, createJSONStorage } from "zustand/middleware"

const LoginStore = (set) => ({
  login: null,
  sex: true,
  trans: false,
  setLogin: (_login) => set((state) => ({ login: _login })),
  setSex: (_sex) => set((state) => ({ sex: _sex })),
  setTrans: (_trans) => set((state) => ({ trans: _trans }))
})

const persistConfig = {
  name: "LoginStore",
  storage: createJSONStorage(() => localStorage)
}

const useLoginStore = create(devtools(persist(LoginStore, persistConfig), { name: persistConfig.name }))
export default useLoginStore

import useAxiosService from "src/common/useAxiosService"
import useSWR from "swr"

export const useSvcList = (login, use_yn) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(login ? `/web/svc/list?use_yn=${use_yn}` : null, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

export const useSvcDetail = (svc_id) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/web/svc/detail?svc_id=${svc_id}`, fetcher)
  return { data: data?.res_data || {}, error, isLoading, mutate }
}

import React, { useCallback, useEffect, useState } from "react"
import NoHeaderLoading from "src/components/comm/NoHeaderLoading"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useVocaCatDetail } from "src/api/VocaApi"
import { useVocaQuzFinish } from "src/api/VocaQuizApi"
import useVocaQuizStore from "src/store/VocaQuizStore"
import useLoginStore from "src/store/LoginStore"

const FinishQuiz = () => {
  const [searchParams] = useSearchParams()
  const svc_id = searchParams.get("svc_id")
  const cat_id = searchParams.get("cat_id")
  const mem_std_id = searchParams.get("mem_std_id")
  const { login } = useLoginStore()

  const navigate = useNavigate()
  const { data: detailData, isLoading: detailLoading } = useVocaCatDetail(cat_id)
  const { trigger, isMutating } = useVocaQuzFinish()
  const [result, setResult] = useState()
  const [sents, setSents] = useState()
  const [manageSents, setManageSents] = useState()
  const [wordList, setWordList] = useState()
  const [wrong, setWrong] = useState("Y")

  useEffect(() => {
    const wordList = result?.wordQstList.filter((item) => {
      if (wrong === "N") {
        if (item.qst_rgt_yn === "N") {
          return item
        }
      } else if (wrong === "A") {
        return item
      }
    })
    setWordList(wordList)

    const sentList = result?.quzList.filter((item) => {
      if (wrong === "N") {
        if (item.quz_rgt_yn === "N" && item.quz_typ === "S") {
          return item
        }
      } else if (wrong === "A") {
        if (item.quz_typ === "S") {
          return item
        }
      }
    })
    setSents(sentList)
  }, [wrong])

  useEffect(() => {
    const fetchData = async () => {
      const finishData = {
        mem_std_id: mem_std_id,
        cat_id: cat_id,
        mem_id: login.user_id
      }
      const response = await trigger(finishData)

      if (response) {
        setResult(response.data.res_data)
        setWordList(response.data.res_data?.wordQstList)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    console.log(result)
    const sentList = result?.quzList.filter((item) => {
      if (item.quz_typ === "S") return item
    })
    setSents(sentList)
  }, [result])

  useEffect(() => {
    const mSents = sents?.map((item) => {
      return {
        quz_qst_eng: item.quz_qst_eng,
        quz_qst_kor: item.quz_qst_kor,
        quz_rgt_yn: item.quz_rgt_yn,
        sent: result?.sentQstList.filter((itemQst) => {
          if (item.mem_quz_id === itemQst.mem_quz_id) {
            return itemQst
          }
        })
      }
    })
    setManageSents(mSents)
    console.log(mSents)
  }, [sents])

  if (detailLoading || isMutating) {
    return <NoHeaderLoading />
  }

  return (
    <>
      <section className="tf-section top-seller home5 s2 mobie-style bg-style2" style={{ paddingTop: "40px" }}>
        <div className="themesflat-container" style={{ padding: "0 8px" }}>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{ padding: "0" }}>
            <h2 className="tf-title style2">{detailData.cat_nm} 퀴즈 결과</h2>
            <div className="form-inner" style={{ paddingTop: "14px" }}>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <button
                    onClick={() => setWrong("A")}
                    className="submit"
                    style={{ padding: "16px 18px", display: "block", marginTop: "10px", fontSize: "2em" }}
                  >
                    <span style={{ marginRight: "8px" }}>전체문제</span>
                    <span style={{ color: "#de9717", fontSize: "1.2em", verticalAlign: "bottom" }}>
                      {result ? result.cnt.qst_cnt_word + result.cnt.quz_cnt_sent : 0}
                    </span>
                  </button>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <button
                    onClick={() => setWrong("N")}
                    className="submit"
                    style={{ padding: "16px 18px", display: "block", marginTop: "10px", fontSize: "2em" }}
                  >
                    <span style={{ marginRight: "8px" }}>틀린문제</span>
                    <span style={{ color: "#de4c17", fontSize: "1.2em", verticalAlign: "bottom" }}>
                      {result ? result.cnt.w_qst_cnt_word + result.cnt.w_quz_cnt_sent : 0}
                    </span>
                  </button>
                </div>
              </div>
              <div className="row">
                {wordList?.map((item, index) => {
                  return (
                    <div key={index} className="col-xl-6 col-lg-6 col-md-6 col-sm-12" style={{ marginTop: "12px" }}>
                      <div className="form-inner" style={{ paddingBottom: "0px" }}>
                        <div className="filename">
                          <label
                            className="uploadFile"
                            style={{
                              lineHeight: "30px",
                              overflow: "visible",
                              fontSize: "1.6em",
                              fontWeight: "bold",
                              padding: "14px 0 10px 18px",
                              margin: "10px 0 0px 0"
                            }}
                          >
                            <div className="row">
                              <div className="col-6">
                                <h4>{item.qst_ans}</h4>
                                <p style={{ fontSize: "1em" }}>{item.qst_req}</p>
                              </div>
                              <div className="col-6" style={{ lineHeight: "55px" }}>
                                <h4>
                                  {item.qst_sol_ans}{" "}
                                  <div
                                    className={item.qst_rgt_yn === "Y" ? "tags quiz_result_ok" : "tags quiz_result_wrong"}
                                    style={{ left: "-22px" }}
                                  >
                                    {item.qst_rgt_yn === "Y" ? <span>O</span> : <span>X</span>}
                                  </div>
                                </h4>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="row">
                {manageSents?.map((item, index) => {
                  return (
                    <div key={index} className="col-xl-6 col-lg-6 col-md-6 col-sm-12" style={{ marginTop: "12px" }}>
                      <div className="form-inner" style={{ paddingBottom: "0px" }}>
                        <div className="filename">
                          <label
                            className="uploadFile"
                            style={{
                              lineHeight: "30px",
                              overflow: "visible",
                              fontSize: "1.6em",
                              fontWeight: "bold",
                              padding: "10px",
                              margin: "10px 0 0px 0"
                            }}
                          >
                            <div className="row">
                              <div className="col-12">
                                <h4 style={{ fontSize: "1.1em" }}>
                                  {
                                    {
                                      1: (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.quz_qst_eng.replace(
                                              "#1",
                                              `<span style="color: #de9717">${item.sent[0]?.qst_ans}</span>`
                                            )
                                          }}
                                        ></div>
                                      ),
                                      2: (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.quz_qst_eng
                                              .replace("#1", `<span style="color: #de9717">${item.sent[0]?.qst_ans}</span>`)
                                              .replace("#2", `<span style="color: #de9717">${item.sent[1]?.qst_ans}</span>`)
                                          }}
                                        ></div>
                                      )
                                    }[item.sent.length]
                                  }
                                </h4>
                                <p style={{ fontSize: "0.9em" }}>{item.quz_qst_kor}</p>

                                <div className="form-inner" style={{ paddingBottom: "0px" }}>
                                  <div className="filename">
                                    <div className="row">
                                      <div
                                        className={item.sent[1] ? "col-6" : "col-12"}
                                        style={{ paddingLeft: "9px", paddingRight: "3px" }}
                                      >
                                        <label
                                          className="uploadFile"
                                          style={{
                                            lineHeight: "30px",
                                            overflow: "visible",
                                            fontSize: "1.2em",
                                            fontWeight: "bold",
                                            padding: "10px 10px 10px 28px",
                                            margin: "0"
                                          }}
                                        >
                                          <div className={item.sent[1] ? "col-6" : "col-12"}>{item.sent[0]?.qst_sol_ans}</div>
                                          <div
                                            className={
                                              item.sent[0]?.qst_rgt_yn === "Y" ? "tags quiz_result_ok" : "tags quiz_result_wrong"
                                            }
                                            style={{ left: "6px" }}
                                          >
                                            {item.sent[0]?.qst_rgt_yn === "Y" ? <span>O</span> : <span>X</span>}
                                          </div>
                                        </label>
                                      </div>
                                      {item.sent[1] ? (
                                        <div className="col-6" style={{ paddingLeft: "3px", paddingRight: "9px" }}>
                                          <label
                                            className="uploadFile"
                                            style={{
                                              lineHeight: "30px",
                                              overflow: "visible",
                                              fontSize: "1.2em",
                                              fontWeight: "bold",
                                              padding: "10px 10px 10px 28px",
                                              margin: "0"
                                            }}
                                          >
                                            <div className="col-6">{item.sent[1]?.qst_sol_ans}</div>
                                            <div
                                              className={
                                                item.sent[1]?.qst_rgt_yn === "Y"
                                                  ? "tags quiz_result_ok"
                                                  : "tags quiz_result_wrong"
                                              }
                                              style={{ left: "6px" }}
                                            >
                                              {item.sent[1]?.qst_rgt_yn === "Y" ? <span>O</span> : <span>X</span>}
                                            </div>
                                          </label>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FinishQuiz

import axios from "axios"
import useLoginStore from "src/store/LoginStore"

const axioxSpring = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  withCredentials: true,
  mode: "no-cors",
  credentials: "same-origin",
  headers: {
    //"Access-Control-Allow-Origin": '*',
    "Content-Type": "application/json"
  }
})
export default axioxSpring

/*
const axioxSpring = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  withCredentials: true,
  mode: "no-cors",
  credentials: "same-origin",
  headers: JSON.parse(localStorage.getItem("LoginStore")).state?.login
    ? {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("LoginStore")).state?.login?.jwt_token}`,
        "Content-Type": "application/json"
      }
    : {
        "Content-Type": "application/json"
      }
})
*/

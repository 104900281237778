import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { HiArrowNarrowUp } from "react-icons/hi"

const Footer = () => {
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)

    return () => window.removeEventListener("scroll", toggleVisibility)
  }, [])

  return (
    <div>
      <footer id="footer" className="footer-light-style clearfix bg-style" style={{ borderTop: "1px solid #333" }}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="widget widget-menu fl-st-3">
                <h6 className="title-widget" style={{ marginBottom: "20px" }}>
                  팀소프트(주) ㅣ 사업자번호 : 634-86-02052
                </h6>
                <ul style={{ fontSize: "1.4em" }}>
                  <li className="footerUlLi">대표: 최재혁 | 개인정보책임관리자: 최재혁</li>
                  <li className="footerUlLi">통신판매업 신고번호: 2024-경기김포-1248</li>
                  <li className="footerUlLi">T. 010-0000-0000 ㅣ E. info@teamsoft.kr</li>
                  <li className="footerUlLi">A. 서울시 구로구 구로동 237, 지하이시티 801</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="widget widget-menu fl-st-3" style={{ paddingTop: "12px" }}>
                <div className="flat-form">
                  <div className="row">
                    <div className="col-6">
                      <div className="widget">
                        <h6 className="title-widget" style={{ marginBottom: "10px" }}>
                          NAVIGATION
                        </h6>
                        <div className="row footerNav">
                          <div className="col-12">
                            <Link to="/">Home</Link>
                          </div>
                        </div>
                        <div className="row footerNav">
                          <div className="col-12">
                            <Link to="/">LoadMap</Link>
                          </div>
                        </div>
                        <div className="row footerNav">
                          <div className="col-12">
                            <Link to="/">Courses</Link>
                          </div>
                        </div>
                        <div className="row footerNav">
                          <div className="col-12">
                            <Link to="/">FAQ</Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="widget">
                        <h6 className="title-widget" style={{ marginBottom: "10px" }}>
                          REGAL
                        </h6>
                        <div className="row footerNav">
                          <div className="col-12">
                            <Link to="/policy/terms">이용약관</Link>
                          </div>
                        </div>
                        <div className="row footerNav">
                          <div className="col-12">
                            <Link to="/policy/private">개인정보취급방침</Link>
                          </div>
                        </div>
                        <div className="row footerNav">
                          <div className="col-12">
                            <Link to="/policy/refund">취소 및 환불정책</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="widget widget-menu fl-st-3">
                <h6 style={{ marginTop: "20px" }} className="title-widget">
                  © 2024 TEAMSOFT. All rights reserved.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isVisible && (
        <Link onClick={scrollToTop} to={location.pathname + location.search} id="scroll-top">
          <HiArrowNarrowUp color="#000" size={26} style={{ margin: "12px 0 0 1px" }} />
        </Link>
      )}

      <div className="modal fade popup" id="popup_bid" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body space-y-20 pd-40">
              <h3>Place a Bid</h3>
              <p className="text-center">
                You must bid at least <span className="price color-popup">4.89 ETH</span>
              </p>
              <input type="text" className="form-control" placeholder="00.00 ETH" />
              <p>
                Enter quantity. <span className="color-popup">5 available</span>
              </p>
              <input type="number" className="form-control" placeholder="1" />
              <div className="hr"></div>
              <div className="d-flex justify-content-between">
                <p> You must bid at least:</p>
                <p className="text-right price color-popup"> 4.89 ETH </p>
              </div>
              <div className="d-flex justify-content-between">
                <p> Service free:</p>
                <p className="text-right price color-popup"> 0,89 ETH </p>
              </div>
              <div className="d-flex justify-content-between">
                <p> Total bid amount:</p>
                <p className="text-right price color-popup"> 4 ETH </p>
              </div>
              <Link
                to="#"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#popup_bid_success"
                data-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                Place a bid
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

import React, { useState, useCallback } from "react"
import { Link } from "react-router-dom"
import { SyncLoader } from "react-spinners"
import { useAcctRegist } from "src/api/LoginApi"
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"

const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/

function emailValidChk(email) {
  if (pattern.test(email) === false) {
    return false
  } else {
    return true
  }
}

const SignUp = () => {
  const [mailDomain, setMailDomain] = useState("")
  const [policyCheck, setPolicyCheck] = useState(true)
  const [marketingCheck, setMarketingCheck] = useState(true)
  const [memNm, setMemNm] = useState("")
  const [memAcct, setMemAcct] = useState("")
  const [msg, setMsg] = useState("")

  const { trigger: acctRegistTrigger, isMutating: acctRegistMutating } = useAcctRegist()

  const registAcct = useCallback(
    async (e) => {
      e.preventDefault()
      console.log("registAcct")
      if (!memNm) {
        setMsg("이름을 입력해주세요")
        return
      }
      if (!memAcct) {
        setMsg("이메일을 입력해주세요")
        return
      }
      if (!emailValidChk(memAcct)) {
        setMsg("이메일 형태의 값이 아닙니다.")
        return
      }
      if (!policyCheck) {
        setMsg("이용약관 및 개인정보취급방침에 동의를 체크해 주세요.")
        return
      }
      setMsg("")

      try {
        const result = await acctRegistTrigger({ mem_nm: memNm, mem_acct: memAcct, mem_marketing: marketingCheck })
        if (result.res_data === "isExist") {
          setMsg("해당 가입 이메일이 존재합니다.")
        } else if (result.res_data === "ok") {
          setMsg(`${memAcct} 메일을 확인하세요.`)
          setMailDomain(memAcct.split("@")[1])
        }
        return
      } catch (e) {
        console.log(e)
        alert("회원가입 에러입니다.")
      }
    },
    [memNm, memAcct, policyCheck, marketingCheck]
  )

  const changePolicyCheck = useCallback((e) => {
    e.target.checked ? setPolicyCheck(true) : setPolicyCheck(false)
  }, [])

  const changeMarketingCheck = useCallback((e) => {
    e.target.checked ? setMarketingCheck(true) : setMarketingCheck(false)
  }, [])

  const domainClick = useCallback(
    (mailDomain, e) => {
      e.preventDefault()
      console.log(mailDomain)
      window.location.href = "https://" + mailDomain
    },
    [mailDomain]
  )

  return (
    <div>
      <Header />
      <section className="tf-login tf-section">
        <div
          className="themesflat-container  ol-xl-8 col-lg-8 col-md-8 col-sm-8"
          style={{ marginTop: "50px", marginBottom: "135px" }}
        >
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-1">OK-VOCA 회원가입</h2>

              <div className="flat-form box-login-email">
                {/*
                <div className="box-title-login" style={{ marginBottom: "10px" }}>
                  <h5>CodeTutor Member</h5>
                </div>
                */}

                <div className="widget widget-search">
                  <form action="#">
                    <div className="input-group mb-3">
                      <input
                        id="memNm"
                        value={memNm}
                        onChange={(e) => setMemNm(e.target.value)}
                        name="memNm"
                        tabIndex="1"
                        type="text"
                        placeholder="이름"
                        style={{ fontSize: "1.6em" }}
                      />
                    </div>

                    <div className="input-group mb-3">
                      <input
                        id="memAcct"
                        name="memAcct"
                        value={memAcct}
                        onChange={(e) => setMemAcct(e.target.value)}
                        tabIndex="2"
                        type="email"
                        placeholder="이메일"
                        style={{ fontSize: "1.6em" }}
                      />
                      {/*
                      <button
                        className="btn btn-outline-secondary"
                        style={{ fontSize: "1.4em", paddingTop: "15px", paddingBottom: "14px" }}
                      >
                        인증메일 발송
                      </button>
                      */}
                    </div>
                    {/*
                    <div className="input-group mb-3">
                      <input id="phone" name="phone" tabIndex="3" type="number" placeholder="휴대폰번호(숫자만)" />
                    </div>

                    <div className="input-group mb-3">
                      <input id="pass1" name="pass1" tabIndex="4" type="password" placeholder="비밀번호" />
                    </div>

                    <div className="input-group mb-3">
                      <input id="pass2" name="pass2" tabIndex="5" type="password" placeholder="비밀번호 확인" />
                    </div>
                    */}

                    <div className="form-inner" style={{ height: "40px" }}>
                      {mailDomain ? (
                        <>
                          <div
                            className="input-group mb-3 form-inner"
                            style={{
                              justifyContent: "center",
                              marginBottom: "6px",
                              fontSize: "1.5em",
                              color: "#fbb34d",
                              wordBreak: "keep-all",
                              whiteSpace: "pre-line"
                            }}
                          >
                            계정이 생성되었습니다.
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div
                        className="row-form style-1"
                        style={{
                          justifyContent: "center",
                          marginBottom: "6px",
                          fontSize: "1.5em",
                          color: "#fbb34d",
                          wordBreak: "keep-all",
                          whiteSpace: "pre-line"
                        }}
                      >
                        {msg}
                      </div>
                      <div
                        className="input-group mb-3 form-inner"
                        style={{
                          justifyContent: "center",
                          marginBottom: "6px",
                          fontSize: "1.5em",
                          color: "#fbb34d",
                          wordBreak: "keep-all",
                          whiteSpace: "pre-line"
                        }}
                      >
                        {mailDomain ? (
                          <>
                            <button
                              onClick={(e) => domainClick(mailDomain, e)}
                              style={{ padding: "12px 0", borderRadius: "10px", fontSize: "1em", marginTop: "20px" }}
                            >
                              {" "}
                              {mailDomain} 바로가기{" "}
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="input-group mb-3 form-inner" style={{ marginTop: "10px" }}>
                      {mailDomain ? (
                        ""
                      ) : (
                        <>
                          {acctRegistMutating ? (
                            <button
                              type="submit"
                              onClick={(e) => e.preventDefault()}
                              className="submit"
                              style={{ borderRadius: "30px", padding: "15px 0" }}
                            >
                              <SyncLoader loading={true} color="#eee" size={12} />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              onClick={(e) => registAcct(e)}
                              className="submit"
                              style={{ borderRadius: "30px", padding: "15px 0" }}
                            >
                              회원가입
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </form>

                  {mailDomain ? (
                    <div style={{ height: "100px" }}></div>
                  ) : (
                    <div className="form-inner" style={{ marginTop: "100px", paddingLeft: "10px" }}>
                      <form action="#" id="contactform">
                        <div className="row-form style-1" style={{ justifyContent: "left", marginBottom: "6px" }}>
                          <label>
                            <input type="checkbox" onChange={(e) => changePolicyCheck(e)} defaultChecked={policyCheck} />
                            <span className="btn-checkbox"></span>
                          </label>
                          <label style={{ paddingLeft: "4px", marginTop: "-3px" }}>
                            <Link to="/policy/terms">
                              <span style={{ fontSize: "1em", color: "#f7d299" }}>이용약관</span>
                            </Link>{" "}
                            및{" "}
                            <Link to="/policy/private">
                              <span style={{ fontSize: "1em", color: "#f7d299" }}>개인정보취급방침</span>
                            </Link>
                            에 동의 합니다.
                          </label>
                        </div>
                        <div className="row-form style-1" style={{ justifyContent: "left" }}>
                          <label>
                            <input type="checkbox" onChange={(e) => changeMarketingCheck(e)} defaultChecked={marketingCheck} />
                            <span className="btn-checkbox"></span>
                          </label>
                          <label style={{ paddingLeft: "4px", marginTop: "-3px" }}>
                            <Link to="/policy/marketing">
                              <span style={{ fontSize: "1em", color: "#f7d299" }}>이메일 마케팅</span>
                            </Link>{" "}
                            수신에 동의 합니다.
                          </label>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default SignUp

import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useLoginStore from "src/store/LoginStore"
import "react-tabs/style/react-tabs.css"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import Create from "../components/layouts/home-2/Create"
import heroSliderData from "../assets/fake-data/data-slider-2"
import SliderStyle1 from "../components/slider/SliderStyle1"

const Home = () => {
  const navigate = useNavigate()
  const { login } = useLoginStore()

  useEffect(() => {
    if (login) {
      navigate("/dashboard")
    }
  }, [])

  return (
    <>
      <Header />
      <SliderStyle1 data={heroSliderData} />
      <Create />
      <Footer />
    </>
  )
}

export default Home

import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"

const PolicyPrivate = () => {
  return (
    <div>
      <Header />
      <section className="tf-contact tf-section">
        <div className="themesflat-container" style={{ marginTop: "70px" }}>
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <h2 className="tf-title-heading ct style-2 mg-bt-40">개인정보취급방침</h2>

                <h3 style={{ marginBottom: "10px" }}>팀소프트 개인정보취급방침</h3>
                <h6 className="sub-title style-2">
                  주식회사 팀소프트 (이하 "회사"라 합니다)는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』, 『통신비밀보호법』, 『전기통신사업법』 등 정보통신서비스제공자가 준수하여야 할 관련 법령 상의 개인정보보호 규정을 준수하며 최소한의 정보만을 필요한 시점에 수집하고, 수집하는 정보는 고지한 범위 내에서만 사용하며, 사전 동의 없이 그 범위를 초과하여 이용하거나 외부에 공개하지 않는 등 "회원"의 권익 보호에 최선을 다하고 있습니다.
                  <br />
                  "회사"는 개인정보취급방침을 통하여 "회원"이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드리고 개인정보취급방침을 개정하는 경우 개정 이유 및 내용에 관하여 웹사이트 및 이메일 등을 통하여 고지합니다.
                  <br />
                  "회사"는 아래와 같이 개인정보를 보호하고 있습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>1. "회사"는 이용하는 서비스의 형태에 따라 다음과 같은 개인정보를 수집 및 이용∙제공∙파기하고 있습니다.</h3>
                <h6 className="sub-title style-2">
                  필수 수집 정보 : 서비스 아이디, 이메일, IMEI(이용자 기기의 고유한 정보를 원래 값을 확인하지 못하도록 안전하게 변환 후 이용), 단말기 정보, (SNS 계정으로 로그인 연동시) 깃허브 계정을 비롯한 기타 소설 네트워크(SNS) 계정, 방문 일시, 서비스 이용기록, 쿠키, 세션, 접속로그, 지역위치
                  <br />
                  선택 수집 정보 : 위치정보, 사진(메타 정보 포함), 성별, 나이, 생년월일, 배송지 정보, 프로필 사진, 닉네임, 암호화된 이용자 확인값(CI)
                </h6>
                <h3 style={{ marginBottom: "10px" }}>2. 개인정보의 수집 및 이용 목적 – "회사"는 필요한 목적 범위 내에서만 개인정보를 이용하고 있습니다.</h3>
                <h6 className="sub-title style-2">
                  가. 서비스의 기본 기능의 제공
                  <br />
                   - "회사"는 "회원"의 로그인, 콘텐츠 감상 등 기본적인 기능을 제공하기 위하여 "회원"의 개인정보를 이용합니다.
                   <br />
                  나. 회원관리
                  <br />
                  - "회사"는 "회원"의 본인확인, 회원 식별, 콘텐츠 접근 권한의 차등 적용, 고객 문의에 대한 회신, 각종 고지 사항 전달, 불량회원 제한, 부정이용방지, 분쟁 조정을 위한 기록 보존 등의 목적으로 "회원"의 개인정보를 이용합니다.
                  <br />
                  다. 사용자 경험 향상 및 마케팅ᆞ광고에의 활용
                  <br />
                  - "회사"는 지속적으로 사용자의 경험을 높이기 위해서 새로운 서비스를 개발하고,새로운 기능, 추천서비스, 기존 기능 개선, 각종 이벤트나 광고성 정보를 제공합니다.
                  <br />
                  라. 법령 및 약관 등의 이행 및 준수
                  <br />
                  - "회사"는 법령이나 이용약관 등에 반하여 피해를 줄 수 있는 부분을 방지하기 위해서 정보로 수집된 정보들을 활용할 수 있습니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>3. "회사"는 원활한 서비스 제공과 효과적인 업무처리를 위하여 다음과 같이 개인정보를 처리 위탁하고 있습니다.</h3>
                <h6 className="sub-title style-2">
                  "회사"는 수탁자들이 위탁한 개인정보를 안전하게 처리하고 있는지 지속적으로 관리 감독하고 있으며, 수탁업무가 종료된 때에 수탁자가 보유하고 있는 개인정보는 즉시 파기하게 하고 있습니다.
                  <br />
                  <br />
                  개인정보 처리 위탁 현황
                  <br />
                  <br />
                  <table>
                    <tr>
                      <td style={{width: "20%", background: "#434343"}}>구분</td>
                      <td style={{width: "40%", background: "#434343"}}>수탁자</td>
                      <td style={{width: "40%", background: "#434343"}}>위탁업무</td>
                    </tr>
                    <tr>
                      <td>서비스 운영 및 관리</td>
                      <td>Google G Suite, Slack, Discord</td>
                      <td>서비스 운영 및 관리를 위해 활용</td>
                    </tr>
                    <tr>
                      <td>콘텐츠 제공</td>
                      <td>Cloudflare, Wistia</td>
                      <td>강의, 동영상 등의 콘텐츠 제공을 위한 인프라</td>
                    </tr>
                    <tr>
                      <td>서버 제공</td>
                      <td>아마존 웹서비스</td>
                      <td>서비스 운영을 위한 인프라</td>
                    </tr>
                    <tr>
                      <td>결제 처리</td>
                      <td>토스페이먼츠, 나이스페이먼츠, 아임포트, (주)카카오, 네이버파이낸셜</td>
                      <td>신용카드, 실시간 계좌이체, 가상계좌 등을 통한 결제 처리</td>
                    </tr>
                    <tr>
                      <td>계약 처리</td>
                      <td>모두싸인, (주)자버</td>
                      <td>프리랜서 및 기업 계약의 진행</td>
                    </tr>
                    <tr>
                      <td>고객 상담</td>
                      <td>(주)채널코퍼레이션</td>
                      <td>고객 상담을 위한 인프라</td>
                    </tr>
                    <tr>
                      <td>알림 발송</td>
                      <td>Mailchimp</td>
                      <td>메시지 발송</td>
                    </tr>
                  </table>
                </h6>
                <h3 style={{ marginBottom: "10px" }}>4. 원칙적으로 "회사"는 수집한 "회원"의 개인정보에 대해 보관해야 되는 목적이 달성된 후, 즉시 해당 개인정보를 파기하고 있습니다.</h3>
                <h6 className="sub-title style-2">
                  "회사"는 회원 가입 시 제공한 정보를 회원 가입시점부터 탈퇴 신청이 접수된 날까지 보관할 수 있습니다. 단, 개인정보 도용 등으로 인한 원치 않는 회원탈퇴 등에 대비하기 위해 회원탈퇴 요청 후 7일간 개인정보를 보존하고, 서비스의 부정한 이용으로 인한 분쟁을 방지하기 위한 내부 방침에 따라 서비스의 부정이용기록, 즉 이용약관 및 "회사"의 정책에 위배되는 행위를 한 회원의 개인정보는 1년간 보존합니다. 개인정보의 파기 절차, 기한 및 방법은 다음과 같습니다.
                  <br />
                  <br />
                  1. 파기절차수집•이용목적이 달성된 개인정보의 경우 별도의 DB에 옮겨져 내부규정 및 관련 법령을 준수하여 안전하게 보관되며, 정해진 기간이 종료되었을 때, 지체없이 파기됩니다. 이 때, 별도의 DB로 옮겨진 개인정보는 여러분 각자가 동의한 목적을 초과하거나 법률에 정한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                  <br />
                  2. 파기방법
                  <br />
                  - 전자적 파일 형태의 정보는 기록을 복원할 수 없는 기술적 방법을 사용하여 영구 삭제합니다.
                  <br />
                  - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                </h6>
                <h3 style={{ marginBottom: "10px" }}>5. "회사"는 "회원"의 안정적인 정보보호를 다양한 노력을 하고 있습니다.</h3>
                <h6 className="sub-title style-2">
                  1. "회원"은 "회사" 서비스에서 직접 자신의 정보를 열람, 정정을 할 수 있으며, 별도로 개인정보보호책임자에게 서면, 전화, 이메일 등을 통하여 개인정보의 열람, 정정, 정지를 요청할 수 있습니다. "회원"은 언제든지 회원가입 시 개인정보의 수집, 이용, 제공 등에 대해 동의한 의사표시를 철회(회원탈퇴)할 수 있습니다.
                  <br />
                  2. "회사"는 개인정보 보호를 위한 기술, 관리로 다음과 같은 물리적 조치를 취합니다.
                  <br />
                  - 가. 수집한 개인정보 중 본인임을 인증하는 정보에 대한 암호화 장치
                  <br />
                  - 나. 개인정보 취급자에 대한 지정과 권한의 설정 및 교육, 개인정보의 안전한 관리
                </h6>
                <h3 style={{ marginBottom: "10px" }}>6. 개인정보보호책임자의 성명, 연락처, 소속</h3>
                <h6 className="sub-title style-2">
                  "회사"의 서비스를 이용하면서 발생한 모든 개인정보보호 관련 민원, 불만처리 등에 관한 사항을 개인정보보호책임자 및 고객센터로 문의할 수 있고, "회사"는 "회원"의 문의에 신속하고 성실하게 답변하겠습니다.
                  <br />
                  1. 개인정보보호 책임자
                  <br />
                  - 성명 : 최재혁
                  <br />
                  - 연락처: info@teamsoft.kr
                  <br />
                  소속 : 팀소프트 주식회사
                  <br />
                  기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                  <br />
                  개인정보침해신고센터 (http://www.118.or.kr / 국번 없이 118)
                  <br />
                  대검찰청 사이버범죄수사단 (http://www.spo.go.kr / 02-3480-2000)
                  <br />
                  경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / 1566-0112)                  
                </h6>
                <h3 style={{ marginBottom: "10px" }}>7. 고지의 의무</h3>
                <h6 className="sub-title style-2">
                  서비스의 개선 혹은 목적에 따라 개인정보 취급방침에 변경사항이 생길 수 있습니다. "회사"는 개인정보처리방침이 변경되는 경우에는 "회사"의 사이트 혹은 이메일을 통하여 변경 및 시행의 시기, 변경 내용을 공지합니다. "회사"는 변경 사항을 게시하며, 변경된 게시정보처리방침은 게시한 날로부터 7일 이후에 효력이 발생하게 됩니다. 단, "회원"의 권리에 중요한 변경이 있을 경우에는 변경될 내용을 30일 이전에 미리 알립니다.
                  <br />
                  1. 공고일자 : 2024년 6월 30일
                  <br />
                  2. 시행일자 : 2024년 7월 1일
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default PolicyPrivate

import { BarLoader } from "react-spinners"
import Footer from "src/components/footer/Footer"

const NoHeaderLoading = () => {
  return (
    <>
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div style={{ minHeight: "500px", margin: "200px 0 200px 0" }}>
                <div style={{ textAlign: "center", width: "200px", margin: "auto" }}>
                  <BarLoader color="#5142FC" width="200px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NoHeaderLoading

import useAxiosService from "src/common/useAxiosService"
import useSWR from "swr"
import useSWRMutation from "swr/mutation"

export const useVocaQuzList = (cat_id, use_yn) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/web/voca/quz/list?cat_id=${cat_id}&use_yn=${use_yn}`, fetcher)
  return { data: data?.res_data || [], error, isLoading, mutate }
}

export const useVocaQuzDetail = (quz_id) => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url) => axiosSpring.get(url).then(({ data }) => data)
  const { data, error, isLoading, mutate } = useSWR(`/web/voca/quz/detail?quz_id=${quz_id}`, fetcher)
  return { data: data?.res_data || {}, error, isLoading, mutate }
}

export const useVocaQuzStart = () => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/web/voca/quz/start`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useVocaQuzInsert = () => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/web/voca/quz/insert`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

export const useVocaQuzFinish = () => {
  const { axiosService } = useAxiosService()
  const axiosSpring = axiosService()
  const fetcher = (url, { arg }) => axiosSpring.post(url, arg)
  const { trigger, isMutating, data, error, reset } = useSWRMutation(`/web/voca/quz/finish`, fetcher)
  return { trigger, isMutating, data, error, reset }
}

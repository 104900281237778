import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { BarLoader } from "react-spinners"
import Header from "src/components/header/Header"
import Footer from "src/components/footer/Footer"
import axiosSpring from "src/common/AxiosSpring"
import useLoginStore from "src/store/LoginStore"

const Logout = () => {
  const navigate = useNavigate()
  const { login, setLogin } = useLoginStore()

  useEffect(() => {
    if (!login) {
      navigate("/")
    } else {
      axiosSpring
        .post(`/logout`)
        .then((res) => {
          console.log(res)
          if (res) {
            setTimeout(() => {
              setLogin(null)
              navigate("/")
            }, 1000)
          } else {
            alert("로그아웃 서버처리 실패")
            navigate("/")
          }
        })
        .catch((err) => {
          alert("로그아웃 서버처리 실패")
          navigate("/")
        })
    }
  }, [])

  return (
    <>
      <Header />

      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div style={{ minHeight: "500px", margin: "200px 0 200px 0" }}>
                <h4 className="tf-title-heading ct style-2" style={{ marginBottom: "10px" }}>
                  로그아웃 중입니다.
                </h4>

                <div style={{ textAlign: "center", width: "200px", margin: "auto" }}>
                  <BarLoader color="#5142FC" width="200px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Logout

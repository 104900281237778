import React, { useCallback, useEffect, useRef, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AgGridReact } from "ag-grid-react"
import { assign } from "lodash"
import "ag-grid-community"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css"
import NoHeaderLoading from "src/components/comm/NoHeaderLoading"
import ProgressBar from "react-bootstrap/ProgressBar"
import useVocaQuizStore from "src/store/VocaQuizStore"
import useLoginStore from "src/store/LoginStore"
import { useVocaQuzDetail, useVocaQuzInsert } from "src/api/VocaQuizApi"

const WordQuiz = () => {
  const gridRef = useRef(null)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const svc_id = searchParams.get("svc_id")
  const cat_id = searchParams.get("cat_id")
  const mem_std_id = searchParams.get("mem_std_id")
  const quz_arr = searchParams.get("quz_arr")

  const { login } = useLoginStore()
  const { quizBase, setQuizDone } = useVocaQuizStore()
  //console.log(quizBase[cat_id][quz_arr].quz_ttl_kor)

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [selectedData, setSelectedData] = useState(null)
  const [submitShow, setSubmitShow] = useState(false)
  const [score, setScore] = useState(0)

  const { data: detailData, isLoading: detailLoading } = useVocaQuzDetail(quizBase[cat_id][Number(quz_arr)].quz_id)
  const { trigger, isMutating } = useVocaQuzInsert()
  const [qstData, setQstData] = useState([])

  const [columnDefs, setColumnDefs] = useState([{ field: "opt_ans" /*, rowDrag: true*/ }])
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      flex: 1,
      cellStyle: { textAlign: "center", paddingLeft: "2px", paddingRight: "2px" }
    }
  }, [])

  const onDropZoneClick = useCallback(
    (item, index, e) => {
      e.stopPropagation()
      //console.log(e.target.innerHTML)
      //if (selectedData) {
      console.log(item, index)
      setSelectedIndex(index)
      setSelectedData({ item: item, index: index })
      //console.log(selectedData.opt_ans)

      /*
        //if (!e.target.innerHTML) {
        e.target.innerHTML = selectedData.opt_ans
        //gridRef.current.api.applyTransaction({ remove: [selectedData] })
        setSelectedData(null)
        assign(detailData.qst[index], { user: e.target.innerHTML })

        const result = detailData.qst.filter((item) => (item.user ? true : false))
        if (detailData.qst.length === result.length) {
          setSubmitShow(true)
          //detailData.qst.map((item) => delete item.user)
          gridRef.current.api.forEachNode((item) => {
            //console.log(item.data)
            //gridRef.current.api.applyTransaction({ remove: [item.data] })
          })
        }
        //}
        */
      //}
    },
    [selectedData]
  )

  const onRowClicked = useCallback(
    (params) => {
      //setSelectedData(params.data)
      console.log(detailData)
      if (selectedData) {
        console.log(selectedData.item)
        console.log(selectedData.item.qst_ans)
        console.log(params)
        console.log(params.data.opt_ans)

        console.log(qstData)
        setQstData((prevQst) => {
          const copyQst = [...qstData]
          assign(copyQst[selectedData.index], { user: params.data.opt_ans })
          return copyQst
        })

        const userCheck = qstData.filter((item) => (item.user ? true : false))
        console.log(userCheck.length)

        if (detailData.qst.length === userCheck.length) {
          setSubmitShow(true)
        }
      }
    },
    [selectedData, detailData.qst]
  )

  const onSubmitClick = useCallback(async () => {
    //[1] 서버로 퀴즈푼내용 보내고
    //[2] 정상 수신받으면 퀴즈리스트 해당퀴즈 푼거로 업데이트
    //[3] 다음퀴즈로 이동
    setQuizDone(cat_id, Number(quz_arr))

    const managedQstData = qstData.map((item) => {
      return {
        qst_id: item.qst_id,
        voca_id: item.voca_id,
        qst_rgt_yn: item.qst_ans === item.user ? "Y" : "N",
        qst_sol_ans: item.user,
        reg_mem_id: login.user_id
      }
    })

    let quz_rgt_yn = "Y"
    managedQstData.map((item) => {
      if (item.qst_rgt_yn === "N") {
        quz_rgt_yn = "N"
      }
    })

    const submitData = {
      mem_std_id: mem_std_id,
      quz_id: quizBase[cat_id][Number(quz_arr)].quz_id,
      quz_rgt_yn: quz_rgt_yn,
      quz_end_yn: "Y",
      quz_qst: managedQstData,
      reg_mem_id: login.user_id
    }
    console.log(submitData)
    await trigger(submitData)

    detailData.qst.map((item) => delete item.user)
    const nextQuiz = quizBase[cat_id][Number(quz_arr) + 1]
    console.log(nextQuiz)
    //console.log("cat_id : ", cat_id)
    //console.log("quz_arr : ", quz_arr)
    if (nextQuiz) {
      //console.log(nextQuiz)
      if (nextQuiz.quz_typ === "W") {
        navigate(`/quiz/word-quiz?svc_id=${svc_id}&cat_id=${cat_id}&mem_std_id=${mem_std_id}&quz_arr=${Number(quz_arr) + 1}`, {
          replace: true
        })
      } else if (nextQuiz.quz_typ === "S") {
        navigate(`/quiz/sent-quiz?svc_id=${svc_id}&cat_id=${cat_id}&mem_std_id=${mem_std_id}&quz_arr=${Number(quz_arr) + 1}`, {
          replace: true
        })
      }
    } else {
      navigate(`/quiz/finish-quiz?svc_id=${svc_id}&cat_id=${cat_id}&mem_std_id=${mem_std_id}`, { replace: true })
    }
  }, [quz_arr, qstData])

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit()
    /*
    quizs.map((item, index) => {
      addDropZones(params, index)
    })*/
  }, [])

  const onGridSizeChanged = useCallback((params) => {
    params.api.sizeColumnsToFit()
  }, [])

  const noData = () => {
    return <div style={{ display: "none" }}>No Rows</div>
  }

  useEffect(() => {
    setQstData(detailData.qst)
  }, [detailData])

  useEffect(() => {
    setSubmitShow(false)
  }, [quz_arr])

  useEffect(() => {
    //console.log(quizBase[cat_id].length)
    const complete = quizBase[cat_id].filter((item) => {
      return item.complete
    })
    //console.log(complete.length)
    setScore((complete.length / quizBase[cat_id].length) * 100)
  }, [quz_arr])

  if (detailLoading || isMutating) {
    return <NoHeaderLoading />
  }

  return (
    <>
      <section className="tf-section top-seller home5 s2 mobie-style bg-style2" style={{ padding: "10px 0 0 0" }}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-0 col-sm-0"></div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <ProgressBar
                animated
                variant="primary"
                now={score}
                label={`${Math.round(score)}%`}
                style={{ lineHeight: "14px", height: "14px", fontSize: "1.2em" }}
              />
              <p style={{ textAlign: "center", fontSize: "2.2em", fontWeight: "bold", marginTop: "16px" }}>단어퀴즈</p>
              <p style={{ textAlign: "center", fontSize: "1.4em", color: "orange" }}>{quizBase[cat_id][quz_arr].quz_ttl_kor}</p>
              <div
                style={{
                  boxSizing: "border-box",
                  flex: "1 1 0px"
                }}
              >
                <div className="row" style={{ padding: "0px 16px" }}>
                  {qstData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{ padding: "6px 2px 10px 2px", textAlign: "center", cursor: "pointer" }}
                        className={
                          detailData.qst.length === 1
                            ? "col-xl-12 col-lg-12 col-md-12 col-12"
                            : "col-xl-6 col-lg-6 col-md-6 col-6"
                        }
                      >
                        <div
                          onClick={(e) => onDropZoneClick(item, index, e)}
                          className={`tile-container${index} ${selectedIndex === index ? "quiz_box" : ""}`}
                          style={{
                            border: "2px solid #888",
                            backgroundColor: "#99999944",
                            overflow: "hidden",
                            height: "50px",
                            lineHeight: "50px",
                            fontSize: "2em",
                            fontWeight: "bold",
                            textAlign: "center",
                            borderRadius: "10px"
                          }}
                        >
                          {item.user}
                        </div>
                        <div style={{ marginTop: "6px", fontSize: "1.4em" }}>{item.qst_req}</div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div style={{ width: "100%", height: "100%" }}>
                <div className="example-wrapper">
                  <div className="drop-containers">
                    <div className="grid-wrapper">
                      <div
                        className={"ag-theme-alpine-dark word-quiz-grid"}
                        style={{ marginTop: "6px", height: "302px", padding: "0px 4px" }}
                      >
                        <AgGridReact
                          ref={gridRef}
                          rowData={detailData.opt}
                          rowHeight={50}
                          noRowsOverlayComponent={noData}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          onRowClicked={onRowClicked}
                          rowDragManaged={true}
                          //rowDragEntireRow={true}
                          rowDragMultiRow={false}
                          rowSelection={"single"}
                          suppressRowClickSelection={false}
                          suppressContextMenu={true}
                          suppressMenuHide={false}
                          onGridReady={onGridReady}
                          onGridSizeChanged={onGridSizeChanged}
                        />
                      </div>
                      {submitShow ? (
                        <div className="form-inner" style={{ padding: "10px" }}>
                          <button
                            onClick={() => onSubmitClick()}
                            className="submit"
                            style={{ display: "block", marginTop: "4px" }}
                          >
                            정답제출
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-0 col-sm-0"></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WordQuiz

import React, { useCallback, useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import { GoHomeFill } from "react-icons/go"
import { IoMdArrowRoundBack } from "react-icons/io"
import "ag-grid-community"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css"
import useLoginStore from "src/store/LoginStore"
import NoHeaderLoading from "src/components/comm/NoHeaderLoading"
import NoDataFooter from "src/components/footer/NoDataFooter"
import MyWordList from "./MyWordList"
import MySentList from "./MySentList"
import { useVocaBookmarkList, useSentBookmarkList } from "src/api/VocaApi"
import useDashboardStore from "src/store/DashBoardStore"

const MyNotes = () => {
  const navigate = useNavigate()
  const { login } = useLoginStore()
  const { choiceItem, setChoiceItem } = useDashboardStore()

  const {
    data: vocaBookmarkList,
    isLoading: vocaBookmarkListLoading,
    mutate: vocaBookmarkListMutate
  } = useVocaBookmarkList(login?.mem_id)
  const {
    data: sentBookmarkList,
    isLoading: sentBookmarkListLoading,
    mutate: sentBookmarkListMutate
  } = useSentBookmarkList(login?.mem_id)

  const clickChoiceItem = useCallback((chiceItem) => {
    setChoiceItem(chiceItem)
  }, [])

  if (vocaBookmarkListLoading || sentBookmarkListLoading) {
    return <NoHeaderLoading />
  }

  return (
    <>
      <section
        className="tf-section top-seller home5 s2 mobie-style bg-style2"
        style={{ padding: "0px", paddingBottom: "0px !important" }}
      >
        <div className="themesflat-container" style={{ padding: "0" }}>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{ padding: "0" }}>
            <div className="form-inner" style={{ padding: "0px 0px" }}>
              <div
                className="row"
                style={{
                  padding: "2px 6px 10px 6px",
                  margin: "0",
                  position: "fixed",
                  top: "0",
                  zIndex: 100,
                  background: "#000",
                  width: "100%",
                  maxWidth: "1440px"
                }}
              >
                <div className="col-xl-1 col-lg-1 col-md-1 col-2" style={{ padding: "0 4px" }}>
                  <IoMdArrowRoundBack
                    onClick={() => navigate(-1)}
                    size="38"
                    style={{ margin: "10px 0 0 4px", cursor: "pointer" }}
                  />
                </div>
                <div className="col-xl-7 col-lg-7 col-md-5 col-2"></div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-4" style={{ padding: "0 4px" }}>
                  <button
                    onClick={() => clickChoiceItem("voca")}
                    className={choiceItem === "voca" ? "submit selected" : "submit"}
                    style={{ padding: "8px 0px", display: "block", marginTop: "10px", fontSize: "1.5em" }}
                  >
                    <span style={{ marginRight: "6px" }}>단어</span>
                    <span style={{ color: "#de9717", fontSize: "1.2em", verticalAlign: "bottom" }}>
                      {vocaBookmarkList.length}
                    </span>
                  </button>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-4" style={{ padding: "0 4px" }}>
                  <button
                    onClick={() => clickChoiceItem("sent")}
                    className={choiceItem === "sent" ? "submit selected" : "submit"}
                    style={{ padding: "8px 0px", display: "block", marginTop: "10px", fontSize: "1.5em" }}
                  >
                    <span style={{ marginRight: "6px" }}>문장</span>
                    <span style={{ color: "#de4c17", fontSize: "1.2em", verticalAlign: "bottom" }}>
                      {sentBookmarkList.length}
                    </span>
                  </button>
                </div>
              </div>
              {
                {
                  voca: <MyWordList vocaBookmarkList={vocaBookmarkList} vocaBookmarkListMutate={vocaBookmarkListMutate} />,
                  sent: <MySentList sentBookmarkList={sentBookmarkList} sentBookmarkListMutate={sentBookmarkListMutate} />
                }[choiceItem]
              }
            </div>
          </div>
        </div>
      </section>
      <NoDataFooter />
    </>
  )
}
export default MyNotes

import React, { useEffect, useCallback, useState } from "react"
import { BarLoader } from "react-spinners"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import axiosSpring from "src/common/AxiosSpring"
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"
import useLoginStore from "src/store/LoginStore"

const LoginPlatform = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get("t")
  const { login, setLogin } = useLoginStore()

  useEffect(() => {
    if (login) {
      navigate("/dashboard")
    }
    axiosSpring
      .get(`/login/platform?t=${token}`)
      .then((res) => {
        console.log(res)
        if (res) {
          setLogin(res.data.res_data)
          //const timeout = setTimeout(() => {
          navigate("/dashboard", {
            replace: true
          })
          //  clearTimeout(timeout)
          //}, 1500)
        } else {
          alert("로그인에 실패하였습니다.")
          navigate("/login", {
            replace: true
          })
        }
      })
      .catch((err) => {
        alert("로그인에 실패하였습니다.")
        navigate("/login", {
          replace: true
        })
      })
  }, [])

  return (
    <>
      <Header />
      <section className="tf-contact tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div style={{ minHeight: "500px", margin: "200px 0 200px 0" }}>
                <h4 className="tf-title-heading ct style-2" style={{ marginBottom: "10px" }}>
                  로그인 중입니다.
                </h4>

                <div style={{ textAlign: "center", width: "200px", margin: "auto" }}>
                  <BarLoader color="#5142FC" width="200px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default LoginPlatform

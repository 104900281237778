import React, { useRef, useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import menus from "../../pages/menu"
import { Dropdown } from "react-bootstrap"
import { FaUser } from "react-icons/fa"
import { IoLogOutOutline } from "react-icons/io5"
import { IoSettingsOutline } from "react-icons/io5"
import logoheader from "../../assets/images/logo/logo.png"
import logoheader2x from "../../assets/images/logo/logo@2x.png"
import logodark from "../../assets/images/logo/logo_dark.png"
import logodark2x from "../../assets/images/logo/logo_dark@2x.png"

import useLoginStore from "src/store/LoginStore"

const Header = () => {
  const { login } = useLoginStore()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const headerRef = useRef(null)

  useEffect(() => {
    window.addEventListener("scroll", isSticky)
    return () => {
      window.removeEventListener("scroll", isSticky)
    }
  })
  const isSticky = (e) => {
    const header = document.querySelector(".js-header")
    const scrollTop = window.scrollY
    scrollTop >= 300 ? header.classList.add("is-fixed") : header.classList.remove("is-fixed")
    scrollTop >= 400 ? header.classList.add("is-small") : header.classList.remove("is-small")
  }

  const menuLeft = useRef(null)
  const btnToggle = useRef(null)
  const btnSearch = useRef(null)

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active")
    btnToggle.current.classList.toggle("active")
  }

  const searchBtn = () => {
    btnSearch.current.classList.toggle("active")
  }

  const [activeIndex, setActiveIndex] = useState(null)
  const handleOnClick = (index) => {
    setActiveIndex(index)
  }

  const logout = () => {
    navigate("/logout")
  }

  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to={login ? "/dashboard" : "/"} rel="home" className="main-logo">
                      <img className="logo-dark" id="logo_header" src={logodark} srcSet={`${logodark2x}`} alt="nft-gaming" />
                      <img className="logo-light" id="logo_header" src={logoheader} srcSet={`${logoheader2x}`} alt="nft-gaming" />
                    </Link>
                  </div>
                </div>
                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}>
                  <span></span>
                </div>
                <nav id="main-nav" className="main-nav" ref={menuLeft}>
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, index) => (
                      <li
                        key={index}
                        onClick={() => handleOnClick(index)}
                        className={`menu-item ${data.namesub ? "menu-item-has-children" : ""} ${
                          activeIndex === index ? "active" : ""
                        } `}
                      >
                        <Link to={login ? data.links[1] : data.links[0]}>{data.name}</Link>
                        {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu) => (
                              <li
                                key={submenu.id}
                                className={pathname === submenu.links ? "menu-item current-item" : "menu-item"}
                              >
                                <Link to={submenu.links}>{submenu.sub}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="flat-search-btn flex">
                  <div className="header-search flat-show-search" id="s1">
                    {/*
                    <Link to="#" className="show-search header-search-trigger" onClick={searchBtn}>
                      <i className="far fa-search"></i>
                    </Link>
                    */}
                    <div className="top-search" ref={btnSearch}>
                      <form action="#" method="get" role="search" className="search-form">
                        <input
                          type="search"
                          id="s"
                          className="search-field"
                          placeholder="Search..."
                          name="s"
                          title="Search for"
                          required=""
                        />
                        <button className="search search-submit" type="submit" title="Search">
                          <i className="icon-fl-search-filled"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  {!login ? (
                    <div className="mg-r-12" id="site-header">
                      <div className="form-inner">
                        <button
                          onClick={() => navigate("/login")}
                          style={{ fontFamily: "nfts", padding: "11px 40px", lineHeight: "16px", height: "48px" }}
                        >
                          <FaUser size="20" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="tf-soft">
                      <div className="soft-left">
                        <Dropdown style={{ marginRight: "10px" }}>
                          <Dropdown.Toggle id="dropdown-basic" style={{ padding: "9px 24px" }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span style={{ fontSize: "1.4em" }}>{login.user_name}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ marginTop: "0px", background: "#2e355d" }}>
                            <Dropdown.Item href="#">
                              <div style={{ display: "flex" }}>
                                <IoSettingsOutline size={18} style={{ margin: "-1px 5px 0 0" }} />
                                <div>Settings</div>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={logout}>
                              <div style={{ display: "flex" }}>
                                <IoLogOutOutline size={20} style={{ margin: "-1px 4px 0 0" }} />
                                <div>Logout</div>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
